import React from 'react';
//COMPONENTS
import {
    Homework,
    Event,
    Task,
    Absence,
    Teacher,
    Subject,
    Grade
} from './_Forms';
//CONTEXT-API
import { useStateValue } from '../../../../StateContext';
//SCSS
import './index.scss';


interface DialogProps {
    open   : boolean;
    setOpen: Function;
}

function Dialog_({
    open,
    setOpen,
}: DialogProps) {
    const [ { url }, dispatch ] = useStateValue();


    let form = (
          url==="/homework"  && <Homework open={open} setOpen={setOpen}/>
        ||url==="/events"    && <Event    open={open} setOpen={setOpen}/>
        ||url==="/tasks"     && <Task     open={open} setOpen={setOpen}/>
        ||url==="/absences"  && <Absence  open={open} setOpen={setOpen}/>
        ||url==="/teachers"  && <Teacher  open={open} setOpen={setOpen}/>
        ||url==="/subjects"  && <Subject  open={open} setOpen={setOpen}/>
        ||url==="/grades"    && <Grade    open={open} setOpen={setOpen}/>
    );


    return (
        <>
            {
                form
            }
        </>
    )
}

export default Dialog_;