import React, { useState, useEffect } from 'react';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
//CONTEXT-API
import { useStateValue } from '../../../../../../StateContext';
import { settingGRADESYSTEM } from '../../../../../../contextapi';
//APPSYNC-MUTATION
import { handleEditUser } from '../../../../../../appsync_functions/mutations';
//SCSS
import './index.scss';


//FOR MUI INPUT BORDER-RADUIS
//FOR MUI DIALOG WIDTH/HEIGHT
const useStyles = makeStyles({
    root: {
        [`& fieldset`]: {
            borderRadius: 16.5
        }
    },
    paper: {
        minWidth: "42.9vh",
        maxWidth: "42.9vh",
        minHeight: "27.9vh",
        maxHeight: "27.9vh",
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
});


//FOR MUI SELECT BOX
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}


interface Dialog_MesssageProps {
    open   : boolean;
    setOpen: Function;
}

function Dialog_SelectSemester({
    open,
    setOpen,
}: Dialog_MesssageProps) {
    const classes = useStyles();
    const [ { user, refetchLoadingAppSync }, dispatch ] = useStateValue();


    const [ semesterValue, setSemesterValue ] = useState<number>(1);
    useEffect(() => {
        setSemesterValue(user?.semester);
    }, []);
    
    
    const [ openDialog_Message, setOpenDialog_Message ] = useState<boolean>(false);
    

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                className="dialog"
            >
                <div className="dialog_selectsemester">
                    <div>
                        <FormControl className={classes.root}>
                            <InputLabel className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}> Change Semester </InputLabel>
                            <Select
                                value={(user?.semester===semesterValue)?user?.semester:semesterValue}
                                onChange={(e: any) => setSemesterValue(e.target.value)}
                                input={<OutlinedInput label="Change Semester"/>}   /* space */
                                MenuProps={MenuProps}
                                className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}
                            >
                                <MenuItem value={1}> Semester 1 </MenuItem>
                                <MenuItem value={2}> Semester 2 </MenuItem>
                            </Select>
                        </FormControl>
                    </div>

                    <div className="dialog_selectsemester_btns">
                        <div className="dialog_selectsemester_btns_flexy"/>
                        <button className={`dialog_selectsemester_btns_cancelBtn ${user?.dark&&`hover:!bg-gray-600`} p-1.5 w-16 rounded-lg mr-[0.265rem]`} onClick={() => setOpen(false)}> No </button>
                        <button
                            className="dialog_selectsemester_btns_addBtn p-1.5 w-16 rounded-lg ml-[0.265rem]"
                            onClick={() => {
                                setOpen(false);

                                if(user?.semester!==semesterValue) {
                                    setOpenDialog_Message(true);
                                }
                            }}
                        > Yes </button>
                    </div>
                </div>
            </Dialog>


            <Dialog
                open={openDialog_Message}
                onClose={() => setOpenDialog_Message(false)}
                className="dialog"
            >
                <div className="dialog_selectsemester_msg pt-6 pl-6 pr-6 pb-3.5">
                    <p className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}> Semester changed successfully. Changed in semester will be applied on Absences, Subjects, and Grades page. </p>

                    <div className="dialog_selectsemester_msg_btns">
                        <div className="dialog_selectsemester_msg_btns_flexy"/>
                        <button
                            className="dialog_selectsemester_msg_btns_btn p-1.5 w-16 rounded-lg ml-2.5"
                            onClick={() => {
                                //editing on DB by AppSync
                                handleEditUser({ ...user, semester: semesterValue });     
                                
                                //setting to default
                                dispatch(settingGRADESYSTEM('Percentage Based Grade'));

                                setOpenDialog_Message(false);
                            }}
                        > Yes </button>
                    </div>
                </div>
            </Dialog>


            <Dialog
                open={refetchLoadingAppSync}
                className="dialog"
                classes={{ paper: classes.paper }}
            >
                <CircularProgress size={40}/>
            </Dialog>
        </>
    )
}

export default Dialog_SelectSemester;