import React, { useState, useEffect } from 'react';
//MATERIAL-UI
import Typography from '@material-ui/core/Typography';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@material-ui/core/Button';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
//MATERIAL-UI ( DATE PICKER )
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
//MATERIAL-UI ( SELECT MULTIPLE )
import SelectMultipleWithCheckbox from './SelectMultipleWithCheckbox';
import SelectMultipleWithoutCheckbox from './SelectMultipleWithoutCheckbox';
//MATERIAL-UI ( Autocomplete )
import MUI_Autocomplete from '../../../MUI_Autocomplete';
//CONTEXT-API
import { useStateValue } from '../../../../../StateContext';
//APPSYNC-MUTATION
import { handleEditUser } from '../../../../../appsync_functions/mutations';
//UNIQUE-ID
import uniqid from 'uniqid';
//SCSS
import './index.scss';


export let handleOpened: Function;


function convertDateToYYYYMMDD(date) {
    var mnth = ("0" + (date.getMonth() + 1)).slice(-2);
    var day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
}


function subtractOneDayToDateRange(dateArray) {
    // Create a new array to store the modified dates
    const resultArray = [];

    for (const dateString of dateArray) {
        // Convert the string to a Date object
        const currentDate = new Date(dateString);

        // Subtract one day from the current date
        currentDate.setDate(currentDate.getDate() - 1);

        // Convert the modified date back to a string and add it to the result array
        resultArray.push(currentDate.toISOString().split('T')[0]);
    }

    return resultArray;
}


function addOneDayToDateRange(dateArray) {
    // Create a new array to store the modified dates
    const modifiedDates = [];

    // Loop through each date in the input array
    for (const dateString of dateArray) {
        // Convert the string to a Date object
        const currentDate = new Date(dateString);

        // Add one day to the date
        currentDate.setDate(currentDate.getDate() + 1);

        // Convert the modified date back to a string and push it to the new array
        modifiedDates.push(currentDate.toISOString().split('T')[0]);
    }

    return modifiedDates;
}


function GetDatesListOfBetween2Dates(startDate, endDate) {
    const dateList = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dateList.push(currentDate.toISOString().split('T')[0]);
        currentDate.setDate(currentDate.getDate() + 1);
    }

    return [...addOneDayToDateRange([...dateList, endDate])];
}


//FOR MUI INPUT BORDER-RADUIS
const useStyles = makeStyles({
    root: {
        [`& fieldset`]: {
            borderRadius: 16.5
        }
    }
});


//FOR MUI SELECT BOX
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}


interface EventProps {
    open: boolean;
    setOpen: Function;
}

function Event({
    open,
    setOpen
}: EventProps) {
    const classes = useStyles();
    const [{ user }, dispatch] = useStateValue();


    const [event, setEvent] = useState<string>("");
    const [subject, setSubject] = useState<string>("");
    const [location, setLocation] = useState<string>("");
    const [note, setNote] = useState<string>("");


    const [date, setDate] = useState<Date>(new Date());
    const handleDate = (date: Date) => {
        setDate(date);


        const _date = new Date(date);  /* ignore 'copy by referrence' by using 'new Date' */
        const newDateToReplace = new Date(_date.setDate(_date.getDate() + 1)).toISOString().slice(0, 10);


        if (
            (repeat === "Daily"
                || repeat === "Weekly"
                || repeat === "Monthly")
            && !openedNewly
        ) {
            setDatesBwDate1NDate2(
                [
                    ...datesBwDate1NDate2.filter((item) => item !== convertDateToYYYYMMDD(calenderDate)),
                    newDateToReplace
                ]
            );
        }
        else {
            setDatesBwDate1NDate2(
                GetDatesListOfBetween2Dates(date, date2)
            );
        }
    }
    const [time, setTime] = useState<any>("2014-08-18T21:00:00");
    const handleTime = (time: Date) => setTime(time);


    const [category, setCategory] = useState<string>("All");
    const [repeat, setRepeat] = useState<string>("Once");


    const [date2, setDate2] = useState<Date>(
        new Date(new Date()).setDate(new Date().getDate() + 1)
    );
    const handleDate2 = (date2: Date) => {
        setDate2(date2);

        setDatesBwDate1NDate2(
            GetDatesListOfBetween2Dates(date, date2)
        );
    }


    const [datesBwDate1NDate2, setDatesBwDate1NDate2] = useState<string[]>([
        //should be empty, but provide options in props so then selected returns here
    ]);


    const [selectedDays_WEEKLY, setSelectedDays_WEEKLY] = useState<string[]>([
        //should be empty, but provide options in props so then selected returns here
    ]);


    const [selectedDays_MONTHLY, setSelectedDays_MONTHLY] = useState<string[]>([
        //should be empty, but provide options in props so then selected returns here
    ]);


    const [openCreated, setOpenCreated] = useState<boolean>(false);
    const [openCreatedId, setOpenCreatedId] = useState<string | null>(null);
    const [openedNewly, setOpenedNewly] = useState<boolean>(true);
    const [calenderDate, setCalenderDate] = useState<Date>(new Date());


    handleOpened = (item: any, calenderDate_: Date) => {
        setOpen(true);
        setOpenCreated(true);
        setOpenCreatedId(item.id);
        setOpenedNewly(false);              /* condition used to hide 'Repeat' option in form when already existing entry is opened */
        setCalenderDate(calenderDate_);     /* to delete that specific 'date' from 'datesBwDate1NDate2' */

        /*setting*/
        setEvent(item.event);
        setSubject(item.subject);
        setLocation(item.location);
        setNote(item.note);
        setDate(new Date(item.date));
        setTime(item.time);
        setCategory(item.category);
        setRepeat(item.repeat);
        setDate2(new Date(item.date2));
        setDatesBwDate1NDate2(item.datesBwDate1NDate2);
        if (item.repeat === "Weekly") { setSelectedDays_WEEKLY(item.selectedDays_WEEKLY); }
        else if (item.repeat === "Monthly") { setSelectedDays_MONTHLY(item.selectedDays_MONTHLY); }
    }


    const [openDialog_Message, setOpenDialog_Message] = useState<boolean>(false);


    const [clicked, setClicked] = useState<boolean>(false);


    const clearAllStateProperties = () => {
        setTimeout(() => {
            /*for reset when dialog closed the popup*/
            /*resetting*/
            setEvent("");
            setSubject("");
            setLocation("");
            setNote("");
            setDate(new Date());
            setTime("2014-08-18T21:00:00");
            setCategory("All");
            setRepeat("Once");
            setDate2(new Date());
            setDatesBwDate1NDate2([]);
            setSelectedDays_WEEKLY([]);
            setSelectedDays_MONTHLY([]);
            /*logical*/
            setOpenCreated(false);
            /*for reset when dialog closed the popup*/

            setOpenedNewly(true);           /* condition used to hide 'Repeat' option in form when already existing entry is opened */
        }, 200);
    }


    var manageCategories_Event;
    var subject_ = [];
    manageCategories_Event = user ? user?.manageCategories_Event : [];
    if (user && (user?.semester === 1)) { subject_ = user ? user?.semester_1?.subject : []; }
    else if (user && (user?.semester === 2)) { subject_ = user ? user?.semester_2?.subject : []; }


    useEffect(() => {
        setCategory(manageCategories_Event[0]);
    }, [user]);


    useEffect(() => {
        /* for to have 'CalendarDate' on Dialog */
        if (
            (repeat === "Daily"
                || repeat === "Weekly"
                || repeat === "Monthly")
            && !openedNewly
        ) {
            setDate(calenderDate);
        }
    }, [openedNewly]);


    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                    /*FIX*/
                    clearAllStateProperties();
                }}
                className="dialog"
            >
                <DialogContent>
                    <form
                        className="form"
                        autoComplete="off"
                        onSubmit={(e) => {
                            e.preventDefault();
                            setClicked(true);


                            if (
                                event !== ""
                            ) {
                                if (!openCreated) {   /*for not recreate already created one*/
                                    if (repeat === "Once") {
                                        //editing on DB by AppSync
                                        handleEditUser({ ...user, event: [...user?.event, { id: uniqid(), checked: [{ date, bool: false }], event, subject, location, note, category, repeat, /*optional*/ date, time }] });
                                    }
                                    else if (repeat === "Daily") {
                                        //editing on DB by AppSync
                                        handleEditUser({ ...user, event: [...user?.event, { id: uniqid(), checked: (GetDatesListOfBetween2Dates(date, date2)).map((item) => { return { date: item, bool: false } }), event, subject, location, note, category, repeat, /*optional*/ time, date, date2, datesBwDate1NDate2: GetDatesListOfBetween2Dates(date, date2) }] });
                                    }
                                    else if (repeat === "Weekly") {
                                        //editing on DB by AppSync
                                        handleEditUser({ ...user, event: [...user?.event, { id: uniqid(), checked: (GetDatesListOfBetween2Dates(date, date2)).map((item) => { return { date: item, bool: false } }), event, subject, location, note, category, repeat, /*optional*/ time, date, date2, selectedDays_WEEKLY, datesBwDate1NDate2: GetDatesListOfBetween2Dates(date, date2) }] });
                                    }
                                    else if (repeat === "Monthly") {
                                        //editing on DB by AppSync
                                        handleEditUser({ ...user, event: [...user?.event, { id: uniqid(), checked: (GetDatesListOfBetween2Dates(date, date2)).map((item) => { return { date: item, bool: false } }), event, subject, location, note, category, repeat, /*optional*/ time, date, date2, selectedDays_MONTHLY, datesBwDate1NDate2: GetDatesListOfBetween2Dates(date, date2) }] });
                                    }
                                }

                                else {                /*for update already created one*/
                                    if (repeat === "Once") {
                                        //editing on DB by AppSync
                                        handleEditUser({ ...user, event: user?.event.map((item) => { if (item.id === openCreatedId) { return { id: item.id, checked: item.checked, event, subject, location, note, category, repeat, /*optional*/ date, time }; } else { return item } }) });
                                    }
                                    else if (repeat === "Daily") {
                                        //editing on DB by AppSync
                                        handleEditUser({ ...user, event: user?.event.map((item) => { if (item.id === openCreatedId) { return { id: item.id, checked: item.checked, event, subject, location, note, category, repeat, /*optional*/ time, date, date2, datesBwDate1NDate2 }; } else { return item } }) });
                                    }
                                    else if (repeat === "Weekly") {
                                        //editing on DB by AppSync
                                        handleEditUser({ ...user, event: user?.event.map((item) => { if (item.id === openCreatedId) { return { id: item.id, checked: item.checked, event, subject, location, note, category, repeat, /*optional*/ time, date, date2, selectedDays_WEEKLY, datesBwDate1NDate2 }; } else { return item } }) });
                                    }
                                    else if (repeat === "Monthly") {
                                        //editing on DB by AppSync
                                        handleEditUser({ ...user, event: user?.event.map((item) => { if (item.id === openCreatedId) { return { id: item.id, checked: item.checked, event, subject, location, note, category, repeat, /*optional*/ time, date, date2, selectedDays_MONTHLY, datesBwDate1NDate2 }; } else { return item } }) });
                                    }

                                    setOpenCreatedId(null);
                                }


                                setOpen(false);
                                setClicked(false);
                            }


                            /*FIX*/
                            clearAllStateProperties();
                        }}
                    >
                        <Typography variant="h4" className={`${user?.dark ? `!text-[#eee]` : `!text-[#000]`}`}> Event </Typography>

                        <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} value={event} onChange={(e) => setEvent(e.target.value)} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Event</p>} variant="outlined" /> </div>
                        {event === "" && clicked && <p className="err"> Required Field </p>}

                        {
                            subject_ && (
                                <MUI_Autocomplete
                                    alloptions={subject_}
                                    value={subject}
                                    setValue={setSubject}
                                    label="Subject"
                                />
                            )
                        }

                        <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} value={location} onChange={(e) => setLocation(e.target.value)} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Location</p>} variant="outlined" /> </div>

                        <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} value={note} onChange={(e) => setNote(e.target.value)} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Note</p>} variant="outlined" /> </div>

                        <div>
                            <FormControl className={classes.root}>
                                <InputLabel className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Category </InputLabel>
                                <Select
                                    value={category}
                                    onChange={(e: any) => setCategory(e.target.value)}
                                    input={<OutlinedInput label="Category" />}
                                    MenuProps={MenuProps}
                                    className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}
                                >
                                    {
                                        manageCategories_Event.map((item) => (
                                            <MenuItem value={item}> {item} </MenuItem>
                                        ))
                                    }
                                </Select>
                            </FormControl>
                        </div>

                        {
                            (repeat === "Daily"
                                || repeat === "Weekly"
                                || repeat === "Monthly")
                            && openedNewly
                            && (
                                <>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <TimePicker
                                            value={time}
                                            onChange={handleTime}
                                            label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Time</p>}
                                            renderInput={(params) => <TextField {...params} className={classes.root} sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} />}
                                            components={{
                                                OpenPickerIcon: (props) => (
                                                    <TimelapseIcon {...props} sx={{ color: 'red' }} />
                                                )
                                            }}
                                        />
                                    </LocalizationProvider>

                                    <div className="!w-[100%] flex items-center">
                                        <div className="!w-[50%] mr-1">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDatePicker
                                                    value={date}
                                                    onChange={handleDate}
                                                    label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Start</p>}
                                                    inputFormat="MMMM DD, YYYY"
                                                    renderInput={(params) => <TextField {...params} className={classes.root} sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} />}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        <div className="!w-[50%] ml-1">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDatePicker
                                                    value={date2}
                                                    onChange={handleDate2}
                                                    label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>End</p>}
                                                    inputFormat="MMMM DD, YYYY"
                                                    renderInput={(params) => <TextField {...params} className={classes.root} sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} />}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </>
                            )
                        }

                        {
                            (repeat === "Daily"
                                || repeat === "Weekly"
                                || repeat === "Monthly")
                            && !openedNewly
                            && (
                                <>
                                    <div className="!w-[100%] flex items-center">
                                        <div className="!w-[50%] mr-1">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <MobileDatePicker
                                                    value={date}
                                                    onChange={handleDate}
                                                    label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Date</p>}
                                                    inputFormat="MMMM DD, YYYY"
                                                    renderInput={(params) => <TextField {...params} className={classes.root} sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} />}
                                                />
                                            </LocalizationProvider>
                                        </div>

                                        <div className="!w-[50%] ml-1">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <TimePicker
                                                    value={time}
                                                    onChange={handleTime}
                                                    label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Time</p>}
                                                    renderInput={(params) => <TextField {...params} className={classes.root} sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} />}
                                                    components={{
                                                        OpenPickerIcon: (props) => (
                                                            <TimelapseIcon {...props} sx={{ color: 'red' }} />
                                                        )
                                                    }}
                                                />
                                            </LocalizationProvider>
                                        </div>
                                    </div>
                                </>
                            )
                        }

                        {
                            repeat === "Once" && (
                                <div className="!w-[100%] flex items-center">
                                    <div className="!w-[50%] mr-1">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <MobileDatePicker
                                                value={date}
                                                onChange={handleDate}
                                                label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Date</p>}
                                                inputFormat="MMMM DD, YYYY"
                                                renderInput={(params) => <TextField {...params} className={classes.root} sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} />}
                                            />
                                        </LocalizationProvider>
                                    </div>

                                    <div className="!w-[50%] ml-1">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                value={time}
                                                onChange={handleTime}
                                                label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Time</p>}
                                                renderInput={(params) => <TextField {...params} className={classes.root} sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} />}
                                                components={{
                                                    OpenPickerIcon: (props) => (
                                                        <TimelapseIcon {...props} sx={{ color: 'red' }} />
                                                    )
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                            )
                        }

                        {
                            openedNewly && (
                                <div>
                                    <FormControl className={classes.root}>
                                        <InputLabel className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Repeat </InputLabel>
                                        <Select
                                            value={repeat}
                                            onChange={(e: any) => {
                                                setRepeat(e.target.value);
                                                setDate2(
                                                    new Date(new Date()).setDate(new Date().getDate() + 1)
                                                );
                                            }}
                                            input={<OutlinedInput label="Repeat" />}   /* space */
                                            MenuProps={MenuProps}
                                            className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}
                                        >
                                            <MenuItem value="Once">    Once    </MenuItem>
                                            <MenuItem value="Daily">   Daily   </MenuItem>
                                            <MenuItem value="Weekly">  Weekly  </MenuItem>
                                            <MenuItem value="Monthly"> Monthly </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            )
                        }

                        {
                            repeat === "Weekly"
                            && openedNewly
                            && (
                                <SelectMultipleWithCheckbox
                                    heading="Selected Days"
                                    options={["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]}
                                    list={selectedDays_WEEKLY}
                                    setList={setSelectedDays_WEEKLY}
                                />
                            )
                        }

                        {
                            repeat === "Monthly"
                            && openedNewly
                            && (
                                <SelectMultipleWithoutCheckbox
                                    heading="Selected Days"
                                    options={['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']}
                                    list={selectedDays_MONTHLY}
                                    setList={setSelectedDays_MONTHLY}
                                />
                            )
                        }

                        <div className="form_btns">
                            <div className="form_btns_flexy" />
                            <Button
                                className="form_btns_cancelBtn"
                                disableRipple
                                onClick={() => {
                                    setOpen(false);

                                    if (
                                        event !== ""
                                    ) {
                                        setOpenDialog_Message(true);
                                    }
                                }}
                            > Delete </Button>
                            <Button className="form_btns_addBtn" type="submit"> Save </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>


            <Dialog
                open={openDialog_Message}
                onClose={() => {
                    setOpenDialog_Message(false);
                    /*FIX*/
                    clearAllStateProperties();
                }}
                className="dialog"
            >
                <div className="dialog_selectsemester_msg pt-6 pl-6 pr-6 pb-3.5">
                    <p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Are you sure you want to delete this event? </p>

                    <div className="dialog_selectsemester_msg_btns">
                        <div className="dialog_selectsemester_msg_btns_flexy" />
                        <button
                            className={`dialog_selectsemester_btns_cancelBtn ${user?.dark && `hover:!bg-gray-600`} p-1.5 w-16 rounded-lg mr-[0.265rem]`}
                            onClick={() => {
                                setOpenDialog_Message(false);
                                /*FIX*/
                                clearAllStateProperties();
                            }}
                        > No </button>
                        <button
                            className="dialog_selectsemester_btns_addBtn p-1.5 w-16 rounded-lg ml-[0.265rem]"
                            onClick={() => {
                                if (
                                    event !== ""
                                ) {

                                    if (
                                        (repeat === "Daily"
                                            || repeat === "Weekly"
                                            || repeat === "Monthly")
                                    ) {
                                        if (
                                            datesBwDate1NDate2.length === 1  /* if last then remove entirely */
                                        ) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, event: user?.event.filter((item) => { return item.id !== openCreatedId; }) });
                                        }
                                        else {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, event:
                                                user?.event.map((item) => {
                                                    if (item.id === openCreatedId) {
                                                        let _ = subtractOneDayToDateRange(item.datesBwDate1NDate2);

                                                        return {
                                                            ...item,
                                                            /*optional*/
                                                            datesBwDate1NDate2: addOneDayToDateRange(_.filter((_item) => {
                                                                return _item !== convertDateToYYYYMMDD(calenderDate);
                                                            }))
                                                        };
                                                    }
                                                    else {
                                                        return item;
                                                    }
                                                })
                                            });
                                        }
                                    }

                                    else {
                                        //editing on DB by AppSync
                                        handleEditUser({ ...user, event: user?.event.filter((item) => { return item.id !== openCreatedId; }) });
                                    }

                                    setOpenCreatedId(null);
                                }

                                setOpenDialog_Message(false);
                                /*FIX*/
                                clearAllStateProperties();
                            }}
                        > Yes </button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default Event;