import React, { useState } from 'react';
//MATERIAL-UI
import Typography from '@material-ui/core/Typography';
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
//CONTEXT-API
import { useStateValue } from '../../../../../StateContext';
//APPSYNC-MUTATIONS
import { handleEditUser } from '../../../../../appsync_functions/mutations';
//UNIQUE-ID
import uniqid from 'uniqid';
//SCSS
import './index.scss';


export let handleOpened: Function;


//FOR MUI INPUT BORDER-RADUIS
const useStyles = makeStyles({
    root: {
        [`& fieldset`]: {
            borderRadius: 16.5
        }
    }
});


interface TeacherProps {
    open: boolean;
    setOpen: Function;
}

function Teacher({
    open,
    setOpen,
}: TeacherProps) {
    const classes = useStyles();
    const [{ user }, dispatch] = useStateValue();


    const [name, setName] = useState<string>("");
    const [phone, setPhone] = useState<number | string>("");
    const [email, setEmail] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [officehours, setOfficehours] = useState<number | string>("");
    const [website, setWebsite] = useState<string>("");


    const [openCreated, setOpenCreated] = useState<boolean>(false);
    const [openCreatedId, setOpenCreatedId] = useState<string | null>(null);
    handleOpened = (item: any) => {
        setOpen(true);
        setOpenCreated(true);
        setOpenCreatedId(item.id);

        /*setting*/
        setName(item.name);
        setPhone(item.phone);
        setEmail(item.email);
        setAddress(item.address);
        setOfficehours(item.officehours);
        setWebsite(item.website);
    }


    const [openDialog_Message, setOpenDialog_Message] = useState<boolean>(false);


    const [clicked, setClicked] = useState<boolean>(false);


    const clearAllStateProperties = () => {
        setTimeout(() => {
            /*for reset when dialog closed the popup*/
            /*resetting*/
            setName("");
            setPhone("");
            setEmail("");
            setAddress("");
            setOfficehours("");
            setWebsite("");
            /*logical*/
            setOpenCreated(false);
            /*for reset when dialog closed the popup*/
        }, 200);
    }


    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                    /*FIX*/
                    clearAllStateProperties();
                }}
                className="dialog"
            >
                <DialogContent>
                    <form
                        className="form"
                        autoComplete="off"
                        onSubmit={(e) => {
                            e.preventDefault();
                            setClicked(true);


                            if (
                                name !== ""
                            ) {
                                if (!openCreated) {   /*for not recreate already created one*/
                                    //editing on DB by AppSync
                                    handleEditUser({ ...user, teacher: [...user?.teacher, { id: uniqid(), name, phone, email, address, officehours, website }] });
                                }
                                else {               /*for update already created one*/
                                    //editing on DB by AppSync
                                    handleEditUser({ ...user, teacher: user?.teacher.map((item) => { if (item.id === openCreatedId) { return { id: item.id, name, phone, email, address, officehours, website }; } else { return item } }) });

                                    setOpenCreatedId(null);
                                }


                                setOpen(false);
                                setClicked(false);
                            }


                            /*FIX*/
                            clearAllStateProperties();
                        }}
                    >
                        <Typography variant="h4" className={`${user?.dark ? `!text-[#eee]` : `!text-[#000]`}`}> Teacher </Typography>

                        <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} name='name' value={name} onChange={(e) => setName(e.target.value)} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Name</p>} variant="outlined" /> </div>
                        {name === "" && clicked && <p className="err"> Required Field </p>}

                        <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} value={phone} onChange={(e) => setPhone(e.target.value)} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Phone</p>} variant="outlined" /> </div>

                        <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} value={email} onChange={(e) => setEmail(e.target.value)} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Email</p>} variant="outlined" /> </div>

                        <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} value={address} onChange={(e) => setAddress(e.target.value)} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Address</p>} variant="outlined" /> </div>

                        <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} value={officehours} onChange={(e) => setOfficehours(e.target.value)} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Office Hours</p>} variant="outlined" /> </div>

                        <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} value={website} onChange={(e) => setWebsite(e.target.value)} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Website</p>} variant="outlined" /> </div>

                        <div className="form_btns">
                            <div className="form_btns_flexy" />
                            <Button
                                className="form_btns_cancelBtn"
                                disableRipple
                                onClick={() => {
                                    setOpen(false);

                                    if (
                                        name !== ""
                                    ) {
                                        setOpenDialog_Message(true);
                                    }
                                }}
                            > Delete </Button>
                            <Button className="form_btns_addBtn" type="submit"> Save </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>


            <Dialog
                open={openDialog_Message}
                onClose={() => {
                    setOpenDialog_Message(false);
                    /*FIX*/
                    clearAllStateProperties();
                }}
                className="dialog"
            >
                <div className="dialog_selectsemester_msg pt-6 pl-6 pr-6 pb-3.5">
                    <p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Are you sure you want to delete this contact? </p>

                    <div className="dialog_selectsemester_msg_btns">
                        <div className="dialog_selectsemester_msg_btns_flexy" />
                        <button
                            className={`dialog_selectsemester_btns_cancelBtn ${user?.dark && `hover:!bg-gray-600`} p-1.5 w-16 rounded-lg mr-[0.265rem]`}
                            onClick={() => {
                                setOpenDialog_Message(false);
                                /*FIX*/
                                clearAllStateProperties();
                            }}
                        > No </button>
                        <button
                            className="dialog_selectsemester_btns_addBtn p-1.5 w-16 rounded-lg ml-[0.265rem]"
                            onClick={() => {
                                if (
                                    name !== ""
                                ) {
                                    //editing on DB by AppSync
                                    handleEditUser({ ...user, teacher: user?.teacher.filter((item) => { return item.id !== openCreatedId; }) });

                                    setOpenCreatedId(null);
                                }

                                setOpenDialog_Message(false);
                                /*FIX*/
                                clearAllStateProperties();
                            }}
                        > Yes </button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default Teacher;