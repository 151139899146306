import React, { useState, useEffect } from 'react';
//MATERIAL-UI
import Typography from '@material-ui/core/Typography';
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
//MATERIAL-UI ( DATE PICKER )
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
//MATERIAL-UI ( Autocomplete )
import MUI_Autocomplete from '../../../MUI_Autocomplete';
//CONTEXT-API
import { useStateValue } from '../../../../../StateContext';
//APPSYNC-MUTATION
import { handleEditUser } from '../../../../../appsync_functions/mutations';
//UNIQUE-ID
import uniqid from 'uniqid';
//SCSS
import './index.scss';


export let handleOpened: Function;


//FOR MUI INPUT BORDER-RADUIS
const useStyles = makeStyles({
    root: {
        [`& fieldset`]: {
            borderRadius: 16.5
        }
    }
});


interface AbsenceProps {
    open: boolean;
    setOpen: Function;
}

function Absence({
    open,
    setOpen,
}: AbsenceProps) {
    const classes = useStyles();
    const [{ user }, dispatch] = useStateValue();


    const [subject, setSubject] = useState<string>("");
    const [teacher, setTeacher] = useState<string>("");
    const [room, setRoom] = useState<string>("");
    const [note, setNote] = useState<string>("");


    const [date, setDate] = useState<any>(new Date());
    const handleDate = (date: Date) => setDate(date);


    const [openCreated, setOpenCreated] = useState<boolean>(false);
    const [openCreatedId, setOpenCreatedId] = useState<string | null>(null);
    handleOpened = (item: any) => {
        setOpen(true);
        setOpenCreated(true);
        setOpenCreatedId(item.id);

        /*setting*/
        setSubject(item.subject);
        setTeacher(item.teacher);
        setRoom(item.room);
        setNote(item.note);
        setDate(item.date);
    }


    const [openDialog_Message, setOpenDialog_Message] = useState<boolean>(false);


    const [clicked, setClicked] = useState<boolean>(false);


    const clearAllStateProperties = () => {
        setTimeout(() => {
            /*for reset when dialog closed the popup*/
            /*resetting*/
            setSubject("");
            setTeacher("");
            setRoom("");
            setNote("");
            setDate(new Date());
            /*logical*/
            setOpenCreated(false);
            /*for reset when dialog closed the popup*/
        }, 200);
    }


    var subject_ = [];
    var teacher_ = [];
    teacher_ = user?.teacher;
    if (user && (user?.semester === 1)) { subject_ = user?.semester_1?.subject; }
    else if (user && (user?.semester === 2)) { subject_ = user?.semester_2?.subject; }


    useEffect(() => {
        if (subject_) {
            const thatSubject = subject_.find((item) => item.subject === subject);

            if (
                thatSubject
            ) {
                setTeacher(thatSubject.teacher);
                setRoom(thatSubject.room);
            }
        }
    }, [subject]);


    useEffect(() => {
        const thatTeacher = teacher_.find((item) => item.name === teacher);

        if (
            thatTeacher
        ) {
            setTeacher(thatTeacher.name);
        }
    }, [teacher]);


    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                    /*FIX*/
                    clearAllStateProperties();
                }}
                className="dialog"
            >
                <DialogContent>
                    <form
                        className="form"
                        autoComplete="off"
                        onSubmit={(e) => {
                            e.preventDefault();
                            setClicked(true);


                            if (
                                subject !== ""
                            ) {
                                if (!openCreated) {   /*for not recreate already created one*/

                                    if (user?.semester === 1) {
                                        //editing on DB by AppSync
                                        handleEditUser({ ...user, semester_1: { ...user?.semester_1, absence: [...user?.semester_1.absence, { id: uniqid(), subject, teacher, room, note, date }] } });
                                    }
                                    else if (user?.semester === 2) {
                                        //editing on DB by AppSync
                                        handleEditUser({ ...user, semester_2: { ...user?.semester_2, absence: [...user?.semester_2.absence, { id: uniqid(), subject, teacher, room, note, date }] } });
                                    }

                                }
                                else {               /*for update already created one*/

                                    if (user?.semester === 1) {
                                        //editing on DB by AppSync
                                        handleEditUser({ ...user, semester_1: { ...user?.semester_1, absence: user?.semester_1.absence.map((item) => { if (item.id === openCreatedId) { return { id: item.id, subject, teacher, room, note, date }; } else { return item } }) } });
                                    }
                                    else if (user?.semester === 2) {
                                        //editing on DB by AppSync
                                        handleEditUser({ ...user, semester_2: { ...user?.semester_2, absence: user?.semester_2.absence.map((item) => { if (item.id === openCreatedId) { return { id: item.id, subject, teacher, room, note, date }; } else { return item } }) } });
                                    }

                                    setOpenCreatedId(null);
                                }


                                setOpen(false);
                                setClicked(false);
                            }


                            /*FIX*/
                            clearAllStateProperties();
                        }}
                    >
                        <Typography variant="h4" className={`${user?.dark ? `!text-[#eee]` : `!text-[#000]`}`}> Absence </Typography>

                        {
                            subject_ && (
                                <MUI_Autocomplete
                                    alloptions={subject_}
                                    value={subject}
                                    setValue={setSubject}
                                    label="Subject"
                                />
                            )
                        }

                        {subject === "" && clicked && <p className="err"> Required Field </p>}

                        {
                            teacher_ && (
                                <MUI_Autocomplete
                                    alloptions={teacher_}
                                    value={teacher}
                                    setValue={setTeacher}
                                    label="Teacher"
                                />
                            )
                        }

                        <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} value={room} onChange={(e) => setRoom(e.target.value)} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Room</p>} variant="outlined" /> </div>

                        <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} value={note} onChange={(e) => setNote(e.target.value)} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Note</p>} variant="outlined" /> </div>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                                value={date}
                                onChange={handleDate}
                                label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Date</p>}
                                inputFormat="MMMM DD, YYYY"
                                renderInput={(params) => <TextField {...params} className={classes.root} sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} />}
                            />
                        </LocalizationProvider>

                        <div className="form_btns">
                            <div className="form_btns_flexy" />
                            <Button
                                className="form_btns_cancelBtn"
                                disableRipple
                                onClick={() => {
                                    setOpen(false);

                                    if (
                                        subject !== ""
                                    ) {
                                        setOpenDialog_Message(true);
                                    }
                                }}
                            > Delete </Button>
                            <Button className="form_btns_addBtn" type="submit"> Save </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>


            <Dialog
                open={openDialog_Message}
                onClose={() => {
                    setOpenDialog_Message(false);
                    /*FIX*/
                    clearAllStateProperties();
                }}
                className="dialog"
            >
                <div className="dialog_selectsemester_msg pt-6 pl-6 pr-6 pb-3.5">
                    <p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Are you sure you want to delete this absence? </p>

                    <div className="dialog_selectsemester_msg_btns">
                        <div className="dialog_selectsemester_msg_btns_flexy" />
                        <button
                            className={`dialog_selectsemester_btns_cancelBtn ${user?.dark && `hover:!bg-gray-600`} p-1.5 w-16 rounded-lg mr-[0.265rem]`}
                            onClick={() => {
                                setOpenDialog_Message(false);
                                /*FIX*/
                                clearAllStateProperties();
                            }}
                        > No </button>
                        <button
                            className="dialog_selectsemester_btns_addBtn p-1.5 w-16 rounded-lg ml-[0.265rem]"
                            onClick={() => {
                                if (
                                    subject !== ""
                                ) {

                                    if (user?.semester === 1) {
                                        //editing on DB by AppSync
                                        handleEditUser({ ...user, semester_1: { ...user?.semester_1, absence: user?.semester_1.absence.filter((item) => { return item.id !== openCreatedId; }) } });
                                    }
                                    else if (user?.semester === 2) {
                                        //editing on DB by AppSync
                                        handleEditUser({ ...user, semester_2: { ...user?.semester_2, absence: user?.semester_2.absence.filter((item) => { return item.id !== openCreatedId; }) } });
                                    }

                                    setOpenCreatedId(null);
                                }

                                setOpenDialog_Message(false);
                                /*FIX*/
                                clearAllStateProperties();
                            }}
                        > Yes </button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default Absence;