import React from 'react';
//MATERIAL-UI
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import IconButton from '@mui/material/IconButton';
//IMAGES
import icon from '../../images/icon.png';


function Footer() {
    return (
        <div className="flex justify-around w-[80%] !mt-20 pb-16" style={{ margin: "0 auto" }}>
            <div>
                <img src={icon} alt="icon" className="w-[68px] h-[68px] -ml-1.5"/>
                <p className="w-[70%] text-base text-gray-600 mt-4"> <span className="font-bold">Tructivity</span> is the perfect app for students who want to be organized in school </p>
                <div className="mt-8 invisible">
                    <a href="https://www.facebook.com" target="_blank">  <IconButton disableRipple className="w-[2.55rem] h-[2.55rem] bg-white hover:bg-white"> <FacebookIcon style={{ fill: "red" }} /> </IconButton> </a>
                    <a href="https://www.twitter.com" target="_blank">   <IconButton disableRipple className="w-[2.55rem] h-[2.55rem] bg-white hover:bg-white ml-3"> <TwitterIcon style={{ fill: "red" }} /> </IconButton> </a>
                    <a href="https://www.instagram.com" target="_blank"> <IconButton disableRipple className="w-[2.55rem] h-[2.55rem] bg-white hover:bg-white ml-3"> <InstagramIcon style={{ fill: "red" }} /> </IconButton> </a>
                </div>
                <p className="text-gray-400 mt-8"> ©2024 Tructivity </p>
            </div>

            <div className="flex items-center justify-between">
                <div className="invisible">
                    <p className="text-black text-xl font-bold"> Product </p>
                    <div className="mt-5 text-gray-500">
                        <p className="mt-2.5"> Download </p>
                        <p className="mt-2.5"> Pricing </p>
                        <p className="mt-2.5"> Locations </p>
                        <p className="mt-2.5"> Server </p>
                        <p className="mt-2.5"> Countries </p>
                        <p className="mt-2.5"> Blog </p>
                    </div>
                </div>

                <div className="ml-20 invisible">
                    <p className="text-black text-xl font-bold"> Product </p>
                    <div className="mt-5 text-gray-500">
                        <p className="mt-2.5"> Download </p>
                        <p className="mt-2.5"> Pricing </p>
                        <p className="mt-2.5"> Locations </p>
                        <p className="mt-2.5"> Server </p>
                        <p className="mt-2.5"> Countries </p>
                        <p className="mt-2.5"> Blog </p>
                    </div>
                </div>

                <div className="ml-20">
                    <p className="text-black text-xl font-bold"> Important Links </p>
                    <div className="mt-5 text-gray-500">
                        <a href="/privacy-policy" target="_blank" className="mt-2.5"> Privacy Policy </a>
                        <br/>
                        <a href="/terms-and-conditions" target="_blank" className="mt-2.5"> Terms & Conditions </a>
                        <p> Contact@tructivity.com </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;