import React from 'react';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
//CONTEXT-API
import { useStateValue } from '../../../../../../StateContext';
//SCSS
import './index.scss';


interface Dialog_WarningProps {
    open   : boolean;
    setOpen: Function;
    message: string;
    func   : Function;
    item   : string;
}

function Dialog_Warning({
    open,
    setOpen,
    message,
    func,
    item
}: Dialog_WarningProps) {
    const [ { user }, dispatch ] = useStateValue();
    

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            className="dialog"
        >
            <div className="dialog_warning pt-6 pl-6 pr-6 pb-3.5">
                <p className={`text-xl ${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}> {message} </p>            
    
                <div className="dialog_warning_btns">
                    <div className="dialog_warning_btns_flexy"/>
                    <button className={`dialog_warning_btns_cancelBtn ${user?.dark&&`hover:!bg-gray-600`} p-1.5 w-16 rounded-lg ml-2.5`} onClick={() => setOpen(false)}> No </button>
                    <button
                        className="dialog_warning_btns_addBtn p-1.5 w-16 rounded-lg ml-2.5"
                        onClick={() => { setOpen(false); func(item); }}
                    > Yes </button>
                </div>
            </div>
        </Dialog>
    )
}

export default Dialog_Warning;