import React, { useState, useEffect } from 'react';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@material-ui/core/styles';
//CONTEXT-API
import { useStateValue } from '../../../../../../StateContext';
//SCSS
import './index.scss';


//FOR MUI INPUT BORDER-RADUIS
const useStyles = makeStyles({
    root: {
        [`& fieldset`]: {
            borderRadius: 16.5
        }
    }
});


interface Dialog_AdditionProps {
    open   : boolean;
    setOpen: Function;
    func   : Function;
}

function Dialog_Addition({
    open,
    setOpen,
    func
}: Dialog_AdditionProps) {
    const classes = useStyles();
    const [ { user }, dispatch ] = useStateValue();


    const [ category, setCategory ] = useState<string>("");
    // const [ categoryDisabled, setategoryDisabled ] = useState<boolean>(false);


    // useEffect(() => {
    //     if(category.length>=15) {
    //         setategoryDisabled(true);
    //     }
    //     else {
    //         setategoryDisabled(false);
    //     }
    // }, [category]);


    return (
        <Dialog
            open={open}
            onClose={() => { setOpen(false); setCategory(""); }}
            className="dialog"
        >
            <div className="form">
                <div className="flex items-center">
                    <TextField 
                      //disabled={categoryDisabled}
                        sx={{ input: { color: `${user?.dark?`#bbc0c4`:`#455a64`}` } }} className={classes.root} value={category} onChange={(e) => setCategory(e.target.value)} label={<p className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}>Category</p>} variant="outlined"
                    />
                    {/*<span className="mt-4 bg-blue-500 text-white pt-3 pb-3 pl-5 pr-5 ml-1 rounded-3xl">{category.length}</span>*/}
                </div>
                {/* {
                    (category.length >= 15) && (
                        <button
                            className={`dialog_addition_btns_cancelBtn ${user?.dark&&`hover:!bg-gray-600`} p-1 mt-1.5 w-20 rounded-lg`}
                            onClick={() => { setCategory(""); setategoryDisabled(true); }}
                        > Remove </button>    
                    )
                } */}

                <div className="dialog_addition_btns">
                    <div className="dialog_addition_btns_flexy"/>
                    <button
                        className={`dialog_addition_btns_cancelBtn ${user?.dark&&`hover:!bg-gray-600`} p-1.5 w-16 rounded-lg ml-2.5`}
                        onClick={() => { setOpen(false); setCategory(""); }}
                    > No </button>
                    <button
                        className="dialog_addition_btns_addBtn p-1.5 w-16 rounded-lg ml-2.5"
                        onClick={() => { setOpen(false); setCategory(""); func(category); }}
                    > Yes </button>
                </div>
            </div>
        </Dialog>
    )
}

export default Dialog_Addition;