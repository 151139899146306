import React from 'react';
//GATSBY
import { navigate } from 'gatsby';
//CONTEXT-API
import { useStateValue } from '../../StateContext';
import { settingURL } from '../../contextapi';
//COMPONENTS
import Page from './Page';


function Landing() {
    const [ state, dispatch] = useStateValue();


    const loginFunc  = () => { dispatch(settingURL("/login")); navigate("/login"); }
    const signupFunc = () => { dispatch(settingURL("/signup")); navigate("/signup"); }
    // const homeFunc   = () => { dispatch(settingURL("/home")); navigate("/home"); };
    const homeFunc   = () => { dispatch(settingURL("/homework")); navigate("/homework"); };


    return (
        <Page
            loginFunc={loginFunc}
            signupFunc={signupFunc}
            homeFunc={homeFunc}
        />
    )
}

export default Landing;