//AWS-AMPLIFY
import { API } from 'aws-amplify';
import {
    onCreateUser, onDeleteUser, onEditUser
} from '../../graphql/subscriptions';






export const subscribeOnUser = (fetchFunction: Function) => {
    API.graphql({ query: onCreateUser })
    .subscribe({
        next: () => { fetchFunction(); }
    });

    API.graphql({ query: onDeleteUser })
    .subscribe({
        next: () => { fetchFunction(); }
    });

    API.graphql({ query: onEditUser })
    .subscribe({
        next: () => { fetchFunction(); }
    });
}