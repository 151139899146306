import React, { useState, useEffect } from 'react';
//COMPONENTS
import BigCalendar_Dynamic from '../utils/BigCalendar_Dynamic';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
//MATERIAL-UI ( DATE PICKER )
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
//MATERIAL-UI ( Autocomplete )
import MUI_Autocomplete from '../utils/MUI_Autocomplete';
//CONTEXT-API
import { useStateValue } from '../../StateContext';
//APPSYNC-MUTATION
import { handleEditUser } from '../../appsync_functions/mutations';
//UNIQUE-ID
import uniqid from 'uniqid';
//SCSS
import './index.scss';
import './_Form.scss';
import './_Dialog.scss';


function convertStringDateToDateObject(str: string) {
    var date = new Date(str);

    var year = date.getFullYear();
    var month = date.getMonth();
    var date_ = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();

    return new Date(year, month, date_, hours, minutes, 0);
}


//FOR MUI INPUT BORDER-RADUIS
//FOR MUI DIALOG WIDTH/HEIGHT
const useStyles = makeStyles({
    root: {
        [`& fieldset`]: {
            borderRadius: 16.5
        }
    },
    paper: {
        minWidth: "42.9vh",
        maxWidth: "42.9vh",
        minHeight: "27.9vh",
        maxHeight: "27.9vh",
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
});


//FOR MUI SELECT BOX
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}


function Schedule() {
    /******************************DIALOG FORM***************************************/
    const classes = useStyles();


    const [subject, setSubject] = useState<string>("");
    const [teacher, setTeacher] = useState<string>("");
    const [room, setRoom] = useState<string>("");
    const [note, setNote] = useState<string>("");


    const [time1, setTime1] = useState<any>("2014-08-18T21:00:00");
    const handleTime1 = (time: Date) => setTime1(time);
    const [time2, setTime2] = useState<any>("2014-08-18T22:00:00");
    const handleTime2 = (time: Date) => setTime2(time);


    const [colorpicker, setColorpicker] = useState<string>("#e40fe4");
    const [typeValue, setTypeValue] = useState<string>("Homework");
    /******************************DIALOG FORM***************************************/



    /******************************BIG CALENDER********************************/
    const [{ user, refetchLoadingAppSync }, dispatch] = useStateValue();


    const [open, setOpen] = useState<boolean>(false);
    const [openCreated, setOpenCreated] = useState<boolean>(false);
    const [openCreatedId, setOpenCreatedId] = useState<string | null>(null);
    const handleEvent = ({ start, end }) => {
        setOpen(true);

        setTime1(start);  /*dynamic start for ( dialog )*/
        setTime2(end);    /*dynamic end   for ( dialog )*/
    }
    const handleOpened = (e: any) => {
        setOpen(true);
        setOpenCreated(true);
        setOpenCreatedId(e.id);

        /*setting*/
        setSubject(e.subject);
        setTeacher(e.teacher);
        setRoom(e.room);
        setNote(e.note);
        setTime1(e.time1);
        setTime2(e.time2);
        setColorpicker(e.colorpicker);
        setTypeValue(e.typeValue);
    }
    /******************************BIG CALENDER********************************/


    const [clicked, setClicked] = useState<boolean>(false);


    const [openDialog_Message, setOpenDialog_Message] = useState<boolean>(false);


    const clearAllStateProperties = () => {
        /*for reset when dialog closed the popup*/
        /*resetting*/
        setSubject("");
        setTeacher("");
        setRoom("");
        setNote("");
        setTime1("2014-08-18T21:00:00");
        setTime2("2014-08-18T22:00:00")
        setColorpicker("#e40fe4");
        setTypeValue("Homework");
        /*logical*/
        setOpenCreated(false);
        /*for reset when dialog closed the popup*/
    }


    var schedule = [];
    var subject_ = [];
    var teacher_ = [];
    schedule = user?.schedule;
    teacher_ = user?.teacher;
    if (user && (user?.semester === 1)) { subject_ = user?.semester_1?.subject; }
    else if (user && (user?.semester === 2)) { subject_ = user?.semester_2?.subject; }


    useEffect(() => {
        if (subject_) {
            const thatSubject = subject_.find((item) => item.subject === subject);

            if (
                thatSubject
            ) {
                setTeacher(thatSubject.teacher);
                setRoom(thatSubject.room);
            }
        }
    }, [subject]);


    useEffect(() => {
        const thatTeacher = teacher_.find((item) => item.name === teacher);

        if (
            thatTeacher
        ) {
            setTeacher(thatTeacher.name);
        }
    }, [teacher]);


    return (
        <>
            <div className="schedule">
                <div className={`${user?.dark ? `schedule-dark` : `schedule-light`}`}>
                    <BigCalendar_Dynamic
                        events={(schedule)
                            ?.map((item: any) => {
                                return { ...item, start: new Date(item.start), end: new Date(item.end) }
                            }
                        )}
                        handleEvent={handleEvent}
                        handleOpened={handleOpened}
                    />
                </div>

                <Dialog
                    open={refetchLoadingAppSync}
                    className="dialog"
                    classes={{ paper: classes.paper }}
                >
                    <CircularProgress size={40} />
                </Dialog>
            </div>


            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                    /*FIX*/
                    clearAllStateProperties();
                }}
                className="dialog"
            >
                <DialogContent>
                    <form
                        className="form"
                        autoComplete="off"
                        onSubmit={(e) => {
                            e.preventDefault();
                            setClicked(true);


                            if (
                                subject !== ""
                            ) {
                                if (!openCreated) {   /*for not recreate already created one*/
                                    //editing on DB by AppSync
                                    handleEditUser({ ...user, schedule: [...user?.schedule, { id: uniqid(), title: subject, start: convertStringDateToDateObject(time1), end: convertStringDateToDateObject(time2), /*other properties*/ subject, teacher, room, note, time1, time2, colorpicker, typeValue }] });
                                }
                                else {               /*for update already created one*/
                                    //editing on DB by AppSync
                                    handleEditUser({ ...user, schedule: user?.schedule.map((item) => { if (item.id === openCreatedId) { return { id: item.id, title: subject, start: convertStringDateToDateObject(time1), end: convertStringDateToDateObject(time2), /*other properties*/ subject, teacher, room, note, time1, time2, colorpicker, typeValue }; } else { return item } }) });

                                    setOpenCreatedId(null);
                                }


                                setOpen(false);
                                setClicked(false);
                            }


                            /*FIX*/
                            clearAllStateProperties();
                        }}
                    >
                        <Typography variant="h4" className={`${user?.dark ? `!text-[#eee]` : `!text-[#000]`}`}> Schedule </Typography>

                        {
                            subject_ && (
                                <MUI_Autocomplete
                                    alloptions={subject_}
                                    value={subject}
                                    setValue={setSubject}
                                    label="Subject"
                                />
                            )
                        }

                        {subject === "" && clicked && <p className="err"> Required Field </p>}

                        {
                            teacher_ && (
                                <MUI_Autocomplete
                                    alloptions={teacher_}
                                    value={teacher}
                                    setValue={setTeacher}
                                    label="Teacher"
                                />
                            )
                        }

                        <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} value={room} onChange={(e) => setRoom(e.target.value)} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Room</p>} variant="outlined" /> </div>

                        <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} value={note} onChange={(e) => setNote(e.target.value)} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Note</p>} variant="outlined" /> </div>

                        <div className="!w-[100%] flex items-center">
                            <div className="!w-[50%] mr-1">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        value={time1}
                                        onChange={handleTime1}
                                        label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Start</p>}
                                        renderInput={(params) => <TextField {...params} className={classes.root} sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} />}
                                        components={{
                                            OpenPickerIcon: (props) => (
                                                <TimelapseIcon {...props} sx={{ color: 'red' }} />
                                            )
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>

                            <div className="!w-[50%] ml-1">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        value={time2}
                                        onChange={handleTime2}
                                        label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>End</p>}
                                        renderInput={(params) => <TextField {...params} className={classes.root} sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} />}
                                        components={{
                                            OpenPickerIcon: (props) => (
                                                <TimelapseIcon {...props} sx={{ color: 'red' }} />
                                            )
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="mt-3.5 p-3.5 rounded-2xl flex items-center" style={{ border: "1px solid #bdb8b8" }}>
                            <span className="mt-[-0.1025rem]"> Color </span>
                            <input
                                id="color"
                                className="cursor-pointer ml-2"
                                type="color"
                                value={colorpicker}
                                onChange={(e) => setColorpicker(e.target.value)}
                            />
                        </div>

                        <div>
                            <FormControl className={classes.root}>
                                <InputLabel className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Type </InputLabel>
                                <Select
                                    value={typeValue}
                                    onChange={(e: any) => setTypeValue(e.target.value)}
                                    input={<OutlinedInput label="Type" />}   /* space */
                                    MenuProps={MenuProps}
                                    className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}
                                >
                                    <MenuItem value="Homework">     Homework     </MenuItem>
                                    <MenuItem value="Presentation"> Presentation </MenuItem>
                                    <MenuItem value="Paper">        Paper        </MenuItem>
                                    <MenuItem value="Quiz">         Quiz         </MenuItem>
                                    <MenuItem value="Exam">         Exam         </MenuItem>
                                </Select>
                            </FormControl>
                        </div>

                        <div className="form_btns">
                            <div className="form_btns_flexy" />
                            <Button
                                className="form_btns_cancelBtn"
                                disableRipple
                                onClick={() => {
                                    setOpen(false);

                                    if (
                                        subject !== ""
                                    ) {
                                        setOpenDialog_Message(true);
                                    }
                                }}
                            > Delete </Button>
                            <Button className="form_btns_addBtn" type="submit"> Save </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>


            <Dialog
                open={openDialog_Message}
                onClose={() => {
                    setOpenDialog_Message(false);
                    /*FIX*/
                    clearAllStateProperties();
                }}
                className="dialog"
            >
                <div className="dialog_selectsemester_msg pt-6 pl-6 pr-6 pb-3.5">
                    <p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Are you sure you want to delete this subject? </p>

                    <div className="dialog_selectsemester_msg_btns">
                        <div className="dialog_selectsemester_msg_btns_flexy" />
                        <button
                            className={`dialog_selectsemester_btns_cancelBtn ${user?.dark && `hover:!bg-gray-600`} p-1.5 w-16 rounded-lg mr-[0.265rem]`}
                            onClick={() => {
                                setOpenDialog_Message(false);
                                /*FIX*/
                                clearAllStateProperties();
                            }}
                        > No </button>
                        <button
                            className="dialog_selectsemester_btns_addBtn p-1.5 w-16 rounded-lg ml-[0.265rem]"
                            onClick={() => {
                                if (
                                    subject !== ""
                                ) {
                                    //editing on DB by AppSync
                                    handleEditUser({ ...user, schedule: user?.schedule.filter((item) => { return item.id !== openCreatedId; }) });

                                    setOpenCreatedId(null);
                                }

                                setOpenDialog_Message(false);
                                /*FIX*/
                                clearAllStateProperties();
                            }}
                        > Yes </button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default Schedule;