//AWS-AMPLIFY
import { API } from 'aws-amplify';
import {
    createUser, deleteUser, editUser
} from '../../graphql/mutations';






export const handleCreateUser = async (firstname: string, lastname: string, email: string, password: string) => {
    try {
        await API.graphql({
            query: createUser,
            variables: {
                firstname,
                lastname,
                email,
                password,
                /**/
                dark: false,
                bandwidth: 0,
                storage: 0,
                regestrationDate: new Date(),
/*next year*/   endingDate: new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate() - 1, new Date().getHours(), new Date().getMinutes(), 0),
                semester: 1,
                pageSettings: { homework: true, events: true, tasks: true, notes: true, absences: true, teachers: true, subjects: true, schedule: true, timetable: true, grades: true },
                /**/
                semester_1: {
                    absence: [],
                    subject: [],
                    grade: []
                },
                semester_2: {
                    absence: [],
                    subject: [],
                    grade: []
                },
                /**/
                schedule: [],
                timetable: [],
                teacher: [],
                note: [],
                task: [],
                event: [],
                homework: [],
                manageCategories_Task: ["All", "Entertainment", "Work"],
                manageCategories_Event: ["All", "Ceremony", "Workshop"],
                manageCategories_Homework: ["All", "Easy", "Urgent"],
                /**/
                pomodorotimer: {
                    workminutes: 25,
                    breakminutes: 5
                }
            }
        });
    }
    catch (error) {
        console.log('error createuser:', error);
    }
}



export const handleDeleteUser = async (id: string, email: string) => {
    try {
        await API.graphql({
            query: deleteUser,
            variables: {
                id,
                email
            }
        });
    }
    catch (error) {
        console.log('error deleteuser:', error);
    }
}



export const handleEditUser = async (
    { id, firstname, lastname, email, password, dark, bandwidth, storage, regestrationDate, endingDate, semester, pageSettings, semester_1, semester_2, schedule, timetable, teacher, note, task, event, homework, manageCategories_Task, manageCategories_Event, manageCategories_Homework, pomodorotimer }
        : {
            id: string, firstname: string, lastname: string, email: string, password: string, /*extra compulsory*/ dark: boolean, bandwidth: number, storage: number, regestrationDate: Date, endingDate: Date, semester: number, pageSettings: { homework: boolean, events: boolean, tasks: boolean, notes: boolean, absences: boolean, teachers: boolean, subjects: boolean, schedule: boolean, timetable: boolean, grades: boolean },
            /**/
            semester_1: {
                absence: [{
                    id: string,
                    /**/
                    subject: string,
                    teacher: string,
                    room: string,
                    note: string,
                    date: Date
                }],
                subject: [{
                    id: string,
                    /**/
                    subject: string,
                    teacher: string,
                    room: string,
                    note: string
                }],
                grade: [{
                    id: string,
                    /**/
                    subject: string,
                    grade: string,
                    weight?: number,
                    note: string,
                    date: Date,
                    maxpoints?: number,
                    credit?: number,
                    course?: string,
                    /*other properties*/
                    gradeSystem: string
                }]
            },
            semester_2: {
                absence: [{
                    id: string,
                    /**/
                    subject: string,
                    teacher: string,
                    room: string,
                    note: string,
                    date: Date
                }],
                subject: [{
                    id: string,
                    /**/
                    subject: string,
                    teacher: string,
                    room: string,
                    note: string
                }],
                grade: [{
                    id: string,
                    /**/
                    subject: string,
                    grade: string,
                    weight?: number,
                    note: string,
                    date: Date,
                    maxpoints?: number,
                    credit?: number,
                    course?: string,
                    /*other properties*/
                    gradeSystem: string
                }]
            },
            /**/
            schedule: [{
                title: string, start: Date, end: Date,
                /*other properties*/
                id: string,
                subject: string,
                teacher: string,
                room: string,
                note: string,
                time1: string,
                time2: string,
                colorpicker: string,
                typeValue: string
            }],
            timetable: [{
                title: string, day: Date,
                /*other properties*/
                id: string,
                subject: string,
                teacher: string,
                room: string,
                note: string,
                time1: string,
                time2: string,
                colorpicker: string
            }],
            teacher: [{
                id: string,
                /**/
                name: string,
                phone: number,
                email: string,
                address: string,
                officehours: number,
                website: string
            }],
            note: [{
                id: string,
                /**/
                noteTitle: string,
                description: string,
                /*other properties*/
                topics: [{
                    id: string,
                    topicTitle: string,
                    topicDescrip: string
                    colorpicker: string
                }]
            }],
            task: [{
                id: string,
                /**/
                task: any[],
                note: any[],
                date?: string,
                time?: string,
                category: any[],
                repeat: string,
                date2?: string,
                selectedDays_WEEKLY?: string[],
                selectedDays_MONTHLY?: string[],
                datesBwDate1NDate2?: string[],
                checked: boolean
            }],
            event: [{
                id: string,
                /**/
                event: any[],
                subject: any[],
                location: any[],
                note: any[],
                date?: string,
                time?: string,
                category: any[],
                repeat: string,
                date2?: string,
                selectedDays_WEEKLY?: string[],
                selectedDays_MONTHLY?: string[],
                datesBwDate1NDate2?: string[],
                checked: boolean
            }],
            homework: [{
                id: string,
                /**/
                subject: any[],
                teacher: any[],
                note: any[],
                date?: string,
                time?: string,
                category: any[],
                repeat: string,
                type: any[],
                date2?: string,
                selectedDays_WEEKLY?: string[],
                selectedDays_MONTHLY?: string[],
                datesBwDate1NDate2?: string[],
                checked: boolean
            }]
            /**/
            manageCategories_Task: string[],
            manageCategories_Event: string[],
            manageCategories_Homework: string[]
            /**/
            pomodorotimer: object
        }) => {

    delete pageSettings.__typename;

    delete semester_1.__typename;
    semester_1.absence.forEach((absence: any) => { delete absence.__typename; });
    semester_1.subject.forEach((subject: any) => { delete subject.__typename; });
    semester_1.grade.forEach((grade: any) => { delete grade.__typename; });

    delete semester_2.__typename;
    semester_2.absence.forEach((absence: any) => { delete absence.__typename; });
    semester_2.subject.forEach((subject: any) => { delete subject.__typename; });
    semester_2.grade.forEach((grade: any) => { delete grade.__typename; });

    teacher.forEach((teacher: any) => { delete teacher.__typename; });

    note.forEach((note: any) => {
        delete note.__typename;
        note.topics.forEach((note_: any) => {
            delete note_.__typename;
        });
    });

    task.forEach((task: any) => {
        delete task.__typename;
        task.checked.forEach((task_: any) => {
            delete task_.__typename;
        });
    });

    event.forEach((event: any) => {
        delete event.__typename;
        event.checked.forEach((event_: any) => {
            delete event_.__typename;
        });
    });

    homework.forEach((homework: any) => {
        delete homework.__typename;
        homework.checked.forEach((homework_: any) => {
            delete homework_.__typename;
        });
    });

    schedule.forEach((schedule: any) => { delete schedule.__typename; });

    timetable.forEach((timetable: any) => { delete timetable.__typename; });

    delete pomodorotimer.__typename;


    console.log({
        id,
        firstname,
        lastname,
        email,
        password,
        /**/
        dark,
        bandwidth,
        storage,
        regestrationDate,
        endingDate,
        semester,
        pageSettings,
        /**/
        semester_1,
        semester_2,
        /**/
        schedule,
        timetable,
        teacher,
        note,
        task,
        event,
        homework,
        /**/
        manageCategories_Task,
        manageCategories_Event,
        manageCategories_Homework,
        /**/
        pomodorotimer
    });

    try {
        await API.graphql({
            query: editUser,
            variables: {
                id,
                firstname,
                lastname,
                email,
                password,
                /**/
                dark,
                bandwidth,
                storage,
                regestrationDate,
                endingDate,
                semester,
                pageSettings,
                /**/
                semester_1,
                semester_2,
                /**/
                schedule,
                timetable,
                teacher,
                note,
                task,
                event,
                homework,
                /**/
                manageCategories_Task,
                manageCategories_Event,
                manageCategories_Homework,
                /**/
                pomodorotimer
            }
        });
    }
    catch (error) {
        console.log('error edituser:', error);
    }
}