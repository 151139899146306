import React from 'react';
//MATETRIAL-UI
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
//CONTEXT-API
import { useStateValue } from '../../../../../../StateContext';


//FOR MUI INPUT BORDER-RADUIS
const useStyles = makeStyles({
    root: {
        [`& fieldset`]: {
            borderRadius: 16.5
        }
    }
});


//FOR MUI SELECT BOX
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}


interface SelectMultipleWithCheckboxProps {
    heading: string;
    options: string[];
    list   : string[];
    setList: Function;
}

function SelectMultipleWithCheckbox({
    heading,
    options,
    list,
    setList
}: SelectMultipleWithCheckboxProps) {
    const classes = useStyles();
    const [ { user }, dispatch ] = useStateValue();


    const handleChange = (event) => {
        const { target: { value } } = event;
        setList(
           // On autofill we get a stringified value.
           typeof value === 'string' ? value.split(',') : value,
        );
    }


    return (
        <FormControl className={classes.root}>
            <InputLabel className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}> {heading} </InputLabel>
            <Select
                multiple
                value={list}
                onChange={handleChange}
                input={<OutlinedInput label={heading}/>}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
                className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}
            >
                {options.map((item) => (
                    <MenuItem key={item} value={item} className="!w-[70%]" style={{ margin: "0 auto" }}>
                        <ListItemText primary={item} />
                        <Checkbox checked={list.indexOf(item) > -1} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectMultipleWithCheckbox;