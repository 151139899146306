import React from 'react';
//CONTEXT-API
import { useStateValue } from '../../StateContext';


function Signup() {
    const [{ user }] = useStateValue();


    return (
        <div className={`flex items-center flex-col ${`${user && user?.dark ? `!bg-[#5e5b5b]` : `!bg-white`}`} !rounded-lg !mt-[-2.85rem] !w-[27rem] !h-[17.7545rem] !pl-2.5 !pr-2.5`} style={{ margin: "0 auto" }}>

            <p className="text-sm text-justify text-gray-600 w-[80%] !mt-10" style={{ margin: "0 auto" }}>
                Tructivity is a game-changer when it comes to maximizing your productivity as a student and we want to give you early access by forming this simple two-question <a className="!text-blue-500 !underline !mt-6" href="https://docs.google.com/forms/d/e/1FAIpQLSfo0tQ9uykJiKMHaLRSKgwoMFpbhgB-hNViqkhQbU1kCAaw3w/viewform"> Google Form </a>.
            </p>

            <p className="text-sm text-justify text-gray-600 w-[80%] !mt-6" style={{ margin: "0 auto" }}>
                Imagine having a head start on boosting your productivity and achieving academic success with a tool that is designed specifically for your needs so don't miss out on this opportunity to supercharge your student life!
            </p>

        </div>
    )
}

export default Signup;