import React, { useState, useEffect } from 'react';
//COMPONENTS
import BigCalendar_Fixed from '../utils/BigCalender_Fixed';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import TextField from '@mui/material/TextField';
import Button from '@material-ui/core/Button';
import TimelapseIcon from '@material-ui/icons/Timelapse';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
//MATERIAL-UI ( DATE PICKER )
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
//MATERIAL-UI ( Autocomplete )
import MUI_Autocomplete from '../utils/MUI_Autocomplete';
//CONTEXT-API
import { useStateValue } from '../../StateContext';
//APPSYNC-MUTATION
import { handleEditUser } from '../../appsync_functions/mutations';
//UNIQUE-ID
import uniqid from 'uniqid';
//SCSS
import './index.scss';
import './_Form.scss';
import './_Dialog.scss';


function convertStringDateToGetDayName(str: string) {
    const date = new Date(str);
    const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    const dayIndex = date.getDay();

    return days[dayIndex];
}


function convertStringDateToGetTime(str: string) {
    const date = new Date(str);

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const timeString = `${hours}:${minutes}:${seconds}`;

    return timeString;
}


function getFormatDate(dayName, time) {
    const days = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];
    const dayIndex = days.indexOf(dayName.toUpperCase());
    if (dayIndex === -1) {
        return "Invalid day name";
    }
    
    const [hours, minutes, seconds] = time.split(':').map(Number);
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
        return "Invalid time format";
    }

    const currentDate = new Date(2024, 4, 6 + dayIndex, hours, minutes, seconds);
    return currentDate.toString();
}


//FOR MUI INPUT BORDER-RADUIS
//FOR MUI DIALOG WIDTH/HEIGHT
const useStyles = makeStyles({
    root: {
        [`& fieldset`]: {
            borderRadius: 16.5
        }
    },
    paper: {
        minWidth: "42.9vh",
        maxWidth: "42.9vh",
        minHeight: "27.9vh",
        maxHeight: "27.9vh",
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
});


function Timetable() {
    const classes = useStyles();


    const [subject, setSubject] = useState<string>("");
    const [teacher, setTeacher] = useState<string>("");
    const [room, setRoom] = useState<string>("");
    const [note, setNote] = useState<string>("");


    const [time1, setTime1] = useState<any>("2014-08-18T21:00:00");
    const handleTime1 = (time: Date) => setTime1(time);
    const [time2, setTime2] = useState<any>("2014-08-18T22:00:00");
    const handleTime2 = (time: Date) => setTime2(time);


    const [colorpicker, setColorpicker] = useState<string>("#e40fe4");


    /******************************BOX CALENDER********************************/
    const [{ user, refetchLoadingAppSync }, dispatch] = useStateValue();


    const [open, setOpen] = useState<boolean>(false);
    const [openCreated, setOpenCreated] = useState<boolean>(false);
    const [openCreatedId, setOpenCreatedId] = useState<string | null>(null);
    const handleEvent = ({ start, end }) => {
        setOpen(true);

        setTime1(start);  /*dynamic start for ( dialog )*/
        setTime2(end);    /*dynamic end   for ( dialog )*/
    }
    const handleOpened = (e: any) => {
        setOpen(true);
        setOpenCreated(true);
        setOpenCreatedId(e.id);

        /*setting*/
        setSubject(e.subject);
        setTeacher(e.teacher);
        setRoom(e.room);
        setNote(e.note);
        setTime1(e.time1);
        setTime2(e.time2);
        setColorpicker(e.colorpicker);
    }
    /******************************BOX CALENDER********************************/


    const [clicked, setClicked] = useState<boolean>(false);


    const [openDialog_Message, setOpenDialog_Message] = useState<boolean>(false);


    const clearAllStateProperties = () => {
        /*for reset when dialog closed the popup*/
        /*resetting*/
        setSubject("");
        setTeacher("");
        setRoom("");
        setNote("");
        setTime1("2014-08-18T21:00:00");
        setTime2("2014-08-18T22:00:00")
        setColorpicker("#e40fe4");
        /*logical*/
        setOpenCreated(false);
        /*for reset when dialog closed the popup*/
    }


    var timetable = [];
    var subject_ = [];
    var teacher_ = [];
    timetable = user?.timetable;
    teacher_ = user?.teacher;
    if (user && (user?.semester === 1)) { subject_ = user?.semester_1?.subject; }
    else if (user && (user?.semester === 2)) { subject_ = user?.semester_2?.subject; }


    useEffect(() => {
        if (subject_) {
            const thatSubject = subject_.find((item) => item.subject === subject);

            if (
                thatSubject
            ) {
                setTeacher(thatSubject.teacher);
                setRoom(thatSubject.room);
            }
        }
    }, [subject]);


    useEffect(() => {
        const thatTeacher = teacher_.find((item) => item.name === teacher);

        if (
            thatTeacher
        ) {
            setTeacher(thatTeacher.name);
        }
    }, [teacher]);


    return (
        <>
            <div className="timetable">
                <div className={`${user?.dark ? `timetable-dark` : `timetable-light`}`}>
                    <BigCalendar_Fixed
                        events={(timetable)
                            ?.map((item: any) => {
                                return {
                                    ...item, start: getFormatDate(item.day, item.time1), end: getFormatDate(item.day, item.time2),
                                    time1: getFormatDate(item.day, item.time1), time2: getFormatDate(item.day, item.time2)
                                }
                            })
                            .map((item: any) => {
                                return {
                                    ...item, start: new Date(item.start), end: new Date(item.end),
                                    time1: new Date(item.start).toString(), time2: new Date(item.end).toString()
                                }
                            })
                        }
                        handleEvent={handleEvent}
                        handleOpened={handleOpened}
                    />
                </div>

                <Dialog
                    open={refetchLoadingAppSync}
                    className="dialog"
                    classes={{ paper: classes.paper }}
                >
                    <CircularProgress size={40} />
                </Dialog>
            </div>


            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                    /*FIX*/
                    clearAllStateProperties();
                }}
                className="dialog"
            >
                <DialogContent>
                    <form
                        className="form"
                        autoComplete="off"
                        onSubmit={(e) => {
                            e.preventDefault();
                            setClicked(true);


                            const day = convertStringDateToGetDayName(time1);
                            const time1_ = convertStringDateToGetTime(time1);
                            const time2_ = convertStringDateToGetTime(time2);


                            if (
                                subject !== ""
                            ) {
                                if (!openCreated) {   /*for not recreate already created one*/
                                    //editing on DB by AppSync
                                    handleEditUser({ ...user, timetable: [...user?.timetable, { id: uniqid(), title: subject, day, /*other properties*/ subject, teacher, room, note, time1: time1_, time2: time2_, colorpicker }] });
                                }
                                else {               /*for update already created one*/
                                    //editing on DB by AppSync
                                    handleEditUser({ ...user, timetable: user?.timetable.map((item) => { if (item.id === openCreatedId) { return { id: item.id, title: subject, day, /*other properties*/ subject, teacher, room, note, time1: time1_, time2: time2_, colorpicker }; } else { return item } }) });

                                    setOpenCreatedId(null);
                                }


                                setOpen(false);
                                setClicked(false);
                            }


                            /*FIX*/
                            clearAllStateProperties();
                        }}
                    >
                        <Typography variant="h4" className={`${user?.dark ? `!text-[#eee]` : `!text-[#000]`}`}> Timetable </Typography>

                        {
                            subject_ && (
                                <MUI_Autocomplete
                                    alloptions={subject_}
                                    value={subject}
                                    setValue={setSubject}
                                    label="Subject"
                                />
                            )
                        }

                        {subject === "" && clicked && <p className="err"> Required Field </p>}

                        {
                            teacher_ && (
                                <MUI_Autocomplete
                                    alloptions={teacher_}
                                    value={teacher}
                                    setValue={setTeacher}
                                    label="Teacher"
                                />
                            )
                        }

                        <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} value={room} onChange={(e) => setRoom(e.target.value)} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Room</p>} variant="outlined" /> </div>

                        <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} value={note} onChange={(e) => setNote(e.target.value)} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Note</p>} variant="outlined" /> </div>

                        <div className="!w-[100%] flex items-center">
                            <div className="!w-[50%] mr-1">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        value={time1}
                                        onChange={handleTime1}
                                        label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Start</p>}
                                        renderInput={(params) => <TextField {...params} className={classes.root} sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} />}
                                        components={{
                                            OpenPickerIcon: (props) => (
                                                <TimelapseIcon {...props} sx={{ color: 'red' }} />
                                            )
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>

                            <div className="!w-[50%] ml-1">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <TimePicker
                                        value={time2}
                                        onChange={handleTime2}
                                        label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>End</p>}
                                        renderInput={(params) => <TextField {...params} className={classes.root} sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} />}
                                        components={{
                                            OpenPickerIcon: (props) => (
                                                <TimelapseIcon {...props} sx={{ color: 'red' }} />
                                            )
                                        }}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>

                        <div className="mt-3.5 p-3.5 rounded-2xl flex items-center" style={{ border: "1px solid #bdb8b8" }}>
                            <span className="mt-[-0.1025rem]"> Color </span>
                            <input
                                id="color"
                                className="cursor-pointer ml-2"
                                type="color"
                                value={colorpicker}
                                onChange={(e) => setColorpicker(e.target.value)}
                            />
                        </div>

                        <div className="form_btns">
                            <div className="form_btns_flexy" />
                            <Button
                                className="form_btns_cancelBtn"
                                disableRipple
                                onClick={() => {
                                    setOpen(false);

                                    if (
                                        subject !== ""
                                    ) {
                                        setOpenDialog_Message(true);
                                    }
                                }}
                            > Delete </Button>
                            <Button className="form_btns_addBtn" type="submit"> Save </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>


            <Dialog
                open={openDialog_Message}
                onClose={() => {
                    setOpenDialog_Message(false);
                    /*FIX*/
                    clearAllStateProperties();
                }}
                className="dialog"
            >
                <div className="dialog_selectsemester_msg pt-6 pl-6 pr-6 pb-3.5">
                    <p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Are you sure you want to delete this subject? </p>

                    <div className="dialog_selectsemester_msg_btns">
                        <div className="dialog_selectsemester_msg_btns_flexy" />
                        <button
                            className={`dialog_selectsemester_btns_cancelBtn ${user?.dark && `hover:!bg-gray-600`} p-1.5 w-16 rounded-lg mr-[0.265rem]`}
                            onClick={() => {
                                setOpenDialog_Message(false);
                                /*FIX*/
                                clearAllStateProperties();
                            }}
                        > No </button>
                        <button
                            className="dialog_selectsemester_btns_addBtn p-1.5 w-16 rounded-lg ml-[0.265rem]"
                            onClick={() => {
                                if (
                                    subject !== ""
                                ) {
                                    //editing on DB by AppSync
                                    handleEditUser({ ...user, timetable: user?.timetable.filter((item) => { return item.id !== openCreatedId; }) });

                                    setOpenCreatedId(null);
                                }

                                setOpenDialog_Message(false);
                                /*FIX*/
                                clearAllStateProperties();
                            }}
                        > Yes </button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default Timetable;