import React, { useState, useEffect } from 'react';
import icon from '../../images/icon.png';
//COMPONENTS
import OpenedItem from './OpenedItem';
//MATERIAL-UI
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@mui/material/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import { makeStyles } from '@material-ui/core/styles';
//DOWNLOAD
import axios from 'axios';
import fileDownload from 'js-file-download';
//AWS-AMPLIFY
import { Storage } from 'aws-amplify';
//BYTES
import bytes from 'bytes';
import convertSize from 'convert-size';
//CONTEXT-API
import { useStateValue } from '../../StateContext';
//APPSYNC-MUTATION
import { handleEditUser } from '../../appsync_functions/mutations';
//UNIQUE-ID
import uniqid from 'uniqid';
//SCSS
import './index.scss';


//FOR MUI INPUT BORDER-RADUIS
//FOR MUI DIALOG WIDTH/HEIGHT
const useStyles = makeStyles({
    root: {
        [`& fieldset`]: {
            borderRadius: 16.5
        }
    },
    paper: {
        minWidth: '29rem',
        maxWidth: '29rem'
    },
    paper_: {
        minWidth: '31.35rem',
        maxWidth: '31.35rem',
        minHeight: '13.85rem',
        maxHeight: '13.85rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
});


//Bytes To MBs
function BytesToMBs(a,b=2){if(0===a)return"0 Bytes";const c=0>b?0:b,d=Math.floor(Math.log(a)/Math.log(1024));return parseFloat((a/Math.pow(1024,d)).toFixed(c))+" "+["Bytes","KB","MB","GB","TB","PB","EB","ZB","YB"][d]}


var h = 5.25;
var increaseDyanamicStyle = (n: number = 0) => h = h+n;


function Notes() {
    const classes = useStyles();
    const [ { user }, dispatch ] = useStateValue();


    const [ noteTitle, setNoteTitle ] = useState<string>("");
    const [ description, setDescription ] = useState<string>("");


    const [ topicTitle, setTopicTitle ] = useState<string>("");
    const [ topicDescrip, setTopicDescrip ] = useState<string>("");


    var note;
    note = user?.note;


    const [ selectedData, setSelectedData ] = useState<{
        isSelected : boolean;
        /*TEMPORARY STORED*/
        id         : number;
        noteTitle  : string;
        description: string;
        topics     : any[];
    }|null>(null);
    /*for react-quill*/
    const [ selectedDataTopic, setSelectedDataTopic ] = useState<{
        /*TEMPORARY STORED*/
        id          : number;
        topicTitle  : string;
        topicDescrip: string;
        colorpicker : string;
    }|null>(null);


    const [ open_dialog1, setOpen_dialog1 ] = useState<boolean>(false);
    const [ open_dialog2, setOpen_dialog2 ] = useState<boolean>(false);
    const [ open_dialog3, setOpen_dialog3 ] = useState<boolean>(false);
    const [ open_dialog4, setOpen_dialog4 ] = useState<boolean>(false);
    const [ open_dialog5, setOpen_dialog5 ] = useState<boolean>(false);


    const [ displayWallpaper, setDisplayWallpaper ] = useState<boolean>(true);
    const [ isLoading, setIsLoading ] = useState<boolean>(false);


    const [ isUploading, setIsUploading ] = useState<boolean>(false);
    const [ isDownloading, setIsDownloading ] = useState<boolean>(false);
    const [ isDeleting, setIsDeleting ] = useState<boolean>(false);


//    
    const [ holdedItemForDelete, setHoldedItemForDelete ] = useState<any>(null);


    const [ files, setFiles ] = useState<any[]>([]);
    async function fetchFiles() {
        let files = await Storage.list("");

        //file's other stuff
        let arr1 = [ ...files ];

        //file's links of each
        let arr2 = await Promise.all(files.map(async k => {
            const url = await Storage.get(k.key);
            return url;
        }));

        //combine both arrays
        files = arr1.map((item, i) => {
            return { ...item, url: arr2[i] }
        })

        console.log('file: ', files);

        setFiles(files);
    }
    async function uploadFile(e) {
        const file = e.target.files[0];


        if(
            file.size >
            (+2e+8).toFixed(0)     //200000000 (200MB)
        ) {
            setOpen_dialog5(true);
            return null;
        }


        /*for popup*/
        setIsUploading(true);


        /********************************************************************/
        Storage.put(file.name, file, {
            contentType: file.type
        })
        .then(() => {
            fetchFiles();
            //editing on DB by AppSync
            handleEditUser({ ...user, pageSettings: { ...user?.pageSettings, storage: user?.storage+convertSize(file.size, "MB") } });
            /*for popup*/
            setIsUploading(false);
            /*consoled*/
            console.log('Uploaded File to S3');
        })
        .catch((e) => {
            console.log('Uploading File from S3 error: ', e);
        });
        /********************************************************************/
    }
    async function downloadFile(item) {
        /*for popup*/
        setIsDownloading(true);


        /********************************************************************/
        axios.get(item.url, {
            responseType: 'blob'
        })
        .then((res) => {
            fileDownload(res.data, item.key);
            //editing on DB by AppSync
            handleEditUser({ ...user, pageSettings: { ...user?.pageSettings, bandwidth: user?.bandwidth+item.size } });
            /*for popup*/
            setIsDownloading(false);
            /*consoled*/
            console.log('Downloaded File from S3 error');
        })
        .catch((e) => {
            console.log('Downloading File from S3 error: ', e);
        });
        /********************************************************************/
    }
    async function deleteFile(item) {
        /*for popup*/
        setIsDeleting(true);


        /********************************************************************/
        Storage.remove(item.key)
        .then(() => {
            fetchFiles();
            //editing on DB by AppSync
            handleEditUser({ ...user, pageSettings: { ...user?.pageSettings, storage: user?.storage-item.size } });
            /*for popup*/
            setIsDeleting(false);
            /*consoled*/
            console.log('Deleted File from S3');
        })
        .catch((e) => {
            console.log('Deleting File from S3 error: ', e);
        });
        /********************************************************************/
    }
    useEffect(() => {
        fetchFiles();
    }, []);


    //WHEN-USER-STOP-TYPING-THEN-RUN-QUERY
    //https://erikmartinjordan.com/start-search-user-not-typing
    useEffect(() => {

        const callFunc = () => {
            if(
                selectedData&&
                selectedData.isSelected
            ) {
                if(
                    selectedDataTopic&&
                    (
                        selectedDataTopic.topicTitle!==""
                      ||topicTitle!==""
                    )
                  &&(
                        selectedDataTopic.topicDescrip!==""
                      ||topicDescrip!==""
                    )
                ) {
                    let topicTitle;
                    let topicDescrip;

                    if(selectedDataTopic.topicTitle!=="") {
                        topicTitle = selectedDataTopic.topicTitle;
                    }
                    else if(topicTitle!=="") {
                        topicTitle = topicTitle;
                    }

                    if(selectedDataTopic.topicDescrip!=="") {
                        topicDescrip = selectedDataTopic.topicDescrip;
                    }
                    else if(topicDescrip!=="") {
                        topicDescrip = topicDescrip;
                    }


                    /*replace object property of specific id's objects in array of object*/
                    //editing on DB by AppSync
                    handleEditUser({
                        ...user,
                        note: [
                            /*STEP-1 remove object of specific id in array of objects*/
                            ...user?.note.filter(item => item.id != selectedData.id),
                            {
                                /*STEP-2 find object of specific id in array of objects*/
                                ...user?.note.find(item => item.id == selectedData.id),
                                topics: [
                                    ...user?.note.find(item => item.id == selectedData.id).topics
                                    .filter(item => item.id != selectedDataTopic.id),
                                    {
                                        ...user?.note.find(item => item.id == selectedData.id).topics
                                        .filter(item => item.id == selectedDataTopic.id)[0],
                                        topicTitle,
                                        topicDescrip
                                    }
                                ]
                            }
                        ]
                    });
                }
            }
        }
    
        // Function launches after 1.5 seconds (1500 ms) of the last keystroke
        // On first render you don't want to launch anything
        // Thus, you check if the user typed a query at first
        let timer = setTimeout(() => callFunc(), 1500);
    
        // If useEffect() relaunches, you clear the function
        // That means, the previous function won't launch
        // Thus, won't send a request to the API
        return () => clearTimeout(timer);
    
    }, [selectedDataTopic, topicDescrip]);
    //WHEN-USER-STOP-TYPING-THEN-RUN-QUERY
    //https://erikmartinjordan.com/start-search-user-not-typing
if(selectedDataTopic){
    console.log(selectedDataTopic&&selectedDataTopic.topicTitle)
}
console.log(topicTitle)

    return (
        <>
            <div className={`notes ${user?.dark&&`notes-dark`}`}>

                <div className={`notes_left ${`${user?.dark?`!bg-[#5e5b5b]`:`!bg-white`}`}`}>
                    <div className="notes_left_top">
                        <div className={`notes_left_top_flexy !mt-2.5 !ml-2
                            ${(!selectedData
                             ||(selectedData&&!selectedData.isSelected))
                            &&`invisible`}
                        `}>
                            <IconButton
                                onClick={() => {
                                    setTimeout(() => {
                                        setDisplayWallpaper(true);
                                        /*resetting*/
                                        setSelectedData(null);
                                        /*reset textfields*/
                                        setSelectedDataTopic(null);
                                        setTopicTitle("");
                                        setTopicDescrip("");    
                                    }, 300);
                                }}
                            >
                                <ArrowBackIcon/>
                            </IconButton>
                        </div>

                        <IconButton
                            className="!mt-2.5"
                            disabled={isLoading}
                            onClick={() => {
                                if(
                                   selectedData&&
                                   selectedData.isSelected
                                ) {
                                    if(
                                        selectedDataTopic&&
                                        (
                                            selectedDataTopic.topicTitle!==""
                                          ||topicTitle!==""
                                        )
                                      &&(
                                            selectedDataTopic.topicDescrip!==""
                                          ||topicDescrip!==""
                                        )
                                    ) {
                                        setOpen_dialog2(true);
                                        /*reset textfields*/
                                        setTopicTitle("");
                                        setTopicDescrip("");
                                    }
                                    //NOT USING POPUP
                                    else if(
                                            selectedDataTopic
                                        &&(
                                            selectedDataTopic.topicTitle!==""
                                          ||topicTitle!==""
                                        )
                                        &&(
                                            selectedDataTopic.topicDescrip===""
                                          ||topicDescrip===""
                                        )
                                    ) {
                                        setOpen_dialog2(true);
                                        /*reset textfields*/
                                        setTopicTitle("");
                                    }
                                    //NOT USING POPUP
                                    else if(
                                            selectedDataTopic
                                        &&(
                                            selectedDataTopic.topicTitle===""
                                          ||topicTitle===""
                                        )
                                        &&(
                                            selectedDataTopic.topicDescrip!==""
                                          ||topicDescrip!==""
                                        )
                                    ) {
                                        setOpen_dialog2(true);
                                        /*reset textfields*/
                                        setTopicDescrip("");
                                    }
                                    //USING POPUP
                                    else if(
                                        topicTitle===""
                                      ||topicDescrip===""
                                    ) {
                                        setOpen_dialog2(true);
                                        /*reset textfields*/
                                        setTopicTitle("");
                                        setTopicDescrip("");
                                    }
                                }

                                else {
                                    setOpen_dialog1(true);
                                }
                            }}
                        >
                            <AddIcon/>
                        </IconButton>
                    </div>

                    {
                        (selectedData&&
                         selectedData.isSelected)?(
                            <OpenedItem
                                selectedData={selectedData}
                                data={note}
                                setSelectedDataTopic={setSelectedDataTopic}
                                setTopicTitle={setTopicTitle}
                                setDisplayWallpaper={setDisplayWallpaper}
                            />
                        ):(
                            <List>
                                {
                                    (
                                        note
                                        .sort((a,b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0))
                                    ).map((item, i) => (
                                        <ListItem
                                            key={i}
                                            style={{ margin: "0 auto" }}
                                            className={`!mt-3.5 !pt-0 !pb-1.5 !pl-6 !w-[100%] !rounded-sm !bg-transparent !cursor-pointer hover:!bg-[#eee] ${user?.dark?`!text-white hover:!text-black`:`!text-black hover:!text-black`}`}
                                            onClick={() => {
                                                setTimeout(() => {
                                                    setSelectedData({
                                                        isSelected : true,
                                                        /*TEMPORARY STORED*/
                                                        id         : item.id,
                                                        noteTitle  : item.noteTitle,
                                                        description: item.description,
                                                        topics     : []
                                                    });
                                                }, 300);
                                            }}
                                        >
                                            <div className="flex items-center ml-[-0.855rem]">
                                                <div className="flex flex-col !w-[10.225rem]">
                                                    <div
                                                        className="text-[0.9rem] mt-1.5 font-medium"
                                                        style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
                                                    >
                                                        {item.noteTitle}
                                                    </div>

                                                    <div
                                                        className="text-[0.7rem] mt-0"
                                                        style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
                                                    >
                                                        {item.description}
                                                    </div>
                                                </div>

                                                <IconButton
                                                    className="w-12 h-12 mt-1.5"
                                                    onClick={(e: any) => {
                                                       if (!e) var e: any = window.event;
                                                       e.cancelBubble = true;
                                                       if (e.stopPropagation) e.stopPropagation();
                                                       /*CODE*/
                                                        setOpen_dialog3(true);
                                                        setSelectedData({
                                                            isSelected : false, /*because only dustbin clicked*/
                                                            /*TEMPORARY STORED*/
                                                            id         : item.id,
                                                            noteTitle  : "",    /*because only dustbin clicked*/
                                                            description: "",    /*because only dustbin clicked*/
                                                            topics     : []     /*because only dustbin clicked*/
                                                        });
                                                    }}
                                                >
                                                    <DeleteIcon style={{ fill: user?.dark?"#c0bbbb":"black" }}/>
                                                </IconButton>
                                            </div>
                                        </ListItem>
                                    ))
                                }
                            </List>
                        )
                    }
                </div>
        
                <div className="notes_middle"/>

                <div className={`notes_right ${`${user?.dark?`!bg-[#5e5b5b]`:`!bg-white`}`} ${`${user?.dark?`!text-white`:`!text-black`}`}`}>
                    {
                        displayWallpaper?(
                            <div className="w-full h-full flex flex-col justify-center items-center">

                                <figure className="w-[12rem] h-[11rem] !mt-[-5rem]" style={{ margin: "0 auto" }}>
                                    <img
                                        src={icon}
                                        alt="logo"
                                    />
                                </figure>

                                <p className={`text-lg ${user?.dark?`text-white`:`text-black`} mt-1.5`} style={{ wordSpacing: "0.0815rem" }}> Create a note or open one to start editing. </p>

                            </div>
                        ):(
                            <>
                                {
                                    isLoading?(
                                        <div className="w-full h-full flex flex-col justify-center items-center">
                                            <CircularProgress size={40} style={{ color: "rgb(41, 98, 255)" }}/>
                                            <p className={`text-sm ${user?.dark?`text-white`:`text-black`} mt-3`} style={{ wordSpacing: "0.0815rem" }}> Loading editor. Please wait... </p>
                                        </div>
                                    ):(
                                        <>
                                            {
                                               !(!selectedData
                                                ||selectedData&&!selectedData.isSelected)&&(
                                                    <div className="flex flex-col h-[0] pb-6 ">
                                                        <div className="pt-14">
                                                            <textarea
                                                                className="w-full !font-semibold !tracking-wide !bg-transparent !outline-none p-5 pl-14 !text-3xl !mt-[-4rem] textarea1"
                                                                placeholder="Note Title"
                                                                value={
                                                                    selectedDataTopic
                                                                    ?selectedDataTopic.topicTitle
                                                                    :topicTitle.html
                                                                }
                                                                onChange={(e) => {
                                                                    setSelectedDataTopic(prev => { return { ...prev, topicTitle: e.target.value } });
                                                                    setTopicTitle(e.target.value);
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if(e.key==='Enter') {
                                                                        e.preventDefault();
                                                                    }
                                                                }}
                                                                // disabled={topicTitle===""?true:false}
                                                            />
                                                        </div>


                                                        <div className="pt-[5.05rem]">
                                                            <textarea
                                                                className="w-full !bg-transparent !outline-none p-5 pl-14 text-sm !mt-[-4rem] textarea2"
                                                                placeholder="Description"
                                                                style={{ height: h+"rem" }}
                                                                value={
                                                                     selectedDataTopic
                                                                    ?selectedDataTopic.topicDescrip
                                                                    :topicDescrip
                                                                }
                                                                onChange={(e) => {
                                                                    if(selectedDataTopic) {
                                                                        setSelectedDataTopic(prev => { return { ...prev, topicDescrip: e.target.value } });
                                                                    }
                                                                    else {
                                                                        setTopicDescrip(e.target.value);
                                                                    }
                                                                }}
                                                                onKeyDown={(e) => {
                                                                    if(e.ctrlKey && e.key.toLowerCase()==='u') {
                                                                        e.preventDefault();
                                                                        document.getElementById('myFile').click();
                                                                    }
                                                                    else if(e.key==='Enter') {/*enter*/
                                                                        increaseDyanamicStyle(1);
                                                                    }
                                                                    else if(e.keyCode === 8) {/*backspace*/
                                                                        if(h != 5.25) {
                                                                            increaseDyanamicStyle(-1);
                                                                        }
                                                                    }
                                                                }}
                                                                // disabled={topicTitle===""?true:false}
                                                            />

                                                            <input
                                                                type="file"
                                                                id="myFile"
                                                                className="hidden"
                                                                onChange={(e) => uploadFile(e)}
                                                            />
                                                        </div>


                                                        <div className="pt-[0.85rem]">
                                                            {
                                                                files.map((item, i) => (
                                                                    <div
                                                                        key={i}
                                                                        style={{ margin: "0 auto" }}
                                                                        className={`flex items-center text-sm ${`${user?.dark?`!bg-white`:`!bg-[#5e5b5b]`}`} ${`${user?.dark?`!text-black`:`!text-white`}`} !w-[97.5%] !mt-2 p-2.5 rounded-lg`}
                                                                    >
                                                                        <p style={{ flex: 0.99 }} className="pl-1.5"> {item.key} </p>

                                                                        <div className="flex items-center">
                                                                            <p className="mr-1.5 tracking-wide"> {bytes(item.size)} </p>

                                                                            <IconButton
                                                                                size="small"
                                                                                onClick={() => downloadFile(item)}
                                                                            >
                                                                                <CloudDownloadIcon fontSize="small"/>
                                                                            </IconButton>

                                                                            <IconButton
                                                                                size="small"
                                                                                className="!ml-1.5"
                                                                                onClick={() => {
                                                                                    setOpen_dialog4(true);
                                                                                    setHoldedItemForDelete(item);
                                                                                }}
                                                                            >
                                                                                <DeleteIcon fontSize="small"/>
                                                                            </IconButton>
                                                                        </div>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </>
                                    )
                                }
                            </>
                        )
                    }
                </div>

            </div>


            <Dialog
                open={open_dialog1}
                onClose={() => setOpen_dialog1(false)}
                className="dialog"
            >
                <div className="dialog_notes form">
                    <div> <TextField sx={{ input: { color: `${user?.dark?`#bbc0c4`:`#455a64`}` } }} className={classes.root} value={noteTitle} onChange={(e) => setNoteTitle(e.target.value)} label={<p className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}>Category Name</p>} variant="outlined" inputProps={{ autoComplete: 'off' }}/> </div>

                    <div> <TextField sx={{ input: { color: `${user?.dark?`#bbc0c4`:`#455a64`}` } }} className={classes.root} value={description} onChange={(e) => setDescription(e.target.value)} label={<p className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}>Description</p>} variant="outlined" inputProps={{ autoComplete: 'off' }}/> </div>

                    <div className="dialog_notes_btns">
                        <div className="dialog_notes_btns_flexy"/>
                        <button className={`dialog_notes_btns_cancelBtn ${user?.dark&&`hover:!bg-gray-600`} p-1.5 w-16 rounded-lg mr-[0.265rem]`} onClick={() => {
                            setOpen_dialog1(false);
                            /*reset textfields*/
                            setNoteTitle("");
                            setDescription("");
                        }}>
                            Delete
                        </button>
                        <button className="dialog_notes_btns_addBtn p-1.5 w-16 rounded-lg ml-[0.265rem]" onClick={() => {
                            setOpen_dialog1(false);

                            if(
                                noteTitle===""&&
                                description!==""
                            ) {
                                //editing on DB by AppSync
                                handleEditUser({ ...user, note: [...user?.note, { id: uniqid(), /*STATE VALUE ASSIGNED*/ noteTitle: "", description, /*INIALLLY EMPTY ARRAY*/ topics: [] } ] });

                                /*reset textfields*/
                                setNoteTitle("");
                                setDescription("");
                            }
                            else if(
                                noteTitle!==""&&
                                description===""
                            ) {
                                //editing on DB by AppSync
                                handleEditUser({ ...user, note: [ ...user?.note, { id: uniqid(), /*STATE VALUE ASSIGNED*/ noteTitle, description: "Just Now", /*INIALLLY EMPTY ARRAY*/ topics: [] } ] });

                                /*reset textfields*/
                                setNoteTitle("");
                                setDescription("");
                            }
                            else if(
                                noteTitle!==""&&
                                description!==""
                            ) {
                                //editing on DB by AppSync
                                handleEditUser({ ...user, note: [ ...user?.note, { id: uniqid(), /*STATE VALUE ASSIGNED*/ noteTitle, description, /*INIALLLY EMPTY ARRAY*/ topics: [] } ] });

                                /*reset textfields*/
                                setNoteTitle("");
                                setDescription("");
                            }
                        }}>
                            Save
                        </button>
                    </div>
                </div>
            </Dialog>


            <Dialog
                open={open_dialog2}
                onClose={() => {
                    setOpen_dialog2(false);
                    /*reset textfields*/
                    setTopicTitle("");
                }}
                className="dialog"
            >
                <div className="dialog_notes form">
                    <div> <TextField sx={{ input: { color: `${user?.dark?`#bbc0c4`:`#455a64`}` } }} className={classes.root} value={topicTitle} onChange={(e) => setTopicTitle(e.target.value)} label={<p className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}>Title</p>} variant="outlined" inputProps={{ autoComplete: 'off' }}/> </div>

                    <div className="dialog_notes_btns">
                        <div className="dialog_notes_btns_flexy"/>
                        <button className={`dialog_notes_btns_cancelBtn ${user?.dark&&`hover:!bg-gray-600`} p-1.5 w-16 rounded-lg mr-[0.265rem]`} onClick={() => {
                            setOpen_dialog2(false);
                            /*reset textfields*/
                            setTopicTitle("");
                        }}>
                            No
                        </button>
                        <button className="dialog_notes_btns_addBtn p-1.5 w-16 rounded-lg ml-[0.265rem]" onClick={() => {
                            setOpen_dialog2(false);

                            /*add object property of specific id's objects in array of objects*/
                            //editing on DB by AppSync
                            handleEditUser({
                                ...user,
                                    note: [
                                    /*STEP-1 remove object of specific id in array of objects*/
                                    ...user?.note.filter(item => item.id != selectedData.id),
                                    {
                                        /*STEP-2 find object of specific id in array of objects*/
                                        ...user?.note.find(item => item.id == selectedData.id),
                                        topics: [
                                            ...user?.note.find(item => item.id == selectedData.id).topics,
                                            {
                                                id: uniqid(),
                                                topicTitle,
                                                topicDescrip: "",
                                                colorpicker: "#03a9f4"
                                            }
                                        ]
                                    }
                                ]
                            });

                            /*reset textfields*/
                            setTopicTitle("");
                        }}>
                            Yes
                        </button>
                    </div>
                </div>
            </Dialog>


            <Dialog
                open={open_dialog3}
                onClose={() => {
                    setOpen_dialog3(false);
                    /*resetting*/
                    setSelectedData(null);
                }}
                className="dialog"
                classes={{ paper: classes.paper }}
            >
                <div className="dialog_warning pt-6 pl-6 pr-6 pb-3.5">
                    <p className={`text-[1.145rem] ml-1.5 ${user?.dark?`!text-[#bbc0c4]`:`!text-[rgb(69,90,100)]`}`}> Are you sure you want to delete this category? </p>

                    <div className="dialog_warning_btns">
                        <div className="dialog_warning_btns_flexy"/>
                        <button className={`dialog_warning_btns_cancelBtn ${user?.dark&&`hover:!bg-gray-600`} p-1.5 w-16 rounded-lg ml-2.5`} onClick={() => {
                            setOpen_dialog3(false);
                            /*resetting*/
                            setSelectedData(null);
                        }}>
                            No
                        </button>
                        <button className="dialog_warning_btns_addBtn p-1.5 w-16 rounded-lg ml-2.5" onClick={() => {
                            setOpen_dialog3(false);

                            //editing on DB by AppSync
                            handleEditUser({ ...user, note: user?.note.filter((item) => { return item.id !== selectedData.id; }) });

                            /*resetting*/
                            setSelectedData(null);
                        }}>
                            Yes
                        </button>
                    </div>
                </div>
            </Dialog>


            <Dialog
                open={open_dialog4}
                onClose={() => {
                    setOpen_dialog4(false);
                    /*reset*/
                    setHoldedItemForDelete(null);
                }}
                className="dialog"
                classes={{ paper: classes.paper }}
            >
                <div className="dialog_warning pt-6 pl-6 pr-6 pb-3.5">
                    <p className={`text-[1.145rem] ml-1.5 ${user?.dark?`!text-[#bbc0c4]`:`!text-[rgb(69,90,100)]`}`}> Are you sure you want to delete this file? </p>

                    <div className="dialog_warning_btns">
                        <div className="dialog_warning_btns_flexy"/>
                        <button className={`dialog_warning_btns_cancelBtn ${user?.dark&&`hover:!bg-gray-600`} p-1.5 w-16 rounded-lg ml-2.5`} onClick={() => {
                            setOpen_dialog4(false);
                            /*reset*/
                            setHoldedItemForDelete(null);
                        }}> No </button>
                        <button className="dialog_warning_btns_addBtn p-1.5 w-16 rounded-lg ml-2.5" onClick={() => {
                            setOpen_dialog4(false);
                            deleteFile(holdedItemForDelete);
                            /*reset*/
                            setHoldedItemForDelete(null);
                        }}> Yes </button>
                    </div>
                </div>
            </Dialog>


            <Dialog
                open={open_dialog5}
                onClose={() => setOpen_dialog5(false)}
                className="dialog"
                classes={{ paper: classes.paper }}
            >
                <div className="dialog_warning pt-6 pl-6 pr-6 pb-3.5">
                    <p className={`text-[1.145rem] ml-1.5 ${user?.dark?`!text-[#bbc0c4]`:`!text-[rgb(69,90,100)]`}`}> You can not upload files above 200MB </p>

                    <div className="dialog_warning_btns">
                        <div className="dialog_warning_btns_flexy"/>
                        <button className="dialog_warning_btns_addBtn p-1.5 w-16 rounded-lg ml-2.5" onClick={() => setOpen_dialog5(false)}> Ok </button>
                    </div>
                </div>
            </Dialog>


            <Dialog
                open={isUploading}
                className="dialog"
                classes={{ paper: classes.paper_ }}
            >
                <div className="w-full h-full flex flex-col justify-center items-center">
                    <CircularProgress size={40} style={{ color: "rgb(41, 98, 255)" }}/>
                    <p className={`text-sm ${user?.dark?`text-white`:`text-black`} mt-3`} style={{ wordSpacing: "0.0815rem" }}> Uploading. Please wait... </p>
                </div>
            </Dialog>


            <Dialog
                open={isDownloading}
                className="dialog"
                classes={{ paper: classes.paper_ }}
            >
                <div className="w-full h-full flex flex-col justify-center items-center">
                    <CircularProgress size={40} style={{ color: "rgb(41, 98, 255)" }}/>
                    <p className={`text-sm ${user?.dark?`text-white`:`text-black`} mt-3`} style={{ wordSpacing: "0.0815rem" }}> Downloading. Please wait... </p>
                </div>
            </Dialog>


            <Dialog
                open={isDeleting}
                className="dialog"
                classes={{ paper: classes.paper_ }}
            >
                <div className="w-full h-full flex flex-col justify-center items-center">
                    <CircularProgress size={40} style={{ color: "rgb(41, 98, 255)" }}/>
                    <p className={`text-sm ${user?.dark?`text-white`:`text-black`} mt-3`} style={{ wordSpacing: "0.0815rem" }}> Deleting. Please wait... </p>
                </div>
            </Dialog>
        </>
    )
}

export default Notes;