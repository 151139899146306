import React, { useState, useEffect } from 'react';
import icon from '../../images/icon.png';
import Splashscreen from './Splashscreen';
//GATSBY
import { navigate } from 'gatsby';
//COMPONENTS
import Drawer from '../utils/Drawer';
import Header from '../utils/Header';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
//MATERIAL-UI
import useMediaQuery from '@mui/material/useMediaQuery';
//CONTEXT-API
import { useStateValue } from '../../StateContext';
import { settingURL, settingUSER, settingREFETCHLOADINGAPPSYNC, settingTWOHALFSECONDSAFTERSIGNIN } from '../../contextapi';
//APPSYNC-QUERIES
import { fetchAllUsers } from '../../appsync_functions/queries';
//APPSYNC-SUBSCRIPTION
import { subscribeOnUser } from '../../appsync_functions/subscriptions';
//FIRST-RENDER
import useFirstRender from '../useFirstRender';
//SCSS
import './index.scss';
import Landing from '../Landing';


const _url = typeof window !== 'undefined' ? window.location.pathname : '';


interface LayoutProps {
    children: JSX.Element[] | JSX.Element;
}

function Layout({
    children
}: LayoutProps) {
    const [{ url, isSignedin, user, twohalfSecsAfter }, dispatch] = useStateValue();


    const firstRender = useFirstRender();
    useEffect(() => {
        if (_url === "/" || url === "/") {
            dispatch(settingURL("/"));
        }
        else if (_url === "/login/" || _url === "/login" || url === "/login/" || url === "/login") {
            dispatch(settingURL("/login"))
        }
        else if (_url === "/forgotPassword/" || _url === "/forgotPassword" || url === "/forgotPassword/" || url === "/forgotPassword") {
            dispatch(settingURL("/forgotPassword"))
        }
        else if (_url === "/signup/" || _url === "/signup" || url === "/signup/" || url === "/signup") {
            dispatch(settingURL("/signup"))
        }
        else if (_url === "/homework/" || _url === "/homework" || url === "/homework/" || url === "/homework") {
            dispatch(settingURL("/homework"));
        }
        else if (_url === "/events/" || _url === "/events" || url === "/events/" || url === "/events") {
            dispatch(settingURL("/events"));
        }
        else if (_url === "/tasks/" || _url === "/tasks" || url === "/tasks/" || url === "/tasks") {
            dispatch(settingURL("/tasks"));
        }
        else if (_url === "/notes/" || _url === "/notes" || url === "/notes/" || url === "/notes") {
            dispatch(settingURL("/notes"));
        }
        else if (_url === "/absences/" || _url === "/absences" || url === "/absences/" || url === "/absences") {
            dispatch(settingURL("/absences"));
        }
        else if (_url === "/teachers/" || _url === "/teachers" || url === "/teachers/" || url === "/teachers") {
            dispatch(settingURL("/teachers"));
        }
        else if (_url === "/subjects/" || _url === "/subjects" || url === "/subjects/" || url === "/subjects") {
            dispatch(settingURL("/subjects"));
        }
        else if (_url === "/schedule/" || _url === "/schedule" || url === "/schedule/" || url === "/schedule") {
            dispatch(settingURL("/schedule"));
        }
        else if (_url === "/timetable/" || _url === "/timetable" || url === "/timetable/" || url === "/timetable") {
            dispatch(settingURL("/timetable"));
        }
        else if (_url === "/grades/" || _url === "/grades" || url === "/grades/" || url === "/grades") {
            dispatch(settingURL("/grades"));
        }
        else if (_url === "/settings/" || _url === "/settings" || url === "/settings/" || url === "/settings") {
            dispatch(settingURL("/settings"));
        }
    }, [firstRender,]);


    /***************JUST FOR REFETCH AFTER BROWSER CLOSED FIRST TIME & REOPEN MULTIPLE TIMES (IF ALREADY LOGEDIN)***************/
    const fetchFunction = async () => {
        dispatch(settingREFETCHLOADINGAPPSYNC(true));


        const allUsers = await fetchAllUsers();
        /*trick to get one user from all*/
        const thatUser = allUsers.filter(item => (item.email === user?.email))[0];


        dispatch(settingUSER(thatUser));


        dispatch(settingREFETCHLOADINGAPPSYNC(false));
    }

    useEffect(() => {
        if (
            isSignedin
        ) {
            //'subscription' for first time
            subscribeOnUser(fetchFunction);
        }
    }, []);
    /***************JUST FOR REFETCH AFTER BROWSER CLOSED FIRST TIME & REOPEN MULTIPLE TIMES (IF ALREADY LOGEDIN)***************/


    const screen1000 = useMediaQuery('(min-width:1000px)');
    const [flag, setFlag] = useState<boolean>(false);
    const [delay, setDelay] = useState<boolean>(true);


    useEffect(() => {
        const navigationEntries = window.performance.getEntriesByType('navigation');
        if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
            dispatch(settingTWOHALFSECONDSAFTERSIGNIN(true));
        }

        setTimeout(() => {
            setFlag(true);
            dispatch(settingTWOHALFSECONDSAFTERSIGNIN(false));
        }, 2000);

        setTimeout(() => {
            setDelay(false);
        }, 1000);
    }, []);


    var themeMode = false;
    if (user) { themeMode = user?.dark; }


    const theme = createTheme({
        palette: {
            type: themeMode ? "dark" : "light"
        },
        overrides: {
            MuiDialog: {
                paper: {
                    overflow: "hidden"
                }
            }
        }
    });


    if (delay) return <div><></></div>;


    // if (
    //     flag &&
    //     !screen1000
    // ) {
    //     return (
    //         <div>
    //             <div className={`landing w-full ${themeMode ? `!bg-[#000000]` : `!bg-[#eee]`}`}>

    //                 <div className="ml-1 w-full">

    //                     <div className="flex flex-col items-center justify-center">

    //                         <img src={icon} alt="icon" className="w-[9.5rem] h-36 -ml-0.5" />

    //                         <p className="text-black font-bold text-3xl sm:text-4xl -mt-2"> Tructivity </p>

    //                         <p className="text-black font-medium text-sm sm:text-lg mt-2.5 w-[80%] text-center"> Tructivity is not supported on mobile browsers. </p>

    //                     </div>

    //                 </div>

    //             </div>
    //         </div>
    //     )
    // }


    /* FIX to show these pages */
    if (
        _url === "/privacy-policy"
        || _url === "/privacy-policy/"
        || _url === "/terms-and-conditions"
        || _url === "/terms-and-conditions/"
    ) {
        return <div><>{children}</></div>;
    }
    else if (
        url === "/login"
        || _url === "/login/"
        || url === "/signup"
        || _url === "/signup/"
        || url === "/register"
        //  || _url === "/register/"
        || url === "/forgotPassword"
        || _url === "/forgotPassword/"
    ) {
        if (!isSignedin) {
            return (
                <div>
                    <div className={`landing ${themeMode ? `!bg-[#000000]` : `!bg-[#eee]`}`}>
                        {children}
                    </div>
                </div>
            )
        }

        return <div><></></div>;
    }
    else if (
        url === "/" && _url === "/"
    ) {
        if (!isSignedin) {
            return (
                <div>
                    <div className={`landing ${themeMode ? `!bg-[#000000]` : `!bg-[#eee]`}`}>
                        {children}
                    </div>
                </div>
            )
        }

        dispatch(settingURL("/tasks"));
        navigate("/tasks");
    }
    /* FIX to show these pages */


    return (
        <ThemeProvider theme={theme}>
            <>
                {
                    (
                        twohalfSecsAfter
                    ) && (
                        <div>
                            <div className={`${themeMode ? `!bg-[#000000]` : `!bg-[#eee]`}`}>
                                <Splashscreen
                                />
                            </div>
                        </div>
                    )
                }

                {
                    (
                        !twohalfSecsAfter
                    ) && (
                        <>
                            {
                                !isSignedin ? (
                                    <>
                                        {
                                            (_url !== "/register" && _url !== "/register/") ? (
                                                <div>
                                                    <Landing
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className={`landing ${themeMode ? `!bg-[#000000]` : `!bg-[#eee]`}`}>
                                                        {children}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </>
                                ) : (
                                    <div>
                                        <div className={`layout ${themeMode ? `!bg-[#000000]` : `!bg-[#eee]`}`}>
                                            <div className="layout_left">

                                                <div className="mt-[0.485rem]">
                                                    <Drawer
                                                    />
                                                </div>

                                            </div>

                                            <div className="layout_right">

                                                <div className="mt-[2.5rem]">
                                                    <Header
                                                    />
                                                </div>

                                                <div className="mt-[0.45rem]">
                                                    {
                                                        children
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </>
                    )
                }
            </>
        </ThemeProvider>
    )
}

export default Layout;