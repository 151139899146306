import React from 'react';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
//CONTEXT-API
import { useStateValue } from '../../../../../StateContext';
import { settingGRADESYSTEM } from '../../../../../contextapi';
//SCSS
import './index.scss';


interface Dialog_GradeSystemProps {
    open   : boolean;
    setOpen: Function;
    heading: string;
    options: string[];
}

function Dialog_GradeSystem({
    open,
    setOpen,
    heading,
    options
}: Dialog_GradeSystemProps) {
    const [ { gradesystem, user }, dispatch ] = useStateValue();


    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            className="dialog !w-[26rem]"
            style={{ margin: "0 auto" }}
        >
            <FormControl
                className="pt-5 pb-5 pl-3.5"
                style={{ margin: "0 auto" }}
            >
                <FormLabel className={`!text-2xl !pt-4 !pb-3 ${user?.dark?`!text-[#eee]`:`!text-[#000]`}`}> {heading} </FormLabel>
                <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={gradesystem}
                    onChange={(e) => dispatch(settingGRADESYSTEM(e.target.value))}
                    className="pb-4"
                >
                    {
                        options.map((text: string) => (
                            <FormControlLabel
                                control={<Radio/>}
                                value={text}
                                label={<p className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}>{text}</p>}
                                id={text}
                                key={text}
                            />    
                        ))
                    }
                </RadioGroup>
            </FormControl>
        </Dialog>
    )
}

export default Dialog_GradeSystem;