import React from 'react';
//REACT-CALENDAR
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
//SCSS
import './index.scss';


interface DatePickerProp {
    date: Date;
    setDate: Function;
}

function DatePicker({
    date,
    setDate
}: DatePickerProp) {
    return (
        <Calendar
            value={date}
            onChange={(e) => setDate(e)}
            minDetail="month"
            defaultView="month"     /* to diable month button */
        />
    )
}

export default DatePicker;