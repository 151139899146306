import React from 'react';
//MATERIAL-UI
import Button from '@material-ui/core/Button';
import SkipNextIcon from '@material-ui/icons/SkipNext';


function SkipbreakButton(props) {
    return (
        <Button {...props} variant="contained" style={{ width: "10.95rem", height: "3rem", margin: "0 auto", display: "flex", justifyContent: "space-around", backgroundColor: "#26cf80", color: "white", borderRadius: "30px" }}>
            <SkipNextIcon/>
            <span style={{ fontWeight: "bold", textTransform: "none", fontSize: "1rem" }}> Skip Break </span>
        </Button>
    )
}

export default SkipbreakButton;