import React, { useContext, useState, useEffect, useRef } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import PlayButton from './PlayButton';
import PauseButton from './PauseButton';
import SkipbreakButton from './SkipbreakButton';
import SettingsButton from './SettingsButton';
import SettingsContext from './SettingsContext';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
//CONTEXT-API
import { useStateValue } from '../../../StateContext';


//FOR MUI DIALOG WIDTH/HEIGHT
const useStyles = makeStyles({
  paper: {
    minWidth: "67.9vh",
    maxWidth: "67.9vh",
  }
});


const red = '#f54e4e';
const green = '#26cf80';

function Timer() {
  const classes = useStyles();
  const [{ user }, dispatch] = useStateValue();
  const [open1, setOpen1] = useState<boolean>(false);
  const [open2, setOpen2] = useState<boolean>(false);
  const [open3, setOpen3] = useState<boolean>(false);
  const [isPaused, setIsPaused] = useState<boolean>(true);
  const [isBreak, setIsBreak] = useState<boolean>(false);


  const settingsInfo = useContext(SettingsContext);


  const [mode, setMode] = useState('work'); // work/break/null
  const [secondsLeft, setSecondsLeft] = useState(0);

  const secondsLeftRef = useRef(secondsLeft);
  const isPausedRef = useRef(isPaused);
  const modeRef = useRef(mode);

  function tick() {
    secondsLeftRef.current--;
    setSecondsLeft(secondsLeftRef.current);
  }


  useEffect(() => {
    function switchMode() {
      const nextMode = modeRef.current === 'work' ? 'break' : 'work';
      const nextSeconds = (nextMode === 'work' ? settingsInfo.workMinutes : settingsInfo.breakMinutes) * 60;

      setMode(nextMode);
      modeRef.current = nextMode;

      setSecondsLeft(nextSeconds);
      secondsLeftRef.current = nextSeconds;
    }

    secondsLeftRef.current = settingsInfo.workMinutes * 60;
    setSecondsLeft(secondsLeftRef.current);

    const interval = setInterval(() => {
      if (isPausedRef.current) {
        return;
      }
      if (secondsLeftRef.current === 0) {
        return switchMode();
      }

      tick();
    }, 1000);

    return () => clearInterval(interval);
  }, [settingsInfo]);


  var totalSeconds = mode === 'work' ? settingsInfo.workMinutes * 60 : settingsInfo.breakMinutes * 60;
  var percentage = Math.round(secondsLeft / totalSeconds * 100);
  var minutes = Math.floor(secondsLeft / 60);
  var seconds = secondsLeft % 60;
  if (seconds < 10) seconds = '0' + seconds;


  return (
    <>
      <div>
        <div style={{ marginTop: '-6.25rem', marginLeft: '41.3rem' }}>
          <SettingsButton onClick={() => settingsInfo.setShowSettings(true)} />
        </div>
        <CircularProgressbar
          value={percentage}
          text={minutes + ':' + seconds}
          styles={buildStyles({
            textColor: user?.dark ? 'white' : 'black',
            pathColor: !isBreak ? (mode === 'work' ? red : green) : green,
            tailColor: 'rgba(255,255,255,.2)',
          })} />
        <div style={{ marginTop: '5.35rem' }}>
          {isPaused
            ? <PlayButton onClick={() => { setIsPaused(false); isPausedRef.current = false; }} />
            : <>
              {
                !isBreak
                  ? <PauseButton onClick={() => setOpen2(true)} />
                  : <SkipbreakButton onClick={() => setOpen3(true)} />
              }

              <div style={{ marginTop: '1.05rem' }}>
                <Button onClick={() => setOpen1(true)} disableRipple style={{ width: '12.25rem', height: '3rem', margin: '0 auto', display: 'flex', justifyContent: 'space-around', backgroundColor: 'transparent', color: !isBreak ? '#f50057' : '#26cf80' }}>
                  <span style={{ fontWeight: 'bold', textTransform: 'none', fontSize: '1rem' }}> End Focus Session </span>
                </Button>
              </div>
            </>
          }
        </div>
      </div>


      <Dialog
        open={open1}
        onClose={() => setOpen1(false)}
        className="dialog"
        classes={{ paper: classes.paper }}
      >
        <div className="mt-4">
          <p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`} text-center text-md`}> Are you sure you want to end this focus session?</p>

          <div className="flex mt-4">
            <div className="flex-1" />
            <button onClick={() => setOpen1(false)} className="bg-transparent hover:bg-[#e9e7e7] text-[#2962ff] font-bold p-2 rounded-lg">No</button>
            <button onClick={() => { setOpen1(false); setIsPaused(true); settingsInfo.setWorkMinutes(user?.pomodorotimer.workminutes); setSecondsLeft(settingsInfo.workMinutes * 60); isPausedRef.current = true; /*extra for second time onwards*/ setIsBreak(false); setCurrenSecond(0); setPreviousSecond(59); secondsLeftRef.current = user?.pomodorotimer.workminutes * 60 }} className="bg-[#2962ff] hover:bg-[#1c44b2] text-white font-bold p-2 rounded-lg ml-2">Yes</button>
          </div>
        </div>
      </Dialog>


      <Dialog
        open={open2}
        onClose={() => setOpen2(false)}
        className="dialog"
        classes={{ paper: classes.paper }}
      >
        <div className="mt-4">
          <p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`} text-center text-md`}> Are you sure you want to finish this round earlier? </p>

          <div className="flex mt-4">
            <div className="flex-1" />
            <button onClick={() => setOpen2(false)} className="bg-transparent hover:bg-[#e9e7e7] text-[#2962ff] font-bold p-2 rounded-lg">No</button>
            <button onClick={() => { setOpen2(false); setIsBreak(true); settingsInfo.setWorkMinutes(user?.pomodorotimer.breakminutes); }} className="bg-[#2962ff] hover:bg-[#1c44b2] text-white font-bold p-2 rounded-lg ml-2">Yes</button>
          </div>
        </div>
      </Dialog>


      <Dialog
        open={open3}
        onClose={() => setOpen3(false)}
        className="dialog"
        classes={{ paper: classes.paper }}
      >
        <div className="mt-4">
          <p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`} text-center text-md`}> Are you sure you want to finish this break earlier? </p>

          <div className="flex mt-4">
            <div className="flex-1" />
            <button onClick={() => setOpen3(false)} className="bg-transparent hover:bg-[#e9e7e7] text-[#2962ff] font-bold p-2 rounded-lg">No</button>
            <button onClick={() => { setOpen3(false); setIsBreak(false); settingsInfo.setWorkMinutes(user?.pomodorotimer.workminutes); }} className="bg-[#2962ff] hover:bg-[#1c44b2] text-white font-bold p-2 rounded-lg ml-2">Yes</button>
          </div>
        </div>
      </Dialog>
    </>
  );
}

export default Timer;