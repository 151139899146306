import React from 'react';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
import Switch from '@material-ui/core/Switch';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
//CONTEXT-API
import { useStateValue } from '../../../../../../StateContext';
//APPSYNC-MUTATION
import { handleEditUser } from '../../../../../../appsync_functions/mutations';
//SCSS
import './index.scss';


//FOR MUI DIALOG WIDTH/HEIGHT
const useStyles = makeStyles({
    paper: {
        minWidth: "42.9vh",
        maxWidth: "42.9vh",
        minHeight: "27.9vh",
        maxHeight: "27.9vh",
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
});


interface Dialog_PageSettingProps {
    open: boolean;
    setOpen: Function;
}

function Dialog_PageSetting({
    open,
    setOpen,
}: Dialog_PageSettingProps) {
    const classes = useStyles();
    const [{ user, refetchLoadingAppSync }, dispatch] = useStateValue();


    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                className="dialog"
            >
                <div className="dialog_pagesetting">
                    {/* <div className="dialog_pagesetting_box">
                        <div className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}> Home </div>
                        <Switch
                            color="primary"
                            size="medium"
                            checked={user?.pageSettings.home}
                            onClick={() => {
                                //editing on DB by AppSync
                                handleEditUser({ ...user, pageSettings: { ...user?.pageSettings, home: !user?.pageSettings.home } });
                            }}
                        />
                    </div> */}


                    <div className="dialog_pagesetting_box">
                        <div className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Events </div>
                        <Switch
                            color="primary"
                            size="medium"
                            checked={user?.pageSettings.events}
                            onClick={() => {
                                //editing on DB by AppSync
                                handleEditUser({ ...user, pageSettings: { ...user?.pageSettings, events: !user?.pageSettings.events } });
                            }}
                        />
                    </div>


                    <div className="dialog_pagesetting_box">
                        <div className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Homework </div>
                        <Switch
                            color="primary"
                            size="medium"
                            checked={user?.pageSettings.homework}
                            onClick={() => {
                                //editing on DB by AppSync
                                handleEditUser({ ...user, pageSettings: { ...user?.pageSettings, homework: !user?.pageSettings.homework } });
                            }}
                        />
                    </div>


                    {/* <div className="dialog_pagesetting_box">
                        <div className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}> Tasks </div>
                        <Switch
                            color="primary"
                            size="medium"
                            checked={user?.pageSettings.tasks}
                            onClick={() => {
                                //editing on DB by AppSync
                                handleEditUser({ ...user, pageSettings: { ...user?.pageSettings, tasks: !user?.pageSettings.tasks } });
                            }}
                        />
                    </div> */}


                    {/* <div className="dialog_pagesetting_box">
                        <div className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}> Notes </div>
                        <Switch
                            color="primary"
                            size="medium"
                            checked={user?.pageSettings.notes}
                            onClick={() => {
                                //editing on DB by AppSync
                                handleEditUser({ ...user, pageSettings: { ...user?.pageSettings, notes: !user?.pageSettings.notes } });
                            }}
                        />
                    </div> */}


                    <div className="dialog_pagesetting_box">
                        <div className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Absences </div>
                        <Switch
                            color="primary"
                            size="medium"
                            checked={user?.pageSettings.absences}
                            onClick={() => {
                                //editing on DB by AppSync
                                handleEditUser({ ...user, pageSettings: { ...user?.pageSettings, absences: !user?.pageSettings.absences } });
                            }}
                        />
                    </div>


                    <div className="dialog_pagesetting_box">
                        <div className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Teachers </div>
                        <Switch
                            color="primary"
                            size="medium"
                            checked={user?.pageSettings.teachers}
                            onClick={() => {
                                //editing on DB by AppSync
                                handleEditUser({ ...user, pageSettings: { ...user?.pageSettings, teachers: !user?.pageSettings.teachers } });
                            }}
                        />
                    </div>


                    <div className="dialog_pagesetting_box">
                        <div className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Subjects </div>
                        <Switch
                            color="primary"
                            size="medium"
                            checked={user?.pageSettings.subjects}
                            onClick={() => {
                                //editing on DB by AppSync
                                handleEditUser({ ...user, pageSettings: { ...user?.pageSettings, subjects: !user?.pageSettings.subjects } });
                            }}
                        />
                    </div>


                    <div className="dialog_pagesetting_box">
                        <div className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Schedule </div>
                        <Switch
                            color="primary"
                            size="medium"
                            checked={user?.pageSettings.schedule}
                            onClick={() => {
                                //editing on DB by AppSync
                                handleEditUser({ ...user, pageSettings: { ...user?.pageSettings, schedule: !user?.pageSettings.schedule } });
                            }}
                        />
                    </div>


                    <div className="dialog_pagesetting_box">
                        <div className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Timetable </div>
                        <Switch
                            color="primary"
                            size="medium"
                            checked={user?.pageSettings.timetable}
                            onClick={() => {
                                //editing on DB by AppSync
                                handleEditUser({ ...user, pageSettings: { ...user?.pageSettings, timetable: !user?.pageSettings.timetable } });
                            }}
                        />
                    </div>


                    <div className="dialog_pagesetting_box">
                        <div className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Grades </div>
                        <Switch
                            color="primary"
                            size="medium"
                            checked={user?.pageSettings.grades}
                            onClick={() => {
                                //editing on DB by AppSync
                                handleEditUser({ ...user, pageSettings: { ...user?.pageSettings, grades: !user?.pageSettings.grades } });
                            }}
                        />
                    </div>
                </div>
            </Dialog>


            <Dialog
                open={refetchLoadingAppSync}
                className="dialog"
                classes={{ paper: classes.paper }}
            >
                <CircularProgress size={40} />
            </Dialog>
        </>
    )
}

export default Dialog_PageSetting;