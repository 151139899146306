exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-absences-tsx": () => import("./../../../src/pages/absences.tsx" /* webpackChunkName: "component---src-pages-absences-tsx" */),
  "component---src-pages-events-tsx": () => import("./../../../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-forgot-password-tsx": () => import("./../../../src/pages/forgotPassword.tsx" /* webpackChunkName: "component---src-pages-forgot-password-tsx" */),
  "component---src-pages-grades-tsx": () => import("./../../../src/pages/grades.tsx" /* webpackChunkName: "component---src-pages-grades-tsx" */),
  "component---src-pages-homework-tsx": () => import("./../../../src/pages/homework.tsx" /* webpackChunkName: "component---src-pages-homework-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-notes-tsx": () => import("./../../../src/pages/notes.tsx" /* webpackChunkName: "component---src-pages-notes-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-schedule-tsx": () => import("./../../../src/pages/schedule.tsx" /* webpackChunkName: "component---src-pages-schedule-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-subjects-tsx": () => import("./../../../src/pages/subjects.tsx" /* webpackChunkName: "component---src-pages-subjects-tsx" */),
  "component---src-pages-tasks-tsx": () => import("./../../../src/pages/tasks.tsx" /* webpackChunkName: "component---src-pages-tasks-tsx" */),
  "component---src-pages-teachers-tsx": () => import("./../../../src/pages/teachers.tsx" /* webpackChunkName: "component---src-pages-teachers-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-timetable-tsx": () => import("./../../../src/pages/timetable.tsx" /* webpackChunkName: "component---src-pages-timetable-tsx" */)
}

