import React, { useState, useEffect } from 'react';
import moment from 'moment';
//COMPONENTS
import Calendar from '../utils/Calendar';
import PomodoroTimer from '../utils/PomodoroTimer';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import CircleCheckedFilledIcon from '@material-ui/icons/CheckCircle';
import CircleUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
//MATERIAL-UI ( Pagination )
import TablePagination from '@material-ui/core/TablePagination';
//CONTEXT-API
import { useStateValue } from '../../StateContext';
import { settingDISPLAY_POMODROTIMER } from '../../contextapi';
let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
//APPSYNC-MUTATION
import { handleEditUser } from '../../appsync_functions/mutations';
//EXPORT FUNCTION
import { handleOpened } from '../utils/Header/Dialog/_Forms/Homework';
//SCSS
import './index.scss';


function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}


function formatDate(t) {
    function format(m) {
        let f = new Intl.DateTimeFormat('en', m);
        return f.format(t);
    }
    return [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }].map(format).join('-');
}


function convertDateToYYYYMMDD(date) {
    var updatedDate = new Date(date);
    updatedDate.setDate(updatedDate.getDate() + 1);
    var mnth = ("0" + (updatedDate.getMonth() + 1)).slice(-2);
    var day = ("0" + updatedDate.getDate()).slice(-2);
    return [updatedDate.getFullYear(), mnth, day].join("-");
}


function getDayFromDate(date) {
    return ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()];
}


function getDateNumberFromDate(date) {
    return date.getDate().toString();
}


//FOR MUI DIALOG WIDTH/HEIGHT
const useStyles = makeStyles({
    paper: {
        minWidth: '63vh',
        maxWidth: '63vh',
        boxShadow: 'none',
        backgroundColor: 'transparent'
    }
});


function Homework() {
    const classes = useStyles();


    const [{ user, displayPomodoroTimer }, dispatch] = useStateValue();


    const [date, setDate] = useState<Date>(new Date());
    const [open, setOpen] = useState<boolean>(false);


    const [selectedCoursel, setSelectedCoursel] = useState<string | null>(null);


    var homework = [];
    var coursel = [];
    var manageCategories_Homework = [];
    homework = user?.homework; coursel = [...user?.manageCategories_Homework]; manageCategories_Homework = user?.manageCategories_Homework;


    useEffect(() => {
        setSelectedCoursel(coursel[0]);
    }, [user]);


    //*********************PAGINATION******************//
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(4);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    //*********************PAGINATION******************//


    const modifiedData = (
        homework
            .filter((item) => {

                if (item.repeat === "Once") {
                    if (
                        formatDate(date) ===
                        formatDate(new Date(item.date))
                    ) {
                        return item;
                    }
                }
                else if (item.repeat === "Daily") {
                    if (
                        item.datesBwDate1NDate2.find((item) => item === convertDateToYYYYMMDD(date))
                    ) {
                        return item;
                    }
                }
                else if (item.repeat === "Weekly") {
                    if (
                        item.datesBwDate1NDate2.find((item) => item === convertDateToYYYYMMDD(date))
                        && item.selectedDays_WEEKLY.find((item_) => item_ === getDayFromDate(date))
                    ) {
                        return item;
                    }
                }
                else if (item.repeat === "Monthly") {
                    if (
                        item.datesBwDate1NDate2.find((item) => item === convertDateToYYYYMMDD(date))
                        && item.selectedDays_MONTHLY.find((item_) => item_ === getDateNumberFromDate(date))
                    ) {
                        return item;
                    }
                }

            })
    );


    return (
        <>
            {
                displayPomodoroTimer ? (
                    <div className="mt-[-3.375rem]">
                        <PomodoroTimer
                        />
                    </div>
                ) : (
                    <div className="!mt-[-4.875rem] !w-[91.5%]">
                        <div className="flex items-center">
                            <Typography variant="h4" className={`!pl-[7.25rem] !pr-1.5 !pb-3.5 tracking-normal ${user?.dark ? `!text-[#eee]` : `!text-[#000]`}`}> {months[date.getMonth()]}, {date.getFullYear()} </Typography>

                            <IconButton onClick={() => setOpen(true)} className="h-[40%] w-[2.25%] !mt-[-0.855rem]" size="small">
                                <KeyboardArrowDownIcon fontSize="small" style={{ fill: user?.dark ? "#eee" : "#000" }} />
                            </IconButton>

                            <Dialog
                                open={open}
                                onClose={() => setOpen(false)}
                                className="dialog"
                                classes={{ paper: classes.paper }}
                            >
                                <Calendar
                                    date={date}
                                    setDate={setDate}
                                />
                            </Dialog>
                        </div>


                        {/* --- */}


                        <div className="-mt-2.5">
                            {
                                user &&
                                (homework).length !== 0 && (
                                    <div className="mt-[1.55rem] flex flex-col justify-center h-[80.25vh]">
                                        <List className="flex-1">
                                            {
                                                modifiedData
                                                    .filter((item) => {
                                                        if (selectedCoursel === "All") {
                                                            return item;
                                                        }
                                                        else if (selectedCoursel === item.category) {
                                                            return item;
                                                        }
                                                        else if (selectedCoursel !== item.category) {
                                                            return null;
                                                        }
                                                    })
                                                    .sort((a, b) => new Date(b.date) - new Date(a.date))
                                                    .sort((a, b) => new Date(a.time) - new Date(b.time))
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((item, i) => {
                                                        const checked = (
                                                            item.checked.find((item) => moment(item.date).add(1, 'days').utc().format('YYYY-MM-DD') === convertDateToYYYYMMDD(date))
                                                        )?.bool;


                                                        return (
                                                            <ListItem
                                                                key={i}
                                                                style={{ margin: "0 auto" }}
                                                                className={`!mt-3.5 !pt-0 !pb-1.5 !pl-6 !w-[87.45%] !ml-28 !rounded-2xl ${user?.dark ? `!bg-[#eee]` : `!bg-[#FFFFFF]`} flex`}
                                                                onClick={() => handleOpened(item, date)}
                                                            >
                                                                <div className="flex-1 flex items-center">
                                                                    <ListItemIcon>
                                                                        <Checkbox
                                                                            edge="start"
                                                                            checked={checked}
                                                                            onClick={(e: any) => {
                                                                                if (!e) var e: any = window.event;
                                                                                e.cancelBubble = true;
                                                                                if (e.stopPropagation) e.stopPropagation();

                                                                                //editing on DB by AppSync
                                                                                handleEditUser({ ...user, homework: user?.homework.map((item_) => { if (item_.id === item.id) { return { ...item_, checked: item_.checked.map((item__) => { if (moment(item__.date).add(1, 'days').utc().format('YYYY-MM-DD') === convertDateToYYYYMMDD(date)) { return { ...item__, bool: !item__.bool } } else { return item__ } }) }; } else { return item_ } }) });
                                                                            }}
                                                                            tabIndex={-1}
                                                                            disableRipple
                                                                            style={{ color: '#242d31' }}
                                                                            icon={<CircleUncheckedIcon />}
                                                                            checkedIcon={<CircleCheckedFilledIcon />}
                                                                        />
                                                                    </ListItemIcon>

                                                                    <div className="flex flex-col">
                                                                        <div className={`text-xl mt-1.5 ${checked ? `text-gray-500` : `text-black`}`}>
                                                                            {checked ? <s>{item.subject}</s> : item.subject}
                                                                        </div>
                                                                        <div className={`text-md mt-2.5 ${checked ? `text-gray-500` : `text-black`} ${!item.note && `invisible`}`}>
                                                                            {item.note ? item.note : "-"}
                                                                        </div>
                                                                        <div className={`text-sm ${checked ? `text-gray-500` : `text-black`}`}>
                                                                            {formatAMPM(new Date(item.time))}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <IconButton
                                                                    onClick={(e) => {
                                                                        if (!e) var e = window.event;
                                                                        e.cancelBubble = true;
                                                                        if (e.stopPropagation) e.stopPropagation();
                                                                        dispatch(settingDISPLAY_POMODROTIMER(true));
                                                                    }}
                                                                    style={{ margin: "0 auto", width: "0.95rem", height: "0.35rem", backgroundColor: "gray", color: "white" }}
                                                                >
                                                                    <AccessTimeIcon />
                                                                </IconButton>
                                                            </ListItem>
                                                        )
                                                    })
                                            }
                                        </List>


                                        {
                                            modifiedData
                                                .filter((item) => {
                                                    if (selectedCoursel === "All") {
                                                        return item;
                                                    }
                                                    else if (selectedCoursel === item.category) {
                                                        return item;
                                                    }
                                                    else if (selectedCoursel !== item.category) {
                                                        return null;
                                                    }
                                                })
                                                .length !== 0
                                            && (
                                                <TablePagination
                                                    component="div"
                                                    count={
                                                        modifiedData
                                                            .filter((item) => {
                                                                if (selectedCoursel === "All") {
                                                                    return item;
                                                                }
                                                                else if (selectedCoursel === item.category) {
                                                                    return item;
                                                                }
                                                                else if (selectedCoursel !== item.category) {
                                                                    return null;
                                                                }
                                                            })
                                                            .length
                                                    }
                                                    page={page}
                                                    onPageChange={handleChangePage}
                                                    rowsPerPageOptions={[3]}
                                                    rowsPerPage={rowsPerPage}
                                                    onRowsPerPageChange={handleChangeRowsPerPage}
                                                />
                                            )
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Homework;