import React, { createContext, useContext, useReducer } from 'react';
import { initialState, reducer } from './contextapi';


const StateContext = createContext<any[]>([]);


/*https://medium.com/@akrush95/global-cached-state-in-react-using-hooks-context-and-local-storage-166eacf8ab46*/
const localState =
typeof window!=='undefined'
?JSON.parse(localStorage.getItem("state"))
:'';


interface StateProviderProps {
    children: JSX.Element | JSX.Element[];
}

export function StateProvider({ children }: StateProviderProps) {
    const [ state, dispatch ] = useReducer<any>(reducer, localState||initialState);

    React.useEffect(() => {
        localStorage.setItem("state", JSON.stringify(state));
    }, [state]);

    return (
        <StateContext.Provider
            value={[ state, dispatch ]}
        >
            { children }
        </StateContext.Provider>
    )
}


export const useStateValue = () => useContext<any[]>(StateContext);