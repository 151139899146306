import React from 'react';
import icon from '../../images/icon.png';
//CONTEXT-API
import { useStateValue } from '../../StateContext';


function Splashscreen() {
    const [{ user }, dispatch] = useStateValue();


    return (
        <div className="!w-full !h-[100vh] flex justify-center items-center flex-col">
            <figure className="!w-[12rem] !h-[11rem]" style={{ margin: "0 auto" }}>
                <img src={icon} alt="logo" />
            </figure>

            <p className={`!text-3xl ${user?.dark ? `!text-white` : `!text-black`} !mt-2 !mb-0.5 !tracking-wide !font-bold`} style={{ wordSpacing: "0.0815rem" }}> Tructivity </p>

            <div className="snippet !h-[0] !bg-transparent !shadow-none">
                <div className="stage !w-[120px] !h-[0px] !ml-[0.55rem]">
                    <div className="dot-flashing"></div>
                </div>
            </div>
        </div>
    )
}

export default Splashscreen;