//initialState
export const initialState: {
    isConfirmedSignup     : boolean;
    isSignedin            : boolean;
    user                  : object;
    signedupFirstname     : string;
    signedupLastname      : string;
    signedupEmail         : string;
    signedupPassword      : string;
    url                   : string;
    gradesystem           : string;
    refetchLoadingAppSync : boolean;
    twohalfSecsAfter      : boolean;
    //EXTRA
    displayPomodoroTimer  : boolean;
} = {
    isConfirmedSignup     : false,
    isSignedin            : false,
    user /*BUILD BUG FIX*/: {
        dark      : false,
        tasks     : true,
        events    : false,
        homework  : false,
        absences  : false,
        teachers  : false,
        subjects  : false,
        schedule  : false,
        timetable : false,
        grades    : false
    },
    signedupFirstname     : "",
    signedupLastname      : "",
    signedupEmail         : "",
    signedupPassword      : "",
    url                   : "/",
    gradesystem           : "Percentage Based Grade",
    refetchLoadingAppSync : false,
    twohalfSecsAfter      : true,
    //EXTRA
    displayPomodoroTimer  : false
}




//actionTypes
const SET_ISCONFIRMEDSIGNUP         : string = "SET_ISCONFIRMEDSIGNUP";
const SET_ISSIGNEDIN                : string = "SET_ISSIGNEDIN";
const SET_USER                      : string = "SET_USER";
const SET_SIGNEDUPFIRSTNAME         : string = "SET_SIGNEDUPFIRSTNAME";
const SET_SIGNEDUPLASTNAME          : string = "SET_SIGNEDUPLASTNAME";
const SET_SIGNEDUPEMAIL             : string = "SET_SIGNEDUPEMAIL";
const SET_SIGNEDUPPASSWORD          : string = "SET_SIGNEDUPPASSWORD";
const SET_URL                       : string = "SET_URL";
const SET_GRADESYSTEM               : string = "SET_GRADESYSTEM";
const SET_REFETCHLOADINGAPPSYNC     : string = "SET_REFETCHLOADINGAPPSYNC";
const SET_TWOHALFSECONDSAFTERSIGNIN : string = "SET_TWOHALFSECONDSAFTERSIGNIN";
//EXTRA
const SET_DISPLAY_POMODROTIMER      : string = "SET_DISPLAY_POMODROTIMER";




//actions
export const settingISCONFIRMEDSIGNUP = (bool: boolean) => {
    return {
        type: SET_ISCONFIRMEDSIGNUP,
        payload: bool
    }
}
export const settingISSIGNEDIN = (bool: boolean) => {
    return {
        type: SET_ISSIGNEDIN,
        payload: bool
    }
}
export const settingUSER = (user: null | { id: string; firstname: string; lastname: string; email: string; password: string; }) => {
    return {
        type: SET_USER,
        payload: user
    }
}
export const settingSIGNEDUPFIRSTNAME = (firstname: string) => {
    return {
        type: SET_SIGNEDUPFIRSTNAME,
        payload: firstname
    }
}
export const settingSIGNEDUPLASTNAME = (lastname: string) => {
    return {
        type: SET_SIGNEDUPLASTNAME,
        payload: lastname
    }
}
export const settingSIGNEDUPEMAIL = (email: string) => {
    return {
        type: SET_SIGNEDUPEMAIL,
        payload: email
    }
}
export const settingSIGNEDUPPASSWORD = (password: string) => {
    return {
        type: SET_SIGNEDUPPASSWORD,
        payload: password
    }
}
export const settingURL = (url: string) => {
    return {
        type: SET_URL,
        payload: url
    }
}
export const settingGRADESYSTEM = (gradesystem: string) => {
    return {
        type: SET_GRADESYSTEM,
        payload: gradesystem
    }
}
export const settingREFETCHLOADINGAPPSYNC = (bool: boolean) => {
    return {
        type: SET_REFETCHLOADINGAPPSYNC,
        payload: bool
    }
}
export const settingTWOHALFSECONDSAFTERSIGNIN = (bool: boolean) => {
    return {
        type: SET_TWOHALFSECONDSAFTERSIGNIN,
        payload: bool
    }
}
//EXTRA
export const settingDISPLAY_POMODROTIMER = (bool: boolean) => {
    return {
        type: SET_DISPLAY_POMODROTIMER,
        payload: bool
    }
}




//reducer
/*https://medium.com/@akrush95/global-cached-state-in-react-using-hooks-context-and-local-storage-166eacf8ab46*/
export function reducer(state = initialState, action: any) {
    if(state === null) {
        localStorage.removeItem("state");
        return initialState;
    }


    switch(action.type) {
        case SET_ISCONFIRMEDSIGNUP:
            return {
                ...state,
                isConfirmedSignup: action.payload
            }

        case SET_ISSIGNEDIN:
            return {
                ...state,
                isSignedin: action.payload
            }

        case SET_USER:
            return {
                ...state,
                user: action.payload
            }

        case SET_SIGNEDUPFIRSTNAME:
            return {
                ...state,
                signedupFirstname: action.payload
            }

        case SET_SIGNEDUPLASTNAME:
            return {
                ...state,
                signedupLastname: action.payload
            }
    
        case SET_SIGNEDUPEMAIL:
            return {
                ...state,
                signedupEmail: action.payload
            }

        case SET_SIGNEDUPPASSWORD:
            return {
                ...state,
                signedupPassword: action.payload
            }

        case SET_URL:
            return {
                ...state,
                url: action.payload
            }

        case SET_GRADESYSTEM:
            return {
                ...state,
                gradesystem: action.payload
            }
    
        case SET_REFETCHLOADINGAPPSYNC:
            return {
                ...state,
                refetchLoadingAppSync: action.payload
            }

        case SET_TWOHALFSECONDSAFTERSIGNIN:
            return {
                ...state,
                twohalfSecsAfter: action.payload
            }

        //EXTRA
        case SET_DISPLAY_POMODROTIMER:
            return {
                ...state,
                displayPomodoroTimer: action.payload
            }

        default:
            return state;
    }
}