import React, { useState } from 'react';
import convertDateToSlashStyle from './convertDateToSlashStyle';
//COMPONENTS
import Dialog_PageSetting from './General/Dialog_PageSetting';
import Dialog_SelectSemester from './General/Dialog_SelectSemester';
import Account from './Account';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Switch from '@material-ui/core/Switch';
import SchoolIcon from '@material-ui/icons/School';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
//BYTES
import bytes from 'bytes';
//CONTEXT-API
import { useStateValue } from '../../../../StateContext';
//APPSYNC-MUTATION
import { handleEditUser } from '../../../../appsync_functions/mutations';
//SCSS
import './index.scss';


//FOR MUI DIALOG WIDTH/HEIGHT
const useStyles = makeStyles({
    paper: {
        minWidth: "56.75rem",
        minHeight: "36.5rem"
    },
    paper_: {
        minWidth: "42.9vh",
        maxWidth: "42.9vh",
        minHeight: "27.9vh",
        maxHeight: "27.9vh",
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
});


interface Dialog_SettingsProps {
    open: boolean;
    setOpen: Function;
}

function Dialog_Settings({
    open,
    setOpen
}: Dialog_SettingsProps) {
    const classes = useStyles();


    const [ { user, refetchLoadingAppSync }, dispatch ] = useStateValue();
    const [ selected, setSelected ] = useState<string>("General");


    const [ openDialog_Option_PageSetting, setOpenDialog_Option_PageSetting ] = useState<boolean>(false);
    const [ openDialog_Option_SelectSemester, setOpenDialog_Option_SelectSemester ] = useState<boolean>(false);


    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                className="dialog"
                classes={{ paper: classes.paper }}
            >
                <div className="settings">
    
                    <div className="settings_left">
    
                        <List className="drawer">
                            <Link
                                component="button"
                                className={`drawer_link ${user?.dark?`bg-a`:`bg-b`}`}
                            >
                                <ListItem
                                    className="drawer_link_option"
                                    selected={selected === 'General'}
                                    onClick={() => setSelected('General')}
                                >
                                    <ListItemIcon className={`drawer_link_option_logo
                                        ${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}
                                    `}>
                                        <SettingsIcon/>
                                    </ListItemIcon>
    
                                    <ListItemText className={`drawer_link_option_text
                                        ${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}
                                    `}>
                                        <span>General</span>
                                    </ListItemText>
                                </ListItem>
                            </Link>

                            <Link
                                component="button"
                                className={`drawer_link ${user?.dark?`bg-a`:`bg-b`}`}
                            >
                                <ListItem
                                    className="drawer_link_option"
                                    selected={selected === 'Account'}
                                    onClick={() => setSelected('Account')}
                                >
                                    <ListItemIcon className={`drawer_link_option_logo
                                        ${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}
                                    `}>
                                        <AccountCircleIcon/>
                                    </ListItemIcon>
    
                                    <ListItemText className={`drawer_link_option_text
                                        ${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}
                                    `}>
                                        <span>Account</span>
                                    </ListItemText>
                                </ListItem>
                            </Link>
                        </List>
                    </div>
    
                    <div className="settings_right">
    
                        {
                            selected==='Account'&&(
                                <Account
                                    setOpenDialog={setOpen}
                                />
                            )
                        }
    
                        {
                            selected==='General'&&(
                                <div className="mt-5">
                                    <div className="!w-[96.5%]" style={{ margin: "0 auto" }}>
                                        <Divider/>
                                    </div>
                                
                                    <div className="settings_right_box">
                                        <div className="settings_right_box_1">
                                            <div className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}> Dard Mode </div>
                                            <Switch
                                                color="primary"
                                                size="medium"
                                                checked={user?.dark}
                                                onClick={() => {
                                                    //editing on DB by AppSync
                                                    handleEditUser({ ...user, dark: !user?.dark });
                                                }}
                                            />
                                        </div>
        
                                        <div className="settings_right_box_2">
                                            <div className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}> Page Settings </div>
                                            <IconButton
                                                onClick={() => setOpenDialog_Option_PageSetting(true)}
                                                className="w-12 h-12 pl-2 mt-1.5"
                                            >
                                                <SettingsApplicationsIcon
                                                    fontSize="large"
                                                    className="!cursor-pointer !ml-1.5"
                                                    style={{fill: user?.dark?`#bbc0c4`:`#455a64`}}
                                                />
                                            </IconButton>
                                        </div>

                                        <div className="settings_right_box_2">
                                            <div className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}> Select Semester </div>
                                            <IconButton
                                                onClick={() => setOpenDialog_Option_SelectSemester(true)}
                                                className="w-12 h-12 pl-2 mt-3"
                                            >
                                                <SchoolIcon
                                                    fontSize="large"
                                                    className="!cursor-pointer !ml-1.5"
                                                    style={{fill: user?.dark?`#bbc0c4`:`#455a64`}}
                                                />
                                            </IconButton>
                                        </div>
                                    </div>

                                    <div className="!w-[96.5%]" style={{ margin: "0 auto" }}>
                                        <Divider/>
                                    </div>

                                    <div className="settings_right_box mt-[-1.285rem]">
                                        <div className="settings_right_box_3 !hidden">
                                            <div className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}> Bandwidth (MB) : </div>
                                            <div> {user?.bandwidth} / 5120 </div>
                                        </div>
        
                                        <div className="settings_right_box_3 !hidden">
                                            <div className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}> Storage (MB) : </div>
                                            <div> {user?.storage} / 5120 </div>
                                        </div>
        
                                        <div className="settings_right_box_3">
                                            <div className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}> Registration Date : </div>
                                            <div> {convertDateToSlashStyle(user?.regestrationDate)} </div>
                                        </div>

                                        {/* <div className="settings_right_box_3">
                                            <div className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}> Ending Date : </div>
                                            <div> {convertDateToSlashStyle(user?.endingDate)} </div>
                                        </div> */}
                                    </div>
                                </div>
                            )
                        }
                    </div>
    
                </div>
            </Dialog>


            <Dialog_PageSetting
                open={openDialog_Option_PageSetting}
                setOpen={setOpenDialog_Option_PageSetting}
            />


            <Dialog_SelectSemester
                open={openDialog_Option_SelectSemester}
                setOpen={setOpenDialog_Option_SelectSemester}
            />


            <Dialog
                open={refetchLoadingAppSync}
                className="dialog"
                classes={{ paper: classes.paper_ }}
            >
                <CircularProgress size={40}/>
            </Dialog>
        </>
    )
}

export default Dialog_Settings;