import React, { useState } from 'react';
//COMPONENTS
import Dialog_Settings from './Dialog_Settings';
//MATERIAL-UI
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import WorkIcon from '@material-ui/icons/Work';
import InsertInvitationIcon from '@material-ui/icons/InsertInvitation';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import EventNoteIcon from '@material-ui/icons/EventNote';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import PersonIcon from '@material-ui/icons/Person';
import BookIcon from '@material-ui/icons/Book';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TableChartIcon from '@material-ui/icons/TableChart';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import SettingsIcon from '@material-ui/icons/Settings';
//GATSBYJS
import { navigate } from 'gatsby';
//CONTEXT-API
import { useStateValue } from '../../../StateContext';
import { settingURL } from '../../../contextapi';
//SCSS
import './index.scss';


function Drawer() {
    const [ { url, user }, dispatch ] = useStateValue();


    const [ openDialog, setOpenDialog ] = useState<boolean>(false);


    const options = [
        {
            id     : 1,
            icon   : <AssignmentTurnedInIcon/>,
            name   : 'Tasks',
            url    : '/tasks',
            display: user?.pageSettings?.tasks
        },
        {
            id     : 2,
            icon   : <InsertInvitationIcon/>,
            name   : 'Events',
            url    : '/events',
            display: user?.pageSettings?.events
        },
        {
            id     : 3,
            icon   : <WorkIcon/>,
            name   : 'Homework',
            url    : '/homework',
            display: user?.pageSettings?.homework
        },
        // {
        //     id     : 4,
        //     icon   : <EventNoteIcon/>,
        //     name   : 'Notes',
        //     url    : '/notes',
        //     display: user?.pageSettings?.notes
        // },
        {
            id     : 5,
            icon   : <PersonAddDisabledIcon/>,
            name   : 'Absences',
            url    : '/absences',
            display: user?.pageSettings?.absences
        },
        {
            id     : 6,
            icon   : <PersonIcon/>,
            name   : 'Teachers',
            url    : '/teachers',
            display: user?.pageSettings?.teachers
        },
        {
            id     : 7,
            icon   : <BookIcon/>,
            name   : 'Subjects',
            url    : '/subjects',
            display: user?.pageSettings?.subjects
        },
        {
            id     : 8,
            icon   : <AccessTimeIcon/>,
            name   : 'Schedule',
            url    : '/schedule',
            display: user?.pageSettings?.schedule
        },
        {
            id     : 9,
            icon   : <TableChartIcon/>,
            name   : 'Timetable',
            url    : '/timetable',
            display: user?.pageSettings?.timetable
        },
        {
            id     : 10,
            icon   : <StarBorderIcon/>,
            name   : 'Grades',
            url    : '/grades',
            display: user?.pageSettings?.grades
        },
        {
            id     : 11,
            icon   : <SettingsIcon/>,
            name   : 'Settings',
            url    : '/settings',
            display: true
        }
    ];


    return (
        <>
            <List className="drawer !mt-[-2rem]">
                {
                    options.map((item, i) => (
                        <Link
                            key={i}
                            component="button"
                            className={`drawer_link ${!item.display&&`!hidden`}
                                ${(item.name==="Settings"&&url==="/settings")
                                ?(user?.dark?`bg-3`:`bg-4`)
                                :(user?.dark?`bg-1`:`bg-2`)}`
                            }
                            onClick={() => {
                                /*for 'settings' will show popup not a page*/
                                (item.name==="Settings")?
                                setOpenDialog(true):
                                navigate(item.url);
                            }}
                        >
                            <ListItem
                                className="drawer_link_option"
                                selected={url===item.url}
                                onClick={() => {
                                    (item.url!=="/settings")&&dispatch(settingURL(item.url));
                                }}
                            >
                                <ListItemIcon className={`drawer_link_option_logo
                                    ${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}
                                `}>
                                    {item.icon}
                                </ListItemIcon>
        
                                <ListItemText className={`drawer_link_option_text
                                    ${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}
                                `}>
                                    <span>{item.name}</span>
                                </ListItemText>
                            </ListItem>
                        </Link>
                    ))
                }
            </List>

            
            <Dialog_Settings
                open={openDialog}
                setOpen={setOpenDialog}
            />            
        </>
    )
}

export default Drawer;