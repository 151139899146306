//AWS-AMPLIFY
import { API } from 'aws-amplify';
import {
    allUsers, getUserById
} from '../../graphql/queries';





export const fetchAllUsers = async() => {
    const { data } = await API.graphql({ query: allUsers });
    return data.allUsers;
}
export const fetchGetUserById = async(id: string) => {
    const { data } = await API.graphql({
        query: getUserById,
        variables: { id }
    });
    return data.getUserById;
}