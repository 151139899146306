import React, { useState } from 'react';
//COMPONENTS
import Dialog_Of_ThreePages from './Dialog_Of_ThreePages';
import Dialog_GradeSystem from './Dialog_GradeSystem';
import Dialog_ClearAll from './Dialog_ClearAll';
import Dialog_AbsenceDetails from './Dialog_AbsenceDetails';
//MATERAIL-UI
import Menu from '@mui/material/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';
//CONTEXT-API
import { useStateValue } from '../../../../StateContext';
//APPSYNC-MUTATION
import { handleEditUser } from '../../../../appsync_functions/mutations';


//FOR MUI PAPER
const useStyles = makeStyles({
    menu: {
        "& .MuiPaper-root": {
            backgroundColor: "#455a64"
        }
    }
});


function MenuThreedots() {
    const classes = useStyles();
    const [{ url, user, displayPomodoroTimer }, dispatch] = useStateValue();


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);


    const [openDialog_Of_ThreePages, setOpenDialog_Of_ThreePages] = useState<boolean>(false);


    const [openDialog_Option_GradeSystem, setOpenDialog_Option_GradeSystem] = useState<boolean>(false);
    const [openDialog_Option_ClearAll, setOpenDialog_Option_ClearAll] = useState<boolean>(false);
    const [openDialog_Option_AbsenceDetails, setOpenDialog_Option_AbsenceDetails] = useState<boolean>(false);


    let message = (
        url === "/absences" && "Are you sure you want to delete all absences in this semester?"
        || url === "/teachers" && "Are you sure you want to delete all of these contacts?"
        || url === "/subjects" && "Are you sure you want to delete all subjects for this semester?"
        || url === "/schedule" && "Are you sure you want to delete all of these subjects?"
        || url === "/timetable" && "Are you sure you want to delete all of these subjects?"
        || url === "/grades" && "Are you sure you want to delete all grades for this semester?"
    )


    return (
        <>
            <>
                <IconButton
                    aria-expanded={open ? 'true' : undefined}
                    className={`
                        ${
                        // (url==='/'||url==='/home'||url==='/notes'||url==='/settings')
                        // &&`invisible`
                        (url === '/' || url === '/notes' || url === '/settings')
                        && `invisible`
                        }
                        ${
                        /*only for homework page ( displayPomodoroTimer ) */
                        (displayPomodoroTimer && url === '/homework')
                        && `invisible`
                        }
                    `}
                    onClick={(e) => {
                        (url === "/homework" || url === "/events" || url === "/tasks")
                            ? setOpenDialog_Of_ThreePages(true)
                            : handleClick(e);
                    }}
                >
                    <MoreVertIcon
                        style={{ fill: user?.dark ? `#bbc0c4` : `#455a64` }}
                    />
                </IconButton>


                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    style={{ marginTop: "1.25rem" }}
                    className={user?.dark && classes.menu} /*for dark mode*/
                >
                    {//just ignoring 'homework', 'event', 'task' for now
                        !(url === "/homework"
                            || url === "/events"
                            || url === "/tasks"
                        ) && (
                            <MenuItem
                                className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}
                                onClick={() => { handleClose(); setOpenDialog_Option_ClearAll(true); }}
                            >
                                Clear All
                            </MenuItem>
                        )
                    }
                    {
                        (url === "/grades"
                        ) && (
                            <MenuItem
                                className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}
                                onClick={() => { handleClose(); setOpenDialog_Option_GradeSystem(true); }}
                            >
                                Grade System
                            </MenuItem>
                        )
                    }
                    {
                        (url === "/absences"
                        ) && (
                            <MenuItem
                                className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}
                                onClick={() => { handleClose(); setOpenDialog_Option_AbsenceDetails(true); }}
                            >
                                Absence Details
                            </MenuItem>
                        )
                    }
                </Menu>
            </>


            {
                url === "/homework" && (
                    <Dialog_Of_ThreePages
                        open={openDialog_Of_ThreePages}
                        setOpen={setOpenDialog_Of_ThreePages}
                        message="Are you sure you want to delete this category?"
                        data={user?.manageCategories_Homework}
                        funcDelete={(thatItem) => {
                            //editing on DB by AppSync
                            handleEditUser({ ...user, manageCategories_Homework: user?.manageCategories_Homework.filter((item) => { return item != thatItem; }) });
                        }}
                        funcAddition={(thatCategory) => {
                            //editing on DB by AppSync
                            handleEditUser({ ...user, manageCategories_Homework: [...user?.manageCategories_Homework, thatCategory] });
                        }}
                    />
                )
            }
            {
                url === "/events" && (
                    <Dialog_Of_ThreePages
                        open={openDialog_Of_ThreePages}
                        setOpen={setOpenDialog_Of_ThreePages}
                        message="Are you sure you want to delete this category?"
                        data={user?.manageCategories_Event}
                        funcDelete={(thatItem) => {
                            //editing on DB by AppSync
                            handleEditUser({ ...user, manageCategories_Event: user?.manageCategories_Event.filter((item) => { return item != thatItem; }) });
                        }}
                        funcAddition={(thatCategory) => {
                            //editing on DB by AppSync
                            handleEditUser({ ...user, manageCategories_Event: [...user?.manageCategories_Event, thatCategory] });
                        }}
                    />
                )
            }
            {
                url === "/tasks" && (
                    <Dialog_Of_ThreePages
                        open={openDialog_Of_ThreePages}
                        setOpen={setOpenDialog_Of_ThreePages}
                        message="Are you sure you want to delete this category?"
                        data={user?.manageCategories_Task}
                        funcDelete={(thatItem) => {
                            //editing on DB by AppSync
                            handleEditUser({ ...user, manageCategories_Task: user?.manageCategories_Task.filter((item) => { return item != thatItem; }) });
                        }}
                        funcAddition={(thatCategory) => {
                            //editing on DB by AppSync
                            handleEditUser({ ...user, manageCategories_Task: [...user?.manageCategories_Task, thatCategory] });
                        }}
                    />
                )
            }


            <Dialog_GradeSystem
                open={openDialog_Option_GradeSystem}
                setOpen={setOpenDialog_Option_GradeSystem}
                heading="Grade System"
                options={[
                    'Percentage Based Grade',
                    'Point Based Grade',
                    'Letter Based Grade',
                    'College GPA',
                    'High School GPA',
                    'High School GPA Percentage'
                ]}
            />


            <Dialog_ClearAll
                open={openDialog_Option_ClearAll}
                setOpen={setOpenDialog_Option_ClearAll}
                message={message}
            />


            <Dialog_AbsenceDetails
                open={openDialog_Option_AbsenceDetails}
                setOpen={setOpenDialog_Option_AbsenceDetails}
            />
        </>
    )
}

export default MenuThreedots;