import React from 'react';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
//CONTEXT-API
import { useStateValue } from '../../../../../StateContext';


//FOR MUI DIALOG WIDTH/HEIGHT
const useStyles = makeStyles({
    paper: {
        minWidth: "40.75rem",
        minHeight: "34.75rem"
    }
});


interface Dialog_AbsenceDetailsProps {
    open   : boolean;
    setOpen: Function;
}

function Dialog_AbsenceDetails({
    open,
    setOpen
}: Dialog_AbsenceDetailsProps) {
    const classes = useStyles();
    const [ { user }, dispatch ] = useStateValue();


     var data;
     if(user&&(user?.semester === 1)) { data = user?.semester_1?.absence; }
else if(user&&(user?.semester === 2)) { data = user?.semester_2?.absence; }


    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            className="dialog"
            classes={{ paper: classes.paper }}
        >
            <table>
                <tr className="flex items-center w-full">
                    <td className={`${`${user?.dark?`!text-white`:`!text-black`}`} p-4 !w-[50.5%] text-3xl !mt-[-0.25rem] text-center ml-[-0.025rem]`}> Subject  </td>
                    <td className={`${`${user?.dark?`!text-white`:`!text-black`}`} p-4 !w-[49.5%] text-3xl !mt-[-0.25rem] text-center`}> Absences </td>
                </tr>

                <Divider className="mt-2.5"/>

                {
                    (
                        /*how to get unique array of objects in javascript*/
                        [...new Set(data?.map(item => item.subject))]
                    ).map((item, i) => (
                        <span key={i}>
                                <ListItem
                                    style={{ margin: "0 auto" }}
                                    className={`!pt-2 !pb-3.5 !w-[100%] !rounded-sm !bg-transparent !cursor-pointer hover:!bg-[#eee] ${user?.dark?`!text-white hover:!text-black`:`!text-black hover:!text-black`}`}
                                >
                                    <div className="flex items-center w-full">
                                        <p className="!w-[49%] p-2.5 text-lg mt-1.5 text-center ml-1"> {item} </p>
                                        <p className="!w-[51%] p-2.5 text-lg mt-1.5 text-center ml-6"> {
                                            data.filter((obj) => obj.subject == item).length
                                        } </p>
                                    </div>
                                </ListItem>
                            <Divider/>
                        </span>
                    ))
                }
            </table>            
        </Dialog>
    )
}

export default Dialog_AbsenceDetails;