import React, { useState } from 'react';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
//CONTEXT-API
import { useStateValue } from '../../../../../../StateContext';
import { settingURL, settingISSIGNEDIN, settingUSER } from '../../../../../../contextapi';
//FORMIK & YUP
import { Formik, ErrorMessage } from 'formik';
const yup = require('yup');
//SWEETALERT2
import { errorAlert, successAlert, toastAlert } from '../../../../../../alerts';
//AWS-AMPLIFY
import { Auth } from 'aws-amplify';
//APPSYNC-MUTATION
import { handleEditUser } from '../../../../../../appsync_functions/mutations';
//STYLE
import './index.scss';
//GATSBY
import { navigate } from 'gatsby';


//FOR MUI DIALOG WIDTH/HEIGHT
const useStyles = makeStyles({
    paper: {
        minWidth: "42.9vh",
        maxWidth: "42.9vh",
        minHeight: "27.9vh",
        maxHeight: "27.9vh",
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
});


interface Dialog_ChangeEmailProps {
    open: boolean;
    setOpen: Function;
    setOpenDialog: Function;
}

function Dialog_ChangeEmail({
    open,
    setOpen,
    setOpenDialog
}: Dialog_ChangeEmailProps) {
    const classes = useStyles();
    const [{ user, refetchLoadingAppSync }, dispatch] = useStateValue();


    const [matched, setMatched] = useState<boolean>(false);


    const [confirmcodeDialog, setConfirmcodeDialog] = useState<boolean>(false);
    const [newEmail, setNewEmail] = useState<string>("");


    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                className="dialog"
            >
                <div className="dialog_changeemail flex flex-col">

                    <div className="flex flex-col w-80 pt-3 pb-3" style={{ margin: "0 auto" }}>
                        <p className={`text-sm font-sans font-bold ${`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}`}> Email </p>
                        <TextField
                            hiddenLabel
                            value={user?.email}
                            variant="filled"
                            size="small"
                            spellCheck={false}
                        />
                    </div>

                    <Formik
                        initialValues={{
                            email: ''
                        }}
                        validationSchema={
                            yup.object({
                                email: yup.string()
                                    .email('Must be a valid email')
                                    .max(30, 'Must be atmost 30 characters')
                                    .min(5, 'Must be atleast 5 characters')
                                    .required('Must fill email')
                            })
                        }
                        onSubmit={(async (values) => {
                            if (values.email === user?.email) {
                                setMatched(true);
                                return null;
                            }


                            try {
                                const _user = await Auth.currentAuthenticatedUser();

                                /* send the code to the email */
                                await Auth.updateUserAttributes(
                                    _user,
                                    { email: values.email }
                                );
                                console.log('sended verification code');

                                setConfirmcodeDialog(true);
                                setNewEmail(values.email);
                            }
                            catch (error) {
                                console.log('error verification code', error);
                                errorAlert('Verification Code', error.message);
                            }
                        })}
                    >
                        {({ values, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <div className="flex flex-col w-80 pt-3 pb-3" style={{ margin: "0 auto" }}>
                                    <p className={`text-sm font-sans font-bold ${`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}`}> New Email </p>
                                    <TextField
                                        hiddenLabel
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        variant="filled"
                                        size="small"
                                        spellCheck={false}
                                    />
                                    <ErrorMessage name='email' component="p" className="err" />
                                    {matched && <p className="err"> Please enter different email </p>}
                                </div>

                                <div className="dialog_changeemail_btns pb-3">
                                    <div className="dialog_changeemail_btns_flexy" />
                                    <button
                                        className="dialog_changeemail_btns_btn p-1.5 w-32 text-sm rounded-lg mt-1.5"
                                        type="submit"
                                    > Change Email </button>
                                </div>
                            </form>
                        )}
                    </Formik>

                </div>
            </Dialog>


            <Dialog
                open={confirmcodeDialog}
                onClose={() => setConfirmcodeDialog(false)}
                className="dialog"
            >
                <Formik
                    initialValues={{
                        confimrationcode: ''
                    }}
                    validationSchema={
                        yup.object({
                            confimrationcode: yup.string()
                                .max(6, 'Must be at most 6 characters')
                                .min(6, 'Must be at least 6 characters')
                                .required('Must fill Confirmation Code')
                        })
                    }
                    onSubmit={(values, { setSubmitting }) => {
                        setSubmitting(true);
                        toastAlert('Submitting..', 'top-end', 'info');


                        setTimeout(async () => {
                            try {
                                /* confirm the new email with the provided code */
                                await Auth.verifyCurrentUserAttributeSubmit('email', values.confimrationcode);
                                console.log('successfully confimration code');

                                const fire = async () => {
                                    successAlert("successfully! changed Email");
                                    console.log('successfully change email');

                                    /* using here due to ContextApi disptach accessable only in react components */
                                    await Auth.signOut();
                                    console.log('successfully signedout');

                                    dispatch(settingURL("/login"));
                                    navigate("/login");

                                    dispatch(settingISSIGNEDIN(false));
                                }

                                fire().then(() => {
                                    /* updating email */
                                    handleEditUser({ ...user, email: newEmail });
                                    dispatch(settingUSER(null));
                                })

                                setOpen(false);
                                setConfirmcodeDialog(false);
                            }
                            catch (error) {
                                console.log('error change email', error);
                                setConfirmcodeDialog(false);
                                setOpenDialog(false);
                                errorAlert('Change Email', error.message);
                            }

                            setSubmitting(false);
                        }, 1500);
                    }}
                >
                    {({ values, handleChange, handleSubmit, isSubmitting, touched }) => (
                        <Box component="form" onSubmit={handleSubmit}>
                            <Typography className={`${user && user?.dark ? `!text-white` : `!text-black`} !text-2xl !font-semibold !mt-2.5`}>
                                <span className="!ml-[5.085rem]">Confirm Signup</span>
                            </Typography>

                            <div className="flex items-center justify-center flex-col h-[17.45rem] -mt-8">
                                <div className="flex-1 mt-4">
                                    <Grid container spacing={2} className="!mt-14">
                                        <Grid item className="w-[25rem] ml-1">
                                            <input
                                                className="mt-[-0.325rem] w-full rounded-lg pt-3 pb-3 pl-3.5 outline-none border-2 border-gray-300 text-black"
                                                name="confimrationcode"
                                                value={values.confimrationcode}
                                                onChange={handleChange}
                                                placeholder="Confirmation Code"
                                                autoComplete="off"
                                                disabled={isSubmitting}
                                            />
                                            <ErrorMessage name='confimrationcode' component="p" className="err" />
                                        </Grid>
                                    </Grid>

                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        disabled={isSubmitting}
                                        className="!bg-blue-600 !text-white !h-[2.335rem] !mt-6"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Submit {isSubmitting && <CircularProgress style={{ color: 'white', marginTop: "0.1rem", marginLeft: "0.4rem" }} size={15} />}
                                    </Button>
                                </div>

                                <Grid container justifyContent="flex-end">
                                    <Grid item>
                                        <button
                                            type="button"
                                            className={`!cursor-pointer !no-underline !text-sm !w-[100%] !mb-3.5 !mr-3 ${user && user?.dark ? `!text-white` : `!text-blue-700`}`}
                                            onClick={async () => {
                                                try {
                                                    const _user = await Auth.currentAuthenticatedUser();

                                                    /* send the code to the email */
                                                    await Auth.updateUserAttributes(
                                                        _user,
                                                        { email: newEmail }
                                                    );
                                                    console.log('sended verification code');
                                                }
                                                catch (error) {
                                                    console.log('error resend', error);
                                                    errorAlert('Resend', error.message);
                                                }
                                            }}
                                        >
                                            {"Resend code now!"}
                                        </button>
                                    </Grid>
                                </Grid>
                            </div>
                        </Box>
                    )}
                </Formik>
            </Dialog>


            <Dialog
                open={refetchLoadingAppSync}
                className="dialog"
                classes={{ paper: classes.paper }}
            >
                <CircularProgress size={40} />
            </Dialog>
        </>
    )
}

export default Dialog_ChangeEmail;