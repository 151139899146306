import React, { useState, useEffect } from 'react';
//MATERIAL-UI
import Typography from '@material-ui/core/Typography';
import TextField from '@mui/material/TextField';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';
//MATERIAL-UI ( DATE PICKER )
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
//MATERIAL-UI ( Autocomplete )
import MUI_Autocomplete from '../../../MUI_Autocomplete';
//CONTEXT-API
import { useStateValue } from '../../../../../StateContext';
//APPSYNC-MUTATIONS
import { handleEditUser } from '../../../../../appsync_functions/mutations';
//UNIQUE-ID
import uniqid from 'uniqid';
//SCSS
import './index.scss';


export let handleOpened: Function;


//FOR MUI INPUT BORDER-RADUIS
const useStyles = makeStyles({
    root: {
        [`& fieldset`]: {
            borderRadius: 16.5
        }
    }
});


//FOR MUI SELECT BOX
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
}


interface GradeProps {
    open: boolean;
    setOpen: Function;
}

function Grade({
    open,
    setOpen,
}: GradeProps) {
    const classes = useStyles();
    const [{ gradesystem, user }, dispatch] = useStateValue();


    const [subject, setSubject] = useState<string>("");
    const [weight, setWeight] = useState<number | undefined>();
    const [grade, setGrade] = useState<string>("");
    const [note, setNote] = useState<string>("");
    const [maxpoints, setMaxpoints] = useState<number | undefined>();
    const [credit, setCredit] = useState<number>(0);
    const [course, setCourse] = useState<string>("Regular");
    const [gradeSystem, setGradeSystem] = useState<string>("");


    const [date, setDate] = useState<Date>(new Date());
    const handleDate = (date: Date) => setDate(date);


    useEffect(() => {
        setGradeSystem(gradesystem);
    }, [gradesystem]);


    const [openCreated, setOpenCreated] = useState<boolean>(false);
    const [openCreatedId, setOpenCreatedId] = useState<string | null>(null);
    handleOpened = (item: any) => {
        setOpen(true);
        setOpenCreated(true);
        setOpenCreatedId(item.id);

        /*setting*/
        setSubject(item.subject);
        setWeight(item?.weight);
        setGrade(item.grade);
        setNote(item.note);
        setDate(item.date);
        setMaxpoints(item?.maxpoints);
        setCredit(item?.credit);
        setCourse(item?.course);
        setGradeSystem(item.gradeSystem);
    }


    const [openDialog_Message, setOpenDialog_Message] = useState<boolean>(false);


    const [clicked, setClicked] = useState<boolean>(false);


    const clearAllStateProperties = () => {
        setTimeout(() => {
            /*for reset when dialog closed the popup*/
            /*resetting*/
            setSubject("");
            setWeight(undefined);
            setGrade("");
            setNote("");
            setDate(new Date());
            setMaxpoints(undefined);
            setCredit(0);
            setCourse("Regular");
            /*logical*/
            setOpenCreated(false);
            /*just for fix display*/
            setTimeout(() => setGradeSystem(gradesystem), 150);
            /*for reset when dialog closed the popup*/
        }, 200);
    }


    var subject_ = [];
    if (user && (user?.semester === 1)) { subject_ = user?.semester_1?.subject; }
    else if (user && (user?.semester === 2)) { subject_ = user?.semester_2?.subject; }


    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                    /*FIX*/
                    clearAllStateProperties();
                }}
                className="dialog"
            >
                <DialogContent>
                    <form
                        className="form"
                        autoComplete="off"
                        onSubmit={(e) => {
                            e.preventDefault();
                            setClicked(true);


                            if (
                                subject !== ""
                            ) {
                                if (!openCreated) {   /*for not recreate already created one*/
                                    if (gradeSystem === 'Percentage Based Grade') {

                                        if (user?.semester === 1) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_1: { ...user?.semester_1, grade: [...user?.semester_1.grade, { id: uniqid(), subject, grade, note, date, gradeSystem, /*optional*/ weight }] } });
                                        }
                                        else if (user?.semester === 2) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_2: { ...user?.semester_2, grade: [...user?.semester_2.grade, { id: uniqid(), subject, grade, note, date, gradeSystem, /*optional*/ weight }] } });
                                        }

                                    }
                                    else if (gradeSystem === 'Point Based Grade') {

                                        if (user?.semester === 1) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_1: { ...user?.semester_1, grade: [...user?.semester_1.grade, { id: uniqid(), subject, grade, note, date, gradeSystem, /*optional*/ maxpoints }] } });
                                        }
                                        else if (user?.semester === 2) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_2: { ...user?.semester_2, grade: [...user?.semester_2.grade, { id: uniqid(), subject, grade, note, date, gradeSystem, /*optional*/ maxpoints }] } });
                                        }

                                    }
                                    else if (gradeSystem === 'Letter Based Grade') {

                                        if (user?.semester === 1) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_1: { ...user?.semester_1, grade: [...user?.semester_1.grade, { id: uniqid(), subject, grade, note, date, gradeSystem, /*optional*/ weight }] } });
                                        }
                                        else if (user?.semester === 2) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_2: { ...user?.semester_2, grade: [...user?.semester_2.grade, { id: uniqid(), subject, grade, note, date, gradeSystem, /*optional*/ weight }] } });
                                        }

                                    }
                                    else if (gradeSystem === 'College GPA') {

                                        if (user?.semester === 1) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_1: { ...user?.semester_1, grade: [...user?.semester_1.grade, { id: uniqid(), subject, grade, note, date, gradeSystem, /*optional*/ credit }] } });
                                        }
                                        else if (user?.semester === 2) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_2: { ...user?.semester_2, grade: [...user?.semester_2.grade, { id: uniqid(), subject, grade, note, date, gradeSystem, /*optional*/ credit }] } });
                                        }

                                    }
                                    else if (gradeSystem === 'High School GPA') {

                                        if (user?.semester === 1) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_1: { ...user?.semester_1, grade: [...user?.semester_1.grade, { id: uniqid(), subject, grade, note, date, gradeSystem, /*optional*/ credit, course }] } });
                                        }
                                        else if (user?.semester === 2) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_2: { ...user?.semester_2, grade: [...user?.semester_2.grade, { id: uniqid(), subject, grade, note, date, gradeSystem, /*optional*/ credit, course }] } });
                                        }

                                    }
                                    else if (gradeSystem === 'High School GPA Percentage') {

                                        if (user?.semester === 1) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_1: { ...user?.semester_1, grade: [...user?.semester_1.grade, { id: uniqid(), subject, grade, note, date, gradeSystem, /*optional*/ credit, course }] } });
                                        }
                                        else if (user?.semester === 2) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_2: { ...user?.semester_2, grade: [...user?.semester_2.grade, { id: uniqid(), subject, grade, note, date, gradeSystem, /*optional*/ credit, course }] } });
                                        }

                                    }
                                }

                                else {               /*for update already created one*/
                                    if (gradeSystem === 'Percentage Based Grade') {

                                        if (user?.semester === 1) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_1: { ...user?.semester_1, grade: user?.semester_1.grade.map((item) => { if (item.id === openCreatedId) { return { id: item.id, subject, grade, note, date, gradeSystem, /*optional*/ weight }; } else { return item } }) } });
                                        }
                                        else if (user?.semester === 2) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_2: { ...user?.semester_2, grade: user?.semester_2.grade.map((item) => { if (item.id === openCreatedId) { return { id: item.id, subject, grade, note, date, gradeSystem, /*optional*/ weight }; } else { return item } }) } });
                                        }

                                    }
                                    else if (gradeSystem === 'Point Based Grade') {

                                        if (user?.semester === 1) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_1: { ...user?.semester_1, grade: user?.semester_1.grade.map((item) => { if (item.id === openCreatedId) { return { id: item.id, subject, grade, note, date, gradeSystem, /*optional*/ maxpoints }; } else { return item } }) } });
                                        }
                                        else if (user?.semester === 2) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_2: { ...user?.semester_2, grade: user?.semester_2.grade.map((item) => { if (item.id === openCreatedId) { return { id: item.id, subject, grade, note, date, gradeSystem, /*optional*/ maxpoints }; } else { return item } }) } });
                                        }

                                    }
                                    else if (gradeSystem === 'Letter Based Grade') {

                                        if (user?.semester === 1) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_1: { ...user?.semester_1, grade: user?.semester_1.grade.map((item) => { if (item.id === openCreatedId) { return { id: item.id, subject, grade, note, date, gradeSystem, /*optional*/ weight }; } else { return item } }) } });
                                        }
                                        else if (user?.semester === 2) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_2: { ...user?.semester_2, grade: user?.semester_2.grade.map((item) => { if (item.id === openCreatedId) { return { id: item.id, subject, grade, note, date, gradeSystem, /*optional*/ weight }; } else { return item } }) } });
                                        }

                                    }
                                    else if (gradeSystem === 'College GPA') {

                                        if (user?.semester === 1) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_1: { ...user?.semester_1, grade: user?.semester_1.grade.map((item) => { if (item.id === openCreatedId) { return { id: item.id, subject, grade, note, date, gradeSystem, /*optional*/ credit }; } else { return item } }) } });
                                        }
                                        else if (user?.semester === 2) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_2: { ...user?.semester_2, grade: user?.semester_2.grade.map((item) => { if (item.id === openCreatedId) { return { id: item.id, subject, grade, note, date, gradeSystem, /*optional*/ credit }; } else { return item } }) } });
                                        }

                                    }
                                    else if (gradeSystem === 'High School GPA') {

                                        if (user?.semester === 1) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_1: { ...user?.semester_1, grade: user?.semester_1.grade.map((item) => { if (item.id === openCreatedId) { return { id: item.id, subject, grade, note, date, gradeSystem, /*optional*/ credit, course }; } else { return item } }) } });
                                        }
                                        else if (user?.semester === 2) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_2: { ...user?.semester_2, grade: user?.semester_2.grade.map((item) => { if (item.id === openCreatedId) { return { id: item.id, subject, grade, note, date, gradeSystem, /*optional*/ credit, course }; } else { return item } }) } });
                                        }

                                    }
                                    else if (gradeSystem === 'High School GPA Percentage') {

                                        if (user?.semester === 1) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_1: { ...user?.semester_1, grade: user?.semester_1.grade.map((item) => { if (item.id === openCreatedId) { return { id: item.id, subject, grade, note, date, gradeSystem, /*optional*/ credit, course }; } else { return item } }) } });
                                        }
                                        else if (user?.semester === 2) {
                                            //editing on DB by AppSync
                                            handleEditUser({ ...user, semester_2: { ...user?.semester_2, grade: user?.semester_2.grade.map((item) => { if (item.id === openCreatedId) { return { id: item.id, subject, grade, note, date, gradeSystem, /*optional*/ credit, course }; } else { return item } }) } });
                                        }

                                    }

                                    setOpenCreatedId(null);
                                }


                                setOpen(false);
                                setClicked(false);
                            }


                            /*FIX*/
                            clearAllStateProperties();
                        }}
                    >
                        <Typography variant="h4" className={`${user?.dark ? `!text-[#eee]` : `!text-[#000]`}`}> {gradeSystem} </Typography>

                        {
                            subject_ && (
                                <MUI_Autocomplete
                                    alloptions={subject_}
                                    value={subject}
                                    setValue={setSubject}
                                    label="Subject"
                                />
                            )
                        }

                        {subject === "" && clicked && <p className="err"> Required Field </p>}

                        {
                            (gradeSystem === "Letter Based Grade"
                                || gradeSystem === "College GPA"
                                || gradeSystem === "High School GPA") ? (
                                <div>
                                    <FormControl className={classes.root}>
                                        <InputLabel className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Grade </InputLabel>
                                        <Select
                                            value={grade}
                                            onChange={(e: any) => setGrade(e.target.value)}
                                            input={<OutlinedInput label="Grade" />}   /* space */
                                            MenuProps={MenuProps}
                                            className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}
                                        >
                                            <MenuItem value="A+"> A+ </MenuItem>
                                            <MenuItem value="A">  A  </MenuItem>
                                            <MenuItem value="A-"> A- </MenuItem>
                                            <MenuItem value="B+"> B+ </MenuItem>
                                            <MenuItem value="B">  B  </MenuItem>
                                            <MenuItem value="B-"> B- </MenuItem>
                                            <MenuItem value="C+"> C+ </MenuItem>
                                            <MenuItem value="C">  C  </MenuItem>
                                            <MenuItem value="C-"> C- </MenuItem>
                                            <MenuItem value="D+"> D+ </MenuItem>
                                            <MenuItem value="D">  D  </MenuItem>
                                            <MenuItem value="D-"> D- </MenuItem>
                                            <MenuItem value="F">  F  </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            ) : (
                                <>
                                    <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} name='grade' value={grade} onChange={(e) => setGrade(e.target.value)} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Grade</p>} variant="outlined" /> </div>
                                    {grade === "" && clicked && <p className="err"> Required Field </p>}
                                </>
                            )
                        }

                        {
                            (gradeSystem === "Percentage Based Grade"
                                || gradeSystem === "Letter Based Grade") && (
                                <>
                                    <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} /*floatinput*/ type="number" inputProps={{ step: "any", maxLength: 13 }}/*floatinput*/ className={classes.root} name='weight' value={weight} onChange={(e) => setWeight(parseFloat(e.target.value))} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Weight</p>} variant="outlined" /> </div>
                                    {weight === undefined && clicked && <p className="err"> Required Field </p>}
                                </>
                            )
                        }

                        {
                            gradeSystem === "Point Based Grade" && (
                                <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} /*floatinput*/ type="number" inputProps={{ step: "any", maxLength: 13 }}/*floatinput*/ className={classes.root} value={maxpoints} onChange={(e) => setMaxpoints(Number(e.target.value))} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Max Points</p>} variant="outlined" /> </div>
                            )
                        }

                        {
                            (gradeSystem === "College GPA"
                                || gradeSystem === "High School GPA"
                                || gradeSystem === "High School GPA Percentage") && (
                                <div>
                                    <FormControl className={classes.root}>
                                        <InputLabel className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Credit </InputLabel>
                                        <Select
                                            value={credit}
                                            onChange={(e: any) => setCredit(parseFloat(e.target.value))}
                                            input={<OutlinedInput label="Credit" />}   /* space */
                                            MenuProps={MenuProps}
                                            type="number"
                                            className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}
                                        >
                                            <MenuItem value={0}>   0   </MenuItem>
                                            <MenuItem value={1}>   1   </MenuItem>
                                            <MenuItem value={1.5}> 1.5 </MenuItem>
                                            <MenuItem value={2}>   2   </MenuItem>
                                            <MenuItem value={2.5}> 2.5 </MenuItem>
                                            <MenuItem value={3}>   3   </MenuItem>
                                            <MenuItem value={3.5}> 3.5 </MenuItem>
                                            <MenuItem value={4}>   4   </MenuItem>
                                            <MenuItem value={4.5}> 4.5 </MenuItem>
                                            <MenuItem value={5}>   5   </MenuItem>
                                            <MenuItem value={5.5}> 5.5 </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            )
                        }

                        {
                            (gradeSystem === "High School GPA"
                                || gradeSystem === "High School GPA Percentage") && (
                                <div>
                                    <FormControl className={classes.root}>
                                        <InputLabel className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Course </InputLabel>
                                        <Select
                                            value={course}
                                            onChange={(e: any) => setCourse(e.target.value)}
                                            input={<OutlinedInput label="Course" />}   /* space */
                                            MenuProps={MenuProps}
                                            className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}
                                        >
                                            <MenuItem value="Regular"> Regular </MenuItem>
                                            <MenuItem value="Honor">   Honor   </MenuItem>
                                            <MenuItem value="AP">      AP      </MenuItem>
                                            <MenuItem value="IB">      IB      </MenuItem>
                                            <MenuItem value="College"> College </MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                            )
                        }

                        <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} value={note} onChange={(e) => setNote(e.target.value)} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Note</p>} variant="outlined" /> </div>

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <MobileDatePicker
                                value={date}
                                onChange={handleDate}
                                label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Date</p>}
                                inputFormat="MMMM DD, YYYY"
                                renderInput={(params) => <TextField {...params} className={classes.root} sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} />}
                            />
                        </LocalizationProvider>

                        <div className="form_btns">
                            <div className="form_btns_flexy" />
                            <Button
                                className="form_btns_cancelBtn"
                                disableRipple
                                onClick={() => {
                                    setOpen(false);

                                    if (
                                        subject !== ""
                                    ) {
                                        setOpenDialog_Message(true);
                                    }
                                }}
                            > Delete </Button>
                            <Button className="form_btns_addBtn" type="submit"> Save </Button>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>


            <Dialog
                open={openDialog_Message}
                onClose={() => {
                    setOpenDialog_Message(false);
                    /*FIX*/
                    clearAllStateProperties();
                }}
                className="dialog"
            >
                <div className="dialog_selectsemester_msg pt-6 pl-6 pr-6 pb-3.5">
                    <p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> Are you sure you want to delete this grade? </p>

                    <div className="dialog_selectsemester_msg_btns">
                        <div className="dialog_selectsemester_msg_btns_flexy" />
                        <button
                            className={`dialog_selectsemester_btns_cancelBtn ${user?.dark && `hover:!bg-gray-600`} p-1.5 w-16 rounded-lg mr-[0.265rem]`}
                            onClick={() => {
                                setOpenDialog_Message(false);
                                /*FIX*/
                                clearAllStateProperties();
                            }}
                        > No </button>
                        <button
                            className="dialog_selectsemester_btns_addBtn p-1.5 w-16 rounded-lg ml-[0.265rem]"
                            onClick={() => {
                                if (
                                    subject !== ""
                                ) {

                                    if (user?.semester === 1) {
                                        //editing on DB by AppSync
                                        handleEditUser({ ...user, semester_1: { ...user?.semester_1, grade: user?.semester_1.grade.filter((item) => { return item.id !== openCreatedId; }) } });
                                    }
                                    else if (user?.semester === 2) {
                                        //editing on DB by AppSync
                                        handleEditUser({ ...user, semester_2: { ...user?.semester_2, grade: user?.semester_2.grade.filter((item) => { return item.id !== openCreatedId; }) } });
                                    }

                                    setOpenCreatedId(null);
                                }

                                setOpenDialog_Message(false);
                                /*FIX*/
                                clearAllStateProperties();
                            }}
                        > Yes </button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default Grade;