import React, { useRef } from 'react';
//MATERIAL-UI
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import PersonIcon from '@material-ui/icons/Person';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DnsIcon from '@material-ui/icons/Dns';
import IconButton from '@mui/material/IconButton';
//IMAGES
import icon from '../../images/icon.png';
import look from '../../images/look.png';
import person2 from '../../images/person2.png';
import box from '../../images/box.png';
//REACT-CAROURSEL
import Coursel from './Coursel';
//CONTEXT-API
import { useStateValue } from '../../StateContext';
//COMPONENTS
import Footer from './Footer';


function Page({
    loginFunc,
    signupFunc,
    homeFunc
}: {
    loginFunc: () => void,
    signupFunc: () => void,
    homeFunc: () => void
}) {
    const [ { url, isSignedin }, dispatch ] = useStateValue();


    let aboutRef = useRef();
    let featureRef = useRef();
    let pricingRef = useRef();
    let testimonialRef = useRef();


    return (
        <div ref={aboutRef} className="bg-[#eee]">
            <AppBar position="sticky" elevation={1} sx={{ padding: "0.465rem", backgroundColor: "white", color: "black" }}>
                <Toolbar sx={{ width: "90%", margin: "0 auto" }}>
                    <div className="flex-1 flex items-center mt-1.5">
                        <img src={icon} alt="icon" className="w-[59px] h-[58px] -ml-1.5" />

                        <div className="flex items-center ml-20 invisible">
                            <p onClick={() => { aboutRef.current.scrollIntoView({ behavior: 'smooth' }); }} className="hover:text-red-500 hover:cursor-pointer border-b-2 border-b-transparent hover:border-b-red-500 p-2"> About </p>
                            <p onClick={() => { featureRef.current.scrollIntoView({ behavior: 'smooth' }); }} className="hover:text-red-500 hover:cursor-pointer border-b-2 border-b-transparent hover:border-b-red-500 p-2 ml-7"> Feature </p>
                            <p onClick={() => { pricingRef.current.scrollIntoView({ behavior: 'smooth' }); }} className="hover:text-red-500 hover:cursor-pointer border-b-2 border-b-transparent hover:border-b-red-500 p-2 ml-7"> Pricing </p>
                            <p onClick={() => { testimonialRef.current.scrollIntoView({ behavior: 'smooth' }); }} className="hover:text-red-500 hover:cursor-pointer border-b-2 border-b-transparent hover:border-b-red-500 p-2 ml-7"> Testimonial </p>
                        </div>
                    </div>

                    <div className="flex items-center">
                        {
                            !isSignedin && (
                                <button onClick={loginFunc} className="text-black hover:text-red-500 w-[6.35rem] p-2 rounded-3xl">
                                    Sign In
                                </button>
                            )
                        }

                        {
                            !isSignedin ? (
                                <button onClick={signupFunc} className="hover:bg-red-500 hover:text-white text-red-500 w-[8rem] p-2 rounded-3xl" style={{ border: "1px solid #dc2626" }}>
                                    Sign Up
                                </button>
                            ) : (
                                <button onClick={homeFunc} className="hover:bg-red-500 hover:text-white text-red-500 w-[8rem] p-2 rounded-3xl" style={{ border: "1px solid #dc2626" }}>
                                    Home
                                </button>
                            )
                        }
                    </div>
                </Toolbar>
            </AppBar>


            <div className={`flex items-center w-[85%] ${url==="/tasks"?`!mt-[4.5rem]`:`!mt-[22rem]`}`} style={{ margin: "0 auto" }}>
                <div className="w-[55%]">
                    <p className="text-5xl text-black"> Your classroom and life are <span className="font-bold"> organized. </span> </p>
                    <p className="mt-5 ml-0.5 text-gray-600"> We understand that managing a student's life isn't an easy task. That's why we've built Tructivity to manage your absences, grades, tasks, and more! </p>
                    <button onClick={!isSignedin?loginFunc:homeFunc} className="mt-8 bg-red-500 text-white w-[12rem] p-3 rounded-xl font-bold hover:shadow-2xl hover:shadow-red-500" style={{ wordSpacing: "2px" }}>
                        Get Started
                    </button>
                </div>

                <div className="w-[45%]">
                    <img src={look} alt="look" className="h-[21rem] w-[133rem]"/>
                </div>
            </div>


            {/* <div ref={featureRef} className="flex justify-center w-[85%] !mt-20" style={{ margin: "0 auto" }}>
                <div className="flex items-center">
                    <div className="flex items-center">
                        <IconButton className="bg-red-200 hover:!bg-red-200 w-11 p-2.5"> <PersonIcon style={{ fill: "red" }} /> </IconButton>

                        <div className="ml-6">
                            <span className="font-bold text-2xl text-black"> 390+ </span>
                            <br />
                            <span className="text-gray-600"> Users </span>
                        </div>
                    </div>

                    <div className="ml-8 mr-8 h-24 border-[1px] border-gray-300" />

                    <div className="flex items-center">
                        <IconButton className="bg-red-200 hover:!bg-red-200 w-11 p-2.5"> <LocationOnIcon style={{ fill: "red" }} /> </IconButton>

                        <div className="ml-6">
                            <span className="font-bold text-2xl text-black"> 20+ </span>
                            <br />
                            <span className="text-gray-600"> Locations </span>
                        </div>
                    </div>

                    <div className="ml-8 mr-8 h-24 border-[1px] border-gray-300" />

                    <div className="flex items-center">
                        <IconButton className="bg-red-200 hover:!bg-red-200 w-11 p-2.5"> <DnsIcon style={{ fill: "red" }} /> </IconButton>

                        <div className="ml-6">
                            <span className="font-bold text-2xl text-black"> 50+ </span>
                            <br />
                            <span className="text-gray-600"> Server </span>
                        </div>
                    </div>
                </div>
            </div> */}


            {/* <div className="flex items-center w-[85%] !mt-28" style={{ margin: "0 auto" }}>
                <div className="w-[55%]">
                    <img src={person2} alt="person" />
                </div>

                <div className="w-[45%]">
                    <p className="text-4xl text-black font-medium"> The app will be soon available on both Android and iOS </p>
                    <p className="mt-5 ml-0.5 text-gray-700"> With all the essential tools, you'll never have trouble staying on top of your responsibilities again. </p>
                    <ul className="list-disc mt-2.5 ml-5 text-gray-700">
                        <li className="mt-0.5"> Support the mobile notification. </li>
                        <li className="mt-0.5"> Sync your data across devices. </li>
                        <li className="mt-0.5"> Easy to use with simple interface. </li>
                        <li className="mt-0.5"> Take notes in the class. </li>
                    </ul>
                </div>
            </div> */}


            <div ref={pricingRef} />
            {/* <div className="flex flex-col items-center !mt-14 bg-white pb-14" style={{ margin: "0 auto" }}>
                <div className="mt-14">
                    <p className="text-3xl font-bold text-center text-black ml-1"> Choose Your Plan </p>
                    <p className="text-gray-600 !mt-2.5 text-center w-[80%]" style={{ margin: "0 auto" }}> Let's choose the package that is best for you and explore it happily and cheerfully. </p>
                </div>

                <div className="flex items-center mt-9">
                    <div className="border-2 border-gray-300 p-11 rounded-xl w-[20rem] flex flex-col items-center">
                        <div>
                            <div className="flex flex-col items-center">
                                <img src={box} alt="box" />
                                <p className="text-xl text-black font-bold mt-8"> Free Plan </p>
                            </div>

                            <ul className="list-disc mt-6 ml-5 text-gray-700">
                                <li className="mt-2.5 text-gray-600"> Powerfull online protection. </li>
                                <li className="mt-2.5 text-gray-600"> Internet without borders. </li>
                                <li className="mt-2.5 text-gray-600"> Supercharged VPN. </li>
                                <li className="mt-2.5 text-gray-600"> Powerfull online protection. </li>
                            </ul>
                        </div>

                        <div className="mt-10 flex flex-col items-center">
                            <p className="text-2xl text-black text-center mb-3"> Free </p>
                            <button className="hover:bg-red-500 hover:text-white text-red-500 w-[8rem] p-2 rounded-3xl" style={{ border: "1px solid #dc2626" }}>
                                Select
                            </button>
                        </div>
                    </div>

                    <div className="border-2 border-gray-300 p-11 rounded-xl w-[20rem] flex flex-col items-center ml-10">
                        <div>
                            <div className="flex flex-col items-center">
                                <img src={box} alt="box" />
                                <p className="text-xl text-black font-bold mt-8"> Standard Plan </p>
                            </div>

                            <ul className="list-disc mt-6 ml-5 text-gray-700">
                                <li className="mt-2.5 text-gray-600"> Unlimited Bandwitch. </li>
                                <li className="mt-2.5 text-gray-600"> Encrypted Connection. </li>
                                <li className="mt-2.5 text-gray-600"> No Traffic Logs. </li>
                                <li className="mt-2.5 text-gray-600"> Works on All Devices. </li>
                            </ul>
                        </div>

                        <div className="mt-10 flex flex-col items-center">
                            <p className="text-2xl text-black text-center mb-3"> $9 / mo </p>
                            <button className="hover:bg-red-500 hover:text-white text-red-500 w-[8rem] p-2 rounded-3xl" style={{ border: "1px solid #dc2626" }}>
                                Select
                            </button>
                        </div>
                    </div>
                </div>
            </div> */}


            <div ref={testimonialRef} />
            <br />
            <br />
            {/* <div>
                <div className="mt-14">
                    <p className="text-3xl text-black font-bold text-center ml-1 w-[28%]" style={{ margin: "0 auto" }}> Trusted by Thousands of Happy Customer </p>
                    <p className="text-gray-600 !mt-2.5 text-center w-[30%]" style={{ margin: "0 auto" }}> These are the stories of our customers who have joined us with great pleasure when using this crazy feature. </p>
                </div>

                <Coursel
                />
            </div> */}


            <div className="bg-white rounded-xl w-[80%] pb-11 hidden" style={{ margin: "0 auto" }}>
                <div className="flex items-center">
                    <div className="mt-14">
                        <p className="text-3xl text-black font-bold ml-[5.5rem] w-[70%]"> Are you having trouble getting organized? </p>
                        <p className="text-gray-600 mt-2.5 ml-[5.4rem] w-[60%]"> Tructivity is for you! Create tasks, schedule events, keep track of your grades, and more. </p>
                    </div>

                    <button onClick={!isSignedin?loginFunc:homeFunc} className="mt-14 ml-24 bg-red-500 text-white w-[12rem] p-3 rounded-xl font-bold hover:shadow-2xl hover:shadow-red-500" style={{ wordSpacing: "2px" }}>
                        Get Started
                    </button>
                </div>
            </div>


            <Footer
            />
        </div>
    )
}

export default Page;