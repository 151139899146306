import React, { useState } from 'react';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
//GATSBY
import { navigate } from 'gatsby';
//CONTEXT-API
import { useStateValue } from '../../../../../../StateContext';
import { settingURL, settingISSIGNEDIN, settingUSER } from '../../../../../../contextapi';
//SWEETALERT2
import { errorAlert } from '../../../../../../alerts';
//APPSYNC-MUTATION
import { handleDeleteUser } from '../../../../../../appsync_functions/mutations';
//STYLE
import './index.scss';


interface Dialog_DeleteAccountProps {
    open         : boolean;
    setOpen      : Function;
    setOpenDialog: Function;
}

function Dialog_DeleteAccount({
    open,
    setOpen,
    setOpenDialog
}: Dialog_DeleteAccountProps) {
    const [ { user }, dispatch ] = useStateValue();


    const [ clicked, setClicked ] = useState<boolean>(false);


    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            className="dialog"
        >
            <div className="dialog_deleteaccount pt-4 pb-2.5 pl-4" style={{ margin: "0 auto" }}>

                <p className={`text-lg text-center font-sans pt-1 pb-2 ${`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}`}> This action will immediately delete all of your data including tasks, notes, grades, and more. This can’t be undone. </p>

                <div className="dialog_deleteaccount_btns pb-3 pt-2">
                    <button className="dialog_deleteaccount_btns_cnl_btn p-1.5 pb-2 pl-2.5 pr-2.5 w-40 text-lg rounded-lg mt-1.5 mr-[0.325rem]" onClick={() => setOpen(false)}> Cancel </button>
                    <button
                        className="dialog_deleteaccount_btns_dlt_btn p-1.5 pb-2 pl-2.5 pr-2.5 w-44 text-lg rounded-lg mt-1.5 ml-[0.325rem]"
                        disabled={clicked}
                        onClick={() => {
                            setClicked(true);


                            setTimeout(async() => { 
                                try {
                                    //pushing to DB by AppSync
                                    handleDeleteUser(user?.id, user?.email);
        
                                    dispatch(settingURL("/"));
                                    navigate("/");

                                    dispatch(settingISSIGNEDIN(false));
                                    dispatch(settingUSER(null));

                                    setClicked(false);
                                    setOpen(false);
                                    setOpenDialog(false);
                                }
                                catch(error) {
                                    console.log('error deleteaccount: ', error);
                                    setClicked(false);
                                    setOpen(false);
                                    setOpenDialog(false);
                                    errorAlert('Signup', error.message);
                                }
                            }, 1500);
                        }}
                    > Delete Account { clicked && <CircularProgress style={{ color: 'white', marginTop: "0.15rem", marginLeft: "0.1rem" }} size={11}/> } </button>
                </div>

            </div>
        </Dialog>
    )
}

export default Dialog_DeleteAccount;