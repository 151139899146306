import React, { useState } from 'react';
import Dialog_Warning from './Dialog_Warning';
import Dialog_Addition from './Dialog_Addition';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/core/styles';
//CONTEXT-API
import { useStateValue } from '../../../../../StateContext';
//SCSS
import './index.scss';


//FOR MUI DIALOG WIDTH/HEIGHT
const useStyles = makeStyles({
    paper: {
        minWidth: "40.75rem",
        minHeight: "34.75rem"
    }
});


interface Dialog_Of_ThreePagesProps {
    open        : boolean;
    setOpen     : Function;
    message     : string;
    data        : string[];
    funcDelete  : Function;
    funcAddition: Function;
}

function Dialog_Of_ThreePages({
    open,
    setOpen,
    message,
    data,
    funcDelete,
    funcAddition
}: Dialog_Of_ThreePagesProps) {
    const classes = useStyles();
    const [ { user }, dispatch ] = useStateValue();


    const [ openDialogAddition, setOpenDialogAddition ] = useState<boolean>(false);
    const [ openDialogWarning, setOpenDialogWarning ] = useState<boolean>(false);


    const [ deletingItem, setDeletingItem ] = useState<string>("");


    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                className="dialog"
                classes={{ paper: classes.paper }}
            >
                <div className="dialog_of_threepages">
                    <div className="dialog_of_threepages_header">
                        <Typography variant="h4" className={`dialog_of_threepages_header_flexy ${user?.dark?`!text-[#eee]`:`!text-[#000]`}`}> Manage Categories </Typography>

                        <IconButton onClick={() => setOpenDialogAddition(true)}>
                            <AddIcon/>
                        </IconButton>
                    </div>
    
                    {
                        data &&
                        data.map((item, i) => (
                            <span key={i}>
                                <div className="dialog_of_threepages_box">
                                    {
                                        item!=="All"&&(
                                            <>
                                                <div className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}> {item} </div>
            
                                                <IconButton
                                                    onClick={() => { setOpenDialogWarning(true); setDeletingItem(item); }}
                                                    className="w-[8.5%]"
                                                >
                                                    <DeleteIcon/>
                                                </IconButton>                                            
                                            </>
                                        )
                                    }
                                </div>
    

                                <Dialog_Warning
                                    open={openDialogWarning}
                                    setOpen={setOpenDialogWarning}
                                    message={message}
                                    func={funcDelete}
                                    item={deletingItem}
                                />
                            </span>
                        ))
                    }
                </div>
            </Dialog>


            <Dialog_Addition
                open={openDialogAddition}
                setOpen={setOpenDialogAddition}
                func={funcAddition}
            />
        </>
    )
}

export default Dialog_Of_ThreePages;