import React from 'react';
import icon from '../../images/icon.png';
//MATERIAL-UI
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
//GATSBY
import { Link, navigate } from 'gatsby';
//CONTEXT-API
import { useStateValue } from '../../StateContext';
import { settingURL, settingISCONFIRMEDSIGNUP, settingSIGNEDUPFIRSTNAME, settingSIGNEDUPLASTNAME, settingSIGNEDUPEMAIL, settingSIGNEDUPPASSWORD } from '../../contextapi';
//FORMIK & YUP
import { Formik, ErrorMessage } from 'formik';
const yup = require('yup')
require('yup-password')(yup);
//SWEETALERT2
import { toastAlert, successAlert, errorAlert } from '../../alerts';
//AWS-AMPILFY
import { Auth } from 'aws-amplify';
//APPSYNC-MUTATIONS
import { handleCreateUser } from '../../appsync_functions/mutations';


function Register() {
    const [{ signedupFirstname, signedupLastname, signedupEmail, signedupPassword, isConfirmedSignup, user }, dispatch] = useStateValue();


    return (
        <>
            {
                (isConfirmedSignup) && (
                    <div className="topper">
                        <div className="topper_bottom">
                            <IconButton
                                onClick={() => dispatch(settingISCONFIRMEDSIGNUP(false))}
                            >
                                <ArrowBackIcon />
                            </IconButton>
                        </div>
                    </div>
                )
            }

            <div className={`${`${user&&user?.dark ? `!bg-[#5e5b5b]` : `!bg-white`}`} !rounded-lg !mt-[-2.85rem] !w-[27rem] !h-[29.545rem] !pl-2.5 !pr-2.5`} style={{ margin: "0 auto" }}>

                <Box sx={{ marginTop: 9, padding: 1.855, paddingBottom: 3.65, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar className="!bg-transparent !mt-3.5 !ml-3 !mb-1.5 !mr-1.5 !w-16 !h-16">
                        <img src={icon} alt="logo" />
                    </Avatar>

                    <div className="!mt-[-1.1rem]">
                        {
                            /*STEP-1*/
                            !isConfirmedSignup ? (
                                <Formik
                                    initialValues={{
                                        firstname: '',
                                        lastname: '',
                                        email: '',
                                        password: ''
                                    }}
                                    validationSchema={
                                        yup.object({
                                            firstname: yup.string()
                                                .max(30, 'Must be atmost 30 characters')
                                                .min(3, 'Must have atleast 3 characters')
                                                .required('Must fill firstname'),

                                            lastname: yup.string()
                                                .max(30, 'Must be atmost 30 characters')
                                                .min(3, 'Must have atleast 3 characters')
                                                .required('Must fill lastname'),

                                            email: yup.string()
                                                .email('Must be a valid email')
                                                .required('Must fill email'),

                                            password: yup.string().password()
                                                .minLowercase(1, 'Must be atleast 1 lowercase')
                                                .minUppercase(1, 'Must be atleast 1 uppercase')
                                                .minNumbers(1, 'Must be atleast 1 number')
                                                .minSymbols(1, 'Must include atleast 1 symnol')
                                                .min(6, 'Must be atleast 6 characters')
                                                .required('Must fill password')
                                        })
                                    }
                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(true);
                                        toastAlert('Submitting..', 'top-end', 'info');


                                        setTimeout(async () => {
                                            try {
                                                /* using here due to ContextApi disptach accessable only in react components */
                                                await Auth.signUp({
                                                    username: values.firstname + values.lastname,
                                                    password: values.password,
                                                    attributes: {
                                                        email: values.email,        //optional
                                                        phone_number: "+1234567890",//optional
                                                    }
                                                });
                                                console.log('sended signup code');

                                                dispatch(settingSIGNEDUPFIRSTNAME(values.firstname));
                                                dispatch(settingSIGNEDUPLASTNAME(values.lastname));
                                                dispatch(settingSIGNEDUPEMAIL(values.email));
                                                dispatch(settingSIGNEDUPPASSWORD(values.password));

                                                dispatch(settingISCONFIRMEDSIGNUP(true));
                                            }
                                            catch (error) {
                                                console.log('error signup:', error);
                                                errorAlert('Signup', error.message);
                                            }


                                            setSubmitting(false);
                                        }, 1500);
                                    }}
                                >
                                    {({ values, handleChange, handleSubmit, isSubmitting }) => (
                                        <Box component="form" onSubmit={handleSubmit}>
                                            <Typography className={`${user&&user?.dark ? `!text-white` : `!text-black`} !text-2xl !font-semibold !mt-2.5`}>
                                                <span className="!ml-[9.285rem]">Sign Up</span>
                                            </Typography>

                                            <Grid container spacing={2} className="!mt-2">
                                                <Grid item xs={12} sm={6}>
                                                    <input
                                                        className="w-full rounded-lg pt-3 pb-3 pl-3.5 outline-none border-2 border-gray-300 text-black"
                                                        name="firstname"
                                                        value={values.firstname}
                                                        onChange={handleChange}
                                                        placeholder="First Name"
                                                        autoComplete="off"
                                                        disabled={isSubmitting}
                                                    />
                                                    <ErrorMessage name='firstname' component="p" className="err" />
                                                </Grid>
                                                <Grid item xs={12} sm={6}>
                                                    <input
                                                        className="w-full rounded-lg pt-3 pb-3 pl-3.5 outline-none border-2 border-gray-300 text-black"
                                                        name="lastname"
                                                        value={values.lastname}
                                                        onChange={handleChange}
                                                        placeholder="Last Name"
                                                        autoComplete="off"
                                                        disabled={isSubmitting}
                                                    />
                                                    <ErrorMessage name='lastname' component="p" className="err" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <input
                                                        className="mt-[-0.325rem] w-full rounded-lg pt-3 pb-3 pl-3.5 outline-none border-2 border-gray-300 text-black"
                                                        name="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        placeholder="Email"
                                                        autoComplete="off"
                                                        disabled={isSubmitting}
                                                    />
                                                    <ErrorMessage name='email' component="p" className="err" />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <input
                                                        className="mt-[-0.325rem] w-full rounded-lg pt-3 pb-3 pl-3.5 outline-none border-2 border-gray-300 text-black"
                                                        type="password"
                                                        name="password"
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        placeholder="Password"
                                                        autoComplete="off"
                                                        disabled={isSubmitting}
                                                    />
                                                    <ErrorMessage name='password' component="p" className="err" />
                                                </Grid>
                                            </Grid>

                                            <p className="text-xs text-center text-gray-600 w-[80%] !mt-3" style={{ margin: "0 auto" }}>
                                                By creating an account, you are agreeing to our Terms of Conditions and Privacy Policy
                                            </p>

                                            <Button
                                                fullWidth
                                                type="submit"
                                                variant="contained"
                                                disabled={isSubmitting}
                                                className="!bg-blue-600 !text-white !h-[2.335rem]"
                                                sx={{ mt: 1.5, mb: 2 }}
                                            >
                                                Sign Up {isSubmitting && <CircularProgress style={{ color: 'white', marginTop: "0.1rem", marginLeft: "0.4rem" }} size={15} />}
                                            </Button>
                                        </Box>
                                    )}
                                </Formik>
                            ) : (
                                <Formik
                                    initialValues={{
                                        confimrationcode: ''
                                    }}
                                    validationSchema={
                                        yup.object({
                                            confimrationcode: yup.string()
                                                .max(6, 'Must be atmost 6 characters')
                                                .min(6, 'Must be atleast 6 characters')
                                                .required('Must fill Confimration Code')
                                        })
                                    }
                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(true);
                                        toastAlert('Submitting..', 'top-end', 'info');


                                        setTimeout(async () => {
                                            try {
                                                /* using here due to ContextApi disptach accessable only in react components */
                                                await Auth.confirmSignUp(
                                                    signedupFirstname + signedupLastname,
                                                    values.confimrationcode
                                                );
                                                console.log('successfully signup');

                                                //pushing to DB by AppSync
                                                handleCreateUser(signedupFirstname, signedupLastname, signedupEmail, signedupPassword);

                                                dispatch(settingSIGNEDUPFIRSTNAME(""));
                                                dispatch(settingSIGNEDUPLASTNAME(""));
                                                dispatch(settingSIGNEDUPEMAIL(""));
                                                dispatch(settingSIGNEDUPPASSWORD(""));

                                                dispatch(settingURL("/login"));
                                                navigate("/login");

                                                dispatch(settingISCONFIRMEDSIGNUP(false));
                                            }
                                            catch (error) {
                                                console.log('error signup', error);
                                                errorAlert('Signup', error.message);
                                            }


                                            setSubmitting(false);
                                        }, 1500);
                                    }}
                                >
                                    {({ values, handleChange, handleSubmit, isSubmitting, touched }) => (
                                        <Box component="form" onSubmit={handleSubmit}>
                                            <Typography className={`${user&&user?.dark ? `!text-white` : `!text-black`} !text-2xl !font-semibold !mt-2.5`}>
                                                <span className="!ml-[6.285rem]">Confirm Signup</span>
                                            </Typography>

                                            <div className="flex items-center justify-center flex-col h-[17.45rem]">
                                                <div className="flex-1 mt-4">
                                                    <Grid container spacing={2} className="!mt-14">
                                                        <Grid item className="w-[25rem] ml-1">
                                                            <input
                                                                className="mt-[-0.325rem] w-full rounded-lg pt-3 pb-3 pl-3.5 outline-none border-2 border-gray-300 text-black"
                                                                name="confimrationcode"
                                                                value={values.confimrationcode}
                                                                onChange={handleChange}
                                                                placeholder="Confimration Code"
                                                                autoComplete="off"
                                                                disabled={isSubmitting}
                                                            />
                                                            <ErrorMessage name='confimrationcode' component="p" className="err" />
                                                        </Grid>
                                                    </Grid>

                                                    <Button
                                                        fullWidth
                                                        type="submit"
                                                        variant="contained"
                                                        disabled={isSubmitting}
                                                        className="!bg-blue-600 !text-white !h-[2.335rem] !mt-6"
                                                        sx={{ mt: 3, mb: 2 }}
                                                    >
                                                        Submit {isSubmitting && <CircularProgress style={{ color: 'white', marginTop: "0.1rem", marginLeft: "0.4rem" }} size={15} />}
                                                    </Button>
                                                </div>

                                                <Grid container justifyContent="flex-end">
                                                    <Grid item>
                                                        <button
                                                            type="button"
                                                            className={`!cursor-pointer !no-underline !text-sm !w-[100%] !mb-0.5 ${user&&user?.dark ? `!text-white` : `!text-blue-700`}`}
                                                            onClick={async () => {
                                                                try {
                                                                    await Auth.resendSignUp(
                                                                        signedupFirstname + signedupLastname
                                                                    );
                                                                    console.log('sended resend code');

                                                                    successAlert('successfully Resended');
                                                                }
                                                                catch (error) {
                                                                    console.log('error resend', error);
                                                                    errorAlert('Resend', error.message);
                                                                }
                                                            }}
                                                        >
                                                            {"Resend code now!"}
                                                        </button>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </Box>
                                    )}
                                </Formik>
                            )
                        }

                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link onClick={() => { dispatch(settingURL("/login")); navigate("/login"); }} to="/login" className={`!cursor-pointer !no-underline !text-sm ${user&&user?.dark ? `!text-white` : `!text-blue-700`}`}>
                                    {"Already have an account? Log in"}
                                </Link>
                            </Grid>
                        </Grid>
                    </div>
                </Box>

            </div>
        </>
    )
}

export default Register;