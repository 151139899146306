import React from 'react';
import icon from '../../images/icon.png';
//MATERIAL-UI
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
//GATSBY
import { Link, navigate } from 'gatsby';
//CONTEXT-API
import { useStateValue } from '../../StateContext';
import { settingURL, settingISSIGNEDIN, settingUSER, settingREFETCHLOADINGAPPSYNC, settingTWOHALFSECONDSAFTERSIGNIN } from '../../contextapi';
//FORMIK & YUP
import { Formik, ErrorMessage } from 'formik';
const yup = require('yup');
//SWEETALERT2
import { toastAlert, errorAlert } from '../../alerts';
//AWS-AMPLIFY
import { Auth } from 'aws-amplify';
//APPSYNC-QUERIES
import { fetchAllUsers } from '../../appsync_functions/queries';
//APPSYNC-SUBSCRIPTION
import { subscribeOnUser } from '../../appsync_functions/subscriptions';


function Login() {
    const [{ user }, dispatch] = useStateValue();


    return (
        <div className={`${`${user && user?.dark ? `!bg-[#5e5b5b]` : `!bg-white`}`} !rounded-lg !mt-[-2.85rem] !w-[27rem] !h-[27.545rem] !pl-2.5 !pr-2.5`} style={{ margin: "0 auto" }}>

            <Box sx={{ marginTop: 9, padding: 1.855, paddingBottom: 3.65, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar className="!bg-transparent !mt-3.5 !ml-3 !mb-1.5 !mr-1.5 !w-16 !h-16">
                    <img src={icon} alt="logo" />
                </Avatar>

                <div className="!mt-[-0.45rem] !ml-0.5">
                    <Typography className={`${user && user?.dark ? `!text-white` : `!text-black`} !text-2xl !font-semibold`}>
                        <span className="!ml-[9.725rem]">Log In</span>
                    </Typography>

                    <Formik
                        initialValues={{
                            email: '',
                            password: '',
                        }}
                        validationSchema={
                            yup.object({
                                email: yup.string()
                                    .email('Must be a valid email')
                                    .required('Must fill email'),

                                password: yup.string()
                                    .required('Must fill password')
                            })
                        }
                        onSubmit={(values, { setSubmitting }) => {
                            setSubmitting(true);
                            toastAlert('Submitting..', 'top-end', 'info');


                            setTimeout(async () => {
                                try {
                                    console.log({
                                        email: values.email,
                                        password: values.password
                                    })
                                    /* using here due to ContextApi disptach accessable only in react components */
                                    await Auth.signIn(
                                        values.email,
                                        values.password
                                    );
                                    console.log('successfully signin');

                                    /***************JUST FOR REFETCH AT BROWSER OPENED FIRST TIME & NOT EFFECTIVE AFTER BROWSER CLOSED***************/
                                    const fetchFunction = async () => {
                                        dispatch(settingREFETCHLOADINGAPPSYNC(true));


                                        const allUsers = await fetchAllUsers();
                                        /*trick to get one user from all*/
                                        const thatUser = allUsers.filter(item => (item.email === values.email))[0];


                                        dispatch(settingISSIGNEDIN(true));
                                        dispatch(settingUSER(thatUser));


                                        dispatch(settingREFETCHLOADINGAPPSYNC(false));
                                    }

                                    //fetching for first time
                                    fetchFunction()
                                        .then(() => {
                                            dispatch(settingURL("/tasks"));
                                            navigate("/tasks");

                                            dispatch(settingTWOHALFSECONDSAFTERSIGNIN(true));

                                            setTimeout(() => {
                                                dispatch(settingTWOHALFSECONDSAFTERSIGNIN(false));
                                            }, 2000);
                                        });

                                    //'subscription' for first time
                                    subscribeOnUser(fetchFunction);
                                    /***************JUST FOR REFETCH AT BROWSER OPENED FIRST TIME & NOT EFFECTIVE AFTER BROWSER CLOSED***************/
                                }
                                catch (error) {
                                    console.log('error signin', error);
                                    errorAlert('Login', error.message);
                                }


                                setSubmitting(false);
                            }, 1500);
                        }}
                    >
                        {({ values, handleChange, handleSubmit, isSubmitting }) => (
                            <Box component="form" onSubmit={handleSubmit} className="!mt-3.5 flex items-center justify-center flex-col h-[18rem]">
                                <div className="flex-1 mt-8">
                                    <input
                                        className="w-full rounded-lg pt-3 pb-3 pl-3.5 outline-none border-2 border-gray-300 text-black"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        placeholder="Email"
                                        autoComplete="off"
                                        disabled={isSubmitting}
                                    />
                                    <ErrorMessage name='email' component="p" className="err" />

                                    <input
                                        className="mt-2.5 w-full rounded-lg pt-3 pb-3 pl-3.5 outline-none border-2 border-gray-300 text-black"
                                        type="password"
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        placeholder="Password"
                                        autoComplete="off"
                                        disabled={isSubmitting}
                                    />
                                    <ErrorMessage name='password' component="p" className="err" />

                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        disabled={isSubmitting}
                                        className="!bg-blue-600 !text-white"
                                        sx={{ mt: 3, mb: 2 }}
                                    >
                                        Log In {isSubmitting && <CircularProgress style={{ color: 'white', marginTop: "0.1rem", marginLeft: "0.4rem" }} size={15} />}
                                    </Button>
                                </div>

                                <Grid container>
                                    <Grid item xs>
                                        <Link onClick={() => { dispatch(settingURL("/forgotPassword")); navigate("/forgotPassword"); }} to="/forgotPassword" className={`!cursor-pointer !no-underline !text-sm ${user && user?.dark ? `!text-white` : `!text-blue-700`}`}>
                                            Forgot password?
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link onClick={() => { dispatch(settingURL("/signup")); navigate("/signup"); }} to="/signup" className={`!cursor-pointer !no-underline !text-sm ${user && user?.dark ? `!text-white` : `!text-blue-700`}`}>
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </Formik>
                </div>
            </Box>

        </div>
    )
}

export default Login;