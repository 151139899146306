import React, { useState, useEffect } from 'react';
//MATERIAL-UI
import Card from '@material-ui/core/Card';
//REACT-BIG-CALENDAR
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
//MOMENT
import moment from "moment";
//SCSS
import './index.scss';
import './_fixed.scss';


let formats = {
    dayFormat: (date, culture, localizer) => localizer.format(date, "dddd", culture)
};
moment.locale('ko', {
    week: {
        dow: 1
    }
});
const localizer = momentLocalizer(moment);


interface BigCalendarProps {
    events: any[];
    handleEvent: Function;
    handleOpened: Function;
}

function BigCalendar({
    events,
    handleEvent,
    handleOpened
}: BigCalendarProps) {
    const [allEvents, setAllEvents] = useState(events);
    useEffect(() => setAllEvents(events), [events]);

    
    console.log({allEventsallEvents:allEvents})


    return (
        <Card style={{ height: "35.315rem", margin: "2.25rem" }} id="noDynamic_fixed" square>
            <Calendar
                localizer={localizer}
                events={allEvents}
                formats={formats}
                startAccessor="start"
                endAccessor="end"
                defaultDate={new Date(2024, 4, 6)}    // Month is 0-indexed, so 4 represents May ( FIX TRICK )
                defaultView="work_week"
                views={["week", "work_week"]}
                selectable={true}
                onSelectEvent={(e) => {
                    handleOpened(e);
                }}
                onSelectSlot={(e) => {
                    handleEvent({ start: e.start.toString(), end: e.end.toString() });
                }}
                eventPropGetter={(e) => {
                    const backgroundColor = e.colorpicker?e.colorpicker:"blue";
                    return { style: { backgroundColor } }
                }}
            />
        </Card>
    )
}

export default BigCalendar;