import React, { useState } from 'react';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
//CONTEXT-API
import { useStateValue } from '../../../../../../StateContext';
//FORMIK & YUP
import { Formik, ErrorMessage } from 'formik';
const yup = require('yup')
require('yup-password')(yup);
//SWEETALERT2
import { errorAlert } from '../../../../../../alerts';
//AWS-AMPLIFY
import { Auth } from 'aws-amplify';
//APPSYNC-MUTATION
import { handleEditUser } from '../../../../../../appsync_functions/mutations';
//STYLE
import './index.scss';


//FOR MUI DIALOG WIDTH/HEIGHT
const useStyles = makeStyles({
    paper: {
        minWidth: "42.9vh",
        maxWidth: "42.9vh",
        minHeight: "27.9vh",
        maxHeight: "27.9vh",
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
});


interface Dialog_ChangePasswordProps {
    open          : boolean;
    setOpen       : Function;
    setOpenDialog : Function;
}

function Dialog_ChangePassword({
    open,
    setOpen,
    setOpenDialog
}: Dialog_ChangePasswordProps) {
    const classes = useStyles();
    const [ { user, refetchLoadingAppSync }, dispatch ] = useStateValue();


    const [ matched, setMatched ] = useState<boolean>(false);


    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                className="dialog"
            >
                <div className="dialog_changepassword flex flex-col">
    
                    <div className="flex flex-col w-80 pt-3 pb-3" style={{ margin: "0 auto" }}>
                        <p className={`text-sm font-sans font-bold ${`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}`}> Password </p>
                        <TextField
                            hiddenLabel
                            value={user?.password}
                            variant="filled"
                            size="small"
                            spellCheck={false}
                        />
                    </div>

                    <Formik
                        initialValues={{
                            password : ''
                        }}
                        validationSchema={
                            yup.object({
                                password: yup.string().password()
                                .minLowercase(1, 'Must be atleast 1 lowercase')
                                .minUppercase(1, 'Must be atleast 1 uppercase')
                                .minNumbers(1, 'Must be atleast 1 number')
                                .minSymbols(0, 'Must not include any symnol')
                                .min(6, 'Must be atleast than 6 characters')
                                .required('Must fill password')
                            })
                        }
                        onSubmit={(async(values) => {
                            if(values.password === user?.password) {
                                setMatched(true);
                                return null;
                            }


                            try {
                                /* using here due to ContextApi disptach accessable only in react components */
                                const _user = await Auth.currentAuthenticatedUser();
                                await Auth.changePassword(
                                    _user,
                                    user?.password,
                                    values.password
                                );

                                //editing on DB by AppSync
                                handleEditUser({ ...user, password: values.password });

                                console.log('successfully changepassword');
                                setOpen(false);
                            }
                            catch(error) {
                                console.log('error changepassword', error);
                                setOpen(false);
                                setOpenDialog(false);
                                errorAlert('Change Password', error.message);
                            }
                        })}
                    >
                        {({ values, handleChange, handleSubmit }) => (
                            <form onSubmit={handleSubmit} autoComplete="off">
                                <div className="flex flex-col w-80 pt-3 pb-3" style={{ margin: "0 auto" }}>
                                    <p className={`text-sm font-sans font-bold ${`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}`}> New Password </p>
                                    <TextField
                                        hiddenLabel
                                        name="password"
                                        value={values.password}
                                        onChange={handleChange}
                                        variant="filled"
                                        size="small"
                                        spellCheck={false}
                                    />
                                    <ErrorMessage name='password' component="p" className="err"/>
                                    {matched && <p className="err"> Please enter different password </p>}
                                </div>

                                <div className="dialog_changepassword_btns pb-3">
                                    <div className="dialog_changepassword_btns_flexy"/>
                                    <button
                                        className="dialog_changepassword_btns_btn p-1.5 w-36 text-sm rounded-lg mt-1.5"
                                        type="submit"
                                    > Change Password </button>
                                </div>
                            </form>
                        )}
                    </Formik>

                </div>
            </Dialog>


            <Dialog
                open={refetchLoadingAppSync}
                className="dialog"
                classes={{ paper: classes.paper }}
            >
                <CircularProgress size={40}/>
            </Dialog>
        </>
    )
}

export default Dialog_ChangePassword;