/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const allUsers = /* GraphQL */ `
  query AllUsers {
    allUsers {
      id
      firstname
      lastname
      email
      password
      dark
      bandwidth
      storage
      regestrationDate
      endingDate
      semester
      pageSettings {
        homework
        events
        tasks
        notes
        absences
        teachers
        subjects
        schedule
        timetable
        grades
        __typename
      }
      semester_1 {
        absence {
          id
          subject
          teacher
          room
          note
          date
          __typename
        }
        subject {
          id
          subject
          teacher
          room
          note
          __typename
        }
        grade {
          id
          subject
          grade
          weight
          note
          date
          maxpoints
          credit
          course
          gradeSystem
          __typename
        }
        __typename
      }
      semester_2 {
        absence {
          id
          subject
          teacher
          room
          note
          date
          __typename
        }
        subject {
          id
          subject
          teacher
          room
          note
          __typename
        }
        grade {
          id
          subject
          grade
          weight
          note
          date
          maxpoints
          credit
          course
          gradeSystem
          __typename
        }
        __typename
      }
      schedule {
        title
        start
        end
        id
        subject
        teacher
        room
        note
        time1
        time2
        colorpicker
        typeValue
        __typename
      }
      timetable {
        title
        day
        id
        subject
        teacher
        room
        note
        time1
        time2
        colorpicker
        __typename
      }
      teacher {
        id
        name
        phone
        email
        address
        officehours
        website
        __typename
      }
      note {
        id
        noteTitle
        description
        topics {
          id
          topicTitle
          topicDescrip
          colorpicker
          __typename
        }
        __typename
      }
      task {
        id
        task
        note
        date
        time
        category
        repeat
        date2
        selectedDays_WEEKLY
        selectedDays_MONTHLY
        datesBwDate1NDate2
        checked {
          date
          bool
          __typename
        }
        __typename
      }
      event {
        id
        event
        subject
        location
        note
        date
        time
        category
        repeat
        date2
        selectedDays_WEEKLY
        selectedDays_MONTHLY
        datesBwDate1NDate2
        checked {
          date
          bool
          __typename
        }
        __typename
      }
      homework {
        id
        subject
        teacher
        note
        date
        time
        category
        repeat
        type
        date2
        selectedDays_WEEKLY
        selectedDays_MONTHLY
        datesBwDate1NDate2
        checked {
          date
          bool
          __typename
        }
        __typename
      }
      manageCategories_Task
      manageCategories_Event
      manageCategories_Homework
      pomodorotimer {
        workminutes
        breakminutes
        __typename
      }
      __typename
    }
  }
`;
export const getUserById = /* GraphQL */ `
  query GetUserById($id: ID!) {
    getUserById(id: $id) {
      id
      firstname
      lastname
      email
      password
      dark
      bandwidth
      storage
      regestrationDate
      endingDate
      semester
      pageSettings {
        homework
        events
        tasks
        notes
        absences
        teachers
        subjects
        schedule
        timetable
        grades
        __typename
      }
      semester_1 {
        absence {
          id
          subject
          teacher
          room
          note
          date
          __typename
        }
        subject {
          id
          subject
          teacher
          room
          note
          __typename
        }
        grade {
          id
          subject
          grade
          weight
          note
          date
          maxpoints
          credit
          course
          gradeSystem
          __typename
        }
        __typename
      }
      semester_2 {
        absence {
          id
          subject
          teacher
          room
          note
          date
          __typename
        }
        subject {
          id
          subject
          teacher
          room
          note
          __typename
        }
        grade {
          id
          subject
          grade
          weight
          note
          date
          maxpoints
          credit
          course
          gradeSystem
          __typename
        }
        __typename
      }
      schedule {
        title
        start
        end
        id
        subject
        teacher
        room
        note
        time1
        time2
        colorpicker
        typeValue
        __typename
      }
      timetable {
        title
        day
        id
        subject
        teacher
        room
        note
        time1
        time2
        colorpicker
        __typename
      }
      teacher {
        id
        name
        phone
        email
        address
        officehours
        website
        __typename
      }
      note {
        id
        noteTitle
        description
        topics {
          id
          topicTitle
          topicDescrip
          colorpicker
          __typename
        }
        __typename
      }
      task {
        id
        task
        note
        date
        time
        category
        repeat
        date2
        selectedDays_WEEKLY
        selectedDays_MONTHLY
        datesBwDate1NDate2
        checked {
          date
          bool
          __typename
        }
        __typename
      }
      event {
        id
        event
        subject
        location
        note
        date
        time
        category
        repeat
        date2
        selectedDays_WEEKLY
        selectedDays_MONTHLY
        datesBwDate1NDate2
        checked {
          date
          bool
          __typename
        }
        __typename
      }
      homework {
        id
        subject
        teacher
        note
        date
        time
        category
        repeat
        type
        date2
        selectedDays_WEEKLY
        selectedDays_MONTHLY
        datesBwDate1NDate2
        checked {
          date
          bool
          __typename
        }
        __typename
      }
      manageCategories_Task
      manageCategories_Event
      manageCategories_Homework
      pomodorotimer {
        workminutes
        breakminutes
        __typename
      }
      __typename
    }
  }
`;
