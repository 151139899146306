const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_appsync_graphqlEndpoint": "https://ryfpyi3eqza4hd6pqb3jtche3m.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-iytwh2hov5hrbo3gqscxvx5ifq",
    "Auth": {
        "userPoolId": "us-east-2_F3pJ8Zf3r",
        "userPoolWebClientId": "114inm05l7bda68f9r15oh7kq4"
    }
}

export default awsmobile;