/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $firstname: String!
    $lastname: String!
    $email: String!
    $password: String!
    $dark: Boolean!
    $bandwidth: Int!
    $storage: Int!
    $regestrationDate: String!
    $endingDate: String!
    $semester: Int!
    $pageSettings: _pageSettingsInput!
    $semester_1: _eachSemesterInput!
    $semester_2: _eachSemesterInput!
    $schedule: [_scheduleInput]
    $timetable: [_timetableInput]
    $teacher: [_teacherInput]
    $note: [_noteInput]
    $task: [_taskInput]
    $event: [_eventInput]
    $homework: [_homeworkInput]
    $manageCategories_Task: [String]
    $manageCategories_Event: [String]
    $manageCategories_Homework: [String]
    $pomodorotimer: _pomodorotimerInput
  ) {
    createUser(
      firstname: $firstname
      lastname: $lastname
      email: $email
      password: $password
      dark: $dark
      bandwidth: $bandwidth
      storage: $storage
      regestrationDate: $regestrationDate
      endingDate: $endingDate
      semester: $semester
      pageSettings: $pageSettings
      semester_1: $semester_1
      semester_2: $semester_2
      schedule: $schedule
      timetable: $timetable
      teacher: $teacher
      note: $note
      task: $task
      event: $event
      homework: $homework
      manageCategories_Task: $manageCategories_Task
      manageCategories_Event: $manageCategories_Event
      manageCategories_Homework: $manageCategories_Homework
      pomodorotimer: $pomodorotimer
    ) {
      id
      firstname
      lastname
      email
      password
      dark
      bandwidth
      storage
      regestrationDate
      endingDate
      semester
      pageSettings {
        homework
        events
        tasks
        notes
        absences
        teachers
        subjects
        schedule
        timetable
        grades
        __typename
      }
      semester_1 {
        absence {
          id
          subject
          teacher
          room
          note
          date
          __typename
        }
        subject {
          id
          subject
          teacher
          room
          note
          __typename
        }
        grade {
          id
          subject
          grade
          weight
          note
          date
          maxpoints
          credit
          course
          gradeSystem
          __typename
        }
        __typename
      }
      semester_2 {
        absence {
          id
          subject
          teacher
          room
          note
          date
          __typename
        }
        subject {
          id
          subject
          teacher
          room
          note
          __typename
        }
        grade {
          id
          subject
          grade
          weight
          note
          date
          maxpoints
          credit
          course
          gradeSystem
          __typename
        }
        __typename
      }
      schedule {
        title
        start
        end
        id
        subject
        teacher
        room
        note
        time1
        time2
        colorpicker
        typeValue
        __typename
      }
      timetable {
        title
        day
        id
        subject
        teacher
        room
        note
        time1
        time2
        colorpicker
        __typename
      }
      teacher {
        id
        name
        phone
        email
        address
        officehours
        website
        __typename
      }
      note {
        id
        noteTitle
        description
        topics {
          id
          topicTitle
          topicDescrip
          colorpicker
          __typename
        }
        __typename
      }
      task {
        id
        task
        note
        date
        time
        category
        repeat
        date2
        selectedDays_WEEKLY
        selectedDays_MONTHLY
        datesBwDate1NDate2
        checked {
          date
          bool
          __typename
        }
        __typename
      }
      event {
        id
        event
        subject
        location
        note
        date
        time
        category
        repeat
        date2
        selectedDays_WEEKLY
        selectedDays_MONTHLY
        datesBwDate1NDate2
        checked {
          date
          bool
          __typename
        }
        __typename
      }
      homework {
        id
        subject
        teacher
        note
        date
        time
        category
        repeat
        type
        date2
        selectedDays_WEEKLY
        selectedDays_MONTHLY
        datesBwDate1NDate2
        checked {
          date
          bool
          __typename
        }
        __typename
      }
      manageCategories_Task
      manageCategories_Event
      manageCategories_Homework
      pomodorotimer {
        workminutes
        breakminutes
        __typename
      }
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($id: ID!, $email: String!) {
    deleteUser(id: $id, email: $email)
  }
`;
export const editUser = /* GraphQL */ `
  mutation EditUser(
    $id: ID!
    $firstname: String!
    $lastname: String!
    $email: String!
    $password: String!
    $dark: Boolean!
    $bandwidth: Int!
    $storage: Int!
    $regestrationDate: String!
    $endingDate: String!
    $semester: Int!
    $pageSettings: _pageSettingsInput!
    $semester_1: _eachSemesterInput!
    $semester_2: _eachSemesterInput!
    $schedule: [_scheduleInput]
    $timetable: [_timetableInput]
    $teacher: [_teacherInput]
    $note: [_noteInput]
    $task: [_taskInput]
    $event: [_eventInput]
    $homework: [_homeworkInput]
    $manageCategories_Task: [String]
    $manageCategories_Event: [String]
    $manageCategories_Homework: [String]
    $pomodorotimer: _pomodorotimerInput
  ) {
    editUser(
      id: $id
      firstname: $firstname
      lastname: $lastname
      email: $email
      password: $password
      dark: $dark
      bandwidth: $bandwidth
      storage: $storage
      regestrationDate: $regestrationDate
      endingDate: $endingDate
      semester: $semester
      pageSettings: $pageSettings
      semester_1: $semester_1
      semester_2: $semester_2
      schedule: $schedule
      timetable: $timetable
      teacher: $teacher
      note: $note
      task: $task
      event: $event
      homework: $homework
      manageCategories_Task: $manageCategories_Task
      manageCategories_Event: $manageCategories_Event
      manageCategories_Homework: $manageCategories_Homework
      pomodorotimer: $pomodorotimer
    ) {
      id
      firstname
      lastname
      email
      password
      dark
      bandwidth
      storage
      regestrationDate
      endingDate
      semester
      pageSettings {
        homework
        events
        tasks
        notes
        absences
        teachers
        subjects
        schedule
        timetable
        grades
        __typename
      }
      semester_1 {
        absence {
          id
          subject
          teacher
          room
          note
          date
          __typename
        }
        subject {
          id
          subject
          teacher
          room
          note
          __typename
        }
        grade {
          id
          subject
          grade
          weight
          note
          date
          maxpoints
          credit
          course
          gradeSystem
          __typename
        }
        __typename
      }
      semester_2 {
        absence {
          id
          subject
          teacher
          room
          note
          date
          __typename
        }
        subject {
          id
          subject
          teacher
          room
          note
          __typename
        }
        grade {
          id
          subject
          grade
          weight
          note
          date
          maxpoints
          credit
          course
          gradeSystem
          __typename
        }
        __typename
      }
      schedule {
        title
        start
        end
        id
        subject
        teacher
        room
        note
        time1
        time2
        colorpicker
        typeValue
        __typename
      }
      timetable {
        title
        day
        id
        subject
        teacher
        room
        note
        time1
        time2
        colorpicker
        __typename
      }
      teacher {
        id
        name
        phone
        email
        address
        officehours
        website
        __typename
      }
      note {
        id
        noteTitle
        description
        topics {
          id
          topicTitle
          topicDescrip
          colorpicker
          __typename
        }
        __typename
      }
      task {
        id
        task
        note
        date
        time
        category
        repeat
        date2
        selectedDays_WEEKLY
        selectedDays_MONTHLY
        datesBwDate1NDate2
        checked {
          date
          bool
          __typename
        }
        __typename
      }
      event {
        id
        event
        subject
        location
        note
        date
        time
        category
        repeat
        date2
        selectedDays_WEEKLY
        selectedDays_MONTHLY
        datesBwDate1NDate2
        checked {
          date
          bool
          __typename
        }
        __typename
      }
      homework {
        id
        subject
        teacher
        note
        date
        time
        category
        repeat
        type
        date2
        selectedDays_WEEKLY
        selectedDays_MONTHLY
        datesBwDate1NDate2
        checked {
          date
          bool
          __typename
        }
        __typename
      }
      manageCategories_Task
      manageCategories_Event
      manageCategories_Homework
      pomodorotimer {
        workminutes
        breakminutes
        __typename
      }
      __typename
    }
  }
`;
