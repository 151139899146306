import React, { useState } from 'react';
//MATERIAL-UI
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
//MATERIAL-UI ( Pagination )
import TablePagination from '@material-ui/core/TablePagination';
//CONTEXT-API
import { useStateValue } from '../../StateContext';
//EXPORT FUNCTION
import { handleOpened } from '../utils/Header/Dialog/_Forms/Teacher';


//FOR MUI DIALOG WIDTH/HEIGHT
const useStyles = makeStyles({
    paper: {
        minWidth: "42.9vh",
        maxWidth: "42.9vh",
        minHeight: "27.9vh",
        maxHeight: "27.9vh",
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
});


function Teachers() {
    const classes = useStyles();
    const [ { user, refetchLoadingAppSync }, dispatch ] = useStateValue();


    var teacher = [];
    teacher = user?.teacher;


    //*********************PAGINATION******************//
    const [ page, setPage ] = useState(0);
    const [ rowsPerPage, setRowsPerPage ] = useState(4);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }
    //*********************PAGINATION******************//


    return (
        <>
            {
                user&&
                (teacher).length!==0&&(
                    <div className="-mt-10 flex flex-col justify-center h-[88vh]">
                        <List className="!mt-[2.05rem] flex-1">
                            {
                                teacher
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)                                
                                .map((item, i) => (
                                    <ListItem
                                        key={i}
                                        style={{ margin: "0 auto" }}
                                        className={`!mt-3.5 !pt-0 !pb-1.5 !pl-6 !w-[80%] !rounded-2xl ${user?.dark?`!bg-[#eee]`:`!bg-[#FFFFFF]`}`}
                                        onClick={() => handleOpened(item)}
                                    >
                                        <ListItemIcon className="invisible"/>
                
                                        <div className="flex flex-col">
                                            <div className="text-xl mt-1.5 text-black"> {item.name}  </div>
                                            <div className="text-md mt-2.5 text-black"> {item.phone} </div>
                                            <div className="text-sm text-black">        {item.email} </div>
                                        </div>
                                    </ListItem>
                                ))
                            }
                        </List>


                        <span className="thatPagination">
                            <TablePagination
                                component="div"
                                count={(teacher).length}
                                page={page}
                                onPageChange={handleChangePage}
                                rowsPerPageOptions={[4]}
                                rowsPerPage={rowsPerPage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </span>
                    </div>
                )
            }


            <Dialog
                open={refetchLoadingAppSync}
                className="dialog"
                classes={{ paper: classes.paper }}
            >
                <CircularProgress size={40}/>
            </Dialog>
        </>
    )
}

export default Teachers;