import React, { useContext, useState, useEffect } from 'react';
import SettingsContext from './SettingsContext';
import './slider.scss';
import './dialog.scss';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import TextField from '@mui/material/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
//CONTEXT-API
import { useStateValue } from '../../../StateContext';
//APPSYNC-MUTATION
import { handleEditUser } from '../../../appsync_functions/mutations';


//FOR MUI INPUT BORDER-RADUIS
const useStyles = makeStyles({
    root: {
        [`& fieldset`]: {
            borderRadius: 16.5
        }
    }
});


function Settings() {
    const settingsInfo = useContext(SettingsContext);


    const [workMinutes, setWorkMinutes] = useState<number>(25);
    const [breakMinutes, setBreakMinutes] = useState<number>(5);


    const [open, setOpen] = useState<boolean>(true);
    const [openDialog_Message, setOpenDialog_Message] = useState<boolean>(false);


    const classes = useStyles();
    const [{ user }, dispatch] = useStateValue();


    useEffect(() => {
        setWorkMinutes(
            user?.pomodorotimer.workminutes
        )
        setBreakMinutes(
            user?.pomodorotimer.breakminutes
        )
    }, []);


    return (
        <>
            <Dialog
                open={open}
                onClose={() => { setOpen(false); settingsInfo.setShowSettings(false); }}
                className="dialog"
            >
                <div style={{ textAlign: 'left' }} className="form">
                    <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} value={settingsInfo.workMinutes} onChange={(e) => { settingsInfo.setWorkMinutes(Number(e.target.value)); setWorkMinutes(Number(e.target.value)); }} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Work Minutes</p>} variant="outlined" type="number" /> </div>

                    <div> <TextField sx={{ input: { color: `${user?.dark ? `#bbc0c4` : `#455a64`}` } }} className={classes.root} value={settingsInfo.breakMinutes} onChange={(e) => { settingsInfo.setBreakMinutes(Number(e.target.value)); setBreakMinutes(Number(e.target.value)); }} label={<p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}>Break Minutes</p>} variant="outlined" type="number" /> </div>

                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        <div className="btns">
                            <div className="btns_flexy" />
                            <Button className="btns_cancelBtn !capitalize" onClick={() => { setOpen(false); settingsInfo.setShowSettings(false); }} disableRipple> Cancel </Button>
                            <Button className="btns_addBtn !capitalize" onClick={() => {
                                if (workMinutes > 120 || breakMinutes > 30) {
                                    setOpen(false); setOpenDialog_Message(true);
                                }
                                else {
                                    setOpen(false); settingsInfo.setShowSettings(false); settingsInfo.setWorkMinutes(Number(workMinutes)); settingsInfo.setBreakMinutes(Number(breakMinutes));

                                    //editing on DB by AppSync
                                    handleEditUser({ ...user, pomodorotimer: { workminutes: Number(workMinutes), breakminutes: Number(breakMinutes) } });
                                }
                            }}> Save </Button>
                        </div>
                    </div>
                </div>
            </Dialog>


            <Dialog
                open={openDialog_Message}
                onClose={() => setOpenDialog_Message(false)}
                className="dialog"
            >
                <div className="dialog_inform_msg pt-6 pl-6 pr-6 pb-3.5">
                    <Typography variant="h4" className={`${user?.dark ? `!text-[#eee]` : `!text-[#000]`}`}> Error </Typography>

                    <p className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`} mt-3.5`}> Work duration should not be more than 120 minutes and the break duration should not be more than 30 minutes. </p>

                    <div className="dialog_inform_msg_btns">
                        <div className="dialog_inform_msg_btns_flexy" />
                        <button className="dialog_inform_msg_btns_btn p-1.5 w-16 rounded-lg ml-2.5" onClick={() => { setOpenDialog_Message(false); settingsInfo.setShowSettings(false); }}> Ok </button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default Settings;