import React, { useState } from 'react';
import icon from '../../images/icon.png';
//MATERIAL-UI
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
//GATSBY
import { Link, navigate } from 'gatsby';
//CONTEXT-API
import { useStateValue } from '../../StateContext';
import { settingURL } from '../../contextapi';
//FORMIK & YUP
import { Formik, ErrorMessage } from 'formik';
const yup = require('yup')
require('yup-password')(yup);
//SWEETALERT2
import { toastAlert, successAlert, errorAlert } from '../../alerts';
//AWS-AMPLIFY
import { Auth } from 'aws-amplify';
//SCSS
import './index.scss';


function ForgetPassword() {
    const [ { user }, dispatch ] = useStateValue();
    const [ holdEmail, setHoldEmail ] = useState<string>("");


    const [ step, setStep ] = useState<number>(1);


    return (
        <>
            {
                (step===2)&&(
                    <div className="topper">
                        <div className="topper_bottom">
                            <IconButton
                                onClick={() => setStep((prev) => prev-1)}
                            >
                                <ArrowBackIcon/>
                            </IconButton>
                        </div>
                    </div>
                )
            }


            <div className={`${`${user&&user?.dark?`!bg-[#5e5b5b]`:`!bg-white`}`} !rounded-lg !mt-[-2.85rem] !w-[27rem] !h-[27.545rem] !pl-2.5 !pr-2.5`} style={{ margin: "0 auto" }}>
                
                <Box sx={{ marginTop: 9, padding: 1.855, paddingBottom: 3.65, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar className="!bg-transparent !mt-3.5 !ml-3 !mb-1.5 !mr-1.5 !w-16 !h-16">
                        <img src={icon} alt="logo"/>
                    </Avatar>
                
                    <div className="!mt-[-1.1rem]">
                        <Typography className={`${user&&user?.dark?`!text-white`:`!text-black`} !mt-[-0.215rem] !ml-[8.15rem] !text-2xl !font-semibold`}>
                            <p className="!ml-[-2.05rem] !mt-4 w-full">Forget Password</p>
                            <p className="!ml-[1.895rem]">Step {step}</p>
                        </Typography>

                        {
                            step===1&&(
                                <Formik
                                    initialValues={{
                                        email: ''
                                    }}
                                    validationSchema={
                                        yup.object({
                                            email: yup.string()
                                            .email('Must be a valid email')
                                            .max(30, 'Must be atmost 30 characters')
                                            .min(5, 'Must be atleast 5 characters')
                                            .required('Must fill email')
                                        })
                                    }
                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(true);
                                        toastAlert('Submitting..', 'top-end', 'info');


                                        setTimeout(async() => { 
                                            try {
                                                /* using here due to ContextApi disptach accessable only in react components */
                                                await Auth.forgotPassword(
                                                    values.email
                                                );
                                                console.log('sended forgotpassword code');
    
                                                setStep(2);
                                                setHoldEmail(values.email);/*for third form only*/
                                            }
                                            catch(error) {
                                                console.log('error forgotpassword', error);
                                                errorAlert('Forgot Password', error.message);
                                            }


                                            setSubmitting(false);
                                        }, 1500);
                                    }}            
                                >
                                    {({ values, handleChange, handleSubmit, isSubmitting }) => (
                                        <Box component="form" onSubmit={handleSubmit} className="!mt-[2.9rem] flex items-center justify-center flex-col h-[13.95rem]">
                                            <div className="flex-1 mt-3.5">
                                                <input
                                                    className="w-full rounded-lg pt-3 pb-3 pl-3.5 outline-none border-2 border-gray-300 text-black"
                                                    name="email"
                                                    value={values.email}
                                                    onChange={handleChange}
                                                    placeholder="Email"
                                                    autoComplete="off"
                                                    disabled={isSubmitting}
                                                />
                                                <ErrorMessage name='email' component="p" className="err"/>

                                                <TextField
                                                    margin="normal"
                                                    fullWidth
                                                    name="password"
                                                    type="password"
                                                    id="password"
                                                    inputProps={{ autoComplete: 'off' }}
                                                    sx={{ input: { color: `${user&&user?.dark?`#bbc0c4`:`#455a64`}` } }}
                                                    className="!invisible"
                                                    disabled={isSubmitting}
                                                />

                                                <Button
                                                    fullWidth
                                                    type="submit"
                                                    variant="contained"
                                                    disabled={isSubmitting}
                                                    className="!bg-blue-600 !text-white !h-[2.2rem] !rounded-md"
                                                    sx={{ mt: -13.25, mb: 2 }}
                                                >
                                                    Submit { isSubmitting && <CircularProgress style={{ color: 'white', marginTop: "0.1rem", marginLeft: "0.4rem" }} size={15}/> }
                                                </Button>
                                            </div>

                                            <Grid container justifyContent="center" className="!mt-[-0.03rem]">
                                                <Grid item>
                                                    <Link className={`!text-center !w-[0.2rem] !cursor-pointer !no-underline !text-sm ${user&&user?.dark?`!text-white`:`!text-blue-700`}`}>
                                                        {"Enter email to get a verification code on your email!"}
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                </Formik>
                            )
                        }

                        {
                            step===2&&(
                                <Formik
                                    initialValues={{
                                        code     : '',
                                        password : ''
                                    }}
                                    validationSchema={
                                        yup.object({
                                            code: yup.string()
                                            .max(6, 'Must be atmost 6 characters')
                                            .required('Must fill code'),

                                            password: yup.string().password()
                                            .minLowercase(1, 'Must be atleast 1 lowercase')
                                            .minUppercase(1, 'Must be atleast 1 uppercase')
                                            .minNumbers(1, 'Must be atleast 1 number')
                                            .minSymbols(0, 'Must not include any symnol')
                                            .min(6, 'Must be atleast than 6 characters')
                                            .required('Must fill password')
                                        })
                                    }
                                    onSubmit={(values, { setSubmitting }) => {
                                        setSubmitting(true);
                                        toastAlert('Submitting..', 'top-end', 'info');


                                        setTimeout(async() => { 
                                            try {
                                                await Auth.forgotPasswordSubmit(
                                                    holdEmail,
                                                    values.code,
                                                    values.password
                                                );
                                                console.log('succesfully forgotpassword');
    
                                                successAlert("Password Restored Successfully");

                                                setStep(1);      /*for second form only*/
                                                setHoldEmail("");/*for second form only*/

                                                dispatch(settingURL("/"));
                                                navigate("/");
                                            }
                                            catch(error) {
                                                console.log('error forgotpassword', error);
                                                errorAlert('Forgot Password', error.message);
                                            }                                                


                                            setSubmitting(false);
                                        }, 1500);
                                    }}
                                >
                                    {({ values, handleChange, handleSubmit, isSubmitting }) => (
                                        <Box component="form" onSubmit={handleSubmit} className="!mt-[2.9rem] flex items-center justify-center flex-col h-[13.75rem]">
                                            <div className="flex-1 -mt-2">
                                                <input
                                                    className="w-full rounded-lg pt-3 pb-3 pl-3.5 outline-none border-2 border-gray-300 text-black"
                                                    name="code"
                                                    value={values.code}
                                                    onChange={handleChange}
                                                    placeholder="Code"
                                                    autoComplete="off"
                                                    disabled={isSubmitting}
                                                />
                                                <ErrorMessage name='code' component="p" className="err"/>

                                                <input
                                                    className="w-full rounded-lg pt-3 pb-3 pl-3.5 mt-3 outline-none border-2 border-gray-300 text-black"
                                                    type="password"
                                                    name="password"
                                                    value={values.password}
                                                    onChange={handleChange}
                                                    placeholder="Password"
                                                    autoComplete="off"
                                                    disabled={isSubmitting}                                                    
                                                />
                                                <ErrorMessage name='password' component="p" className="err"/>
        
                                                <Button
                                                    fullWidth
                                                    type="submit"
                                                    variant="contained"
                                                    disabled={isSubmitting}
                                                    className="!bg-blue-600 !text-white !h-[2.2rem] !rounded-md !mt-[1.25rem]"
                                                    sx={{ mt: -13.25, mb: 2 }}
                                                >
                                                    Submit { isSubmitting && <CircularProgress style={{ color: 'white', marginTop: "0.5rem", marginLeft: "0.4rem" }} size={15}/> }
                                                </Button>
                                            </div>

    
                                            <Grid container justifyContent="center" className="!mt-[-0.03rem]">
                                                <Grid item>
                                                    <Link className={`!text-center !w-[0.2rem] !cursor-pointer !no-underline !text-sm ${user&&user?.dark?`!text-white`:`!text-blue-700`}`}>
                                                        {"You have received a verification code on your email!"}
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )}
                                </Formik>
                            )
                        }
                    </div>
                </Box>
                
            </div>
        </>
    )
}

export default ForgetPassword;