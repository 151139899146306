import React from 'react';
//COMPONENTS
import { Layout } from '../components';
//CONTEXT-API
import { StateProvider } from '../StateContext';


//AWS-AMPLIFY
import Amplify, { Auth } from 'aws-amplify';
import config from '../aws-exports';
Amplify.configure(config);
Auth.configure(config);


interface wrapRootElementProps {
    element: JSX.Element[] | JSX.Element;
}

export const wrapRootElement = ({ element }: wrapRootElementProps) => {
    return <StateProvider><Layout>{element}</Layout></StateProvider>;
}