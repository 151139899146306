import React, { useState } from 'react';
import Dialog_ChangeEmail from './Dialog_ChangeEmail';
import Dialog_ChangePassword from './Dialog_ChangePassword';
import Dialog_DeleteAccount from './Dialog_DeleteAccount';
//MATERIAL-UI
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
//GATSBY
import { navigate } from 'gatsby';
//CONTEXT-API
import { useStateValue } from '../../../../../StateContext';
import { settingURL, settingISSIGNEDIN, settingUSER } from '../../../../../contextapi';
//AWS-AMPLIFY
import { Auth } from 'aws-amplify';
//STYLE
import './index.scss';


interface AccountProps {
    setOpenDialog: Function;
}

function Account({
    setOpenDialog
}: AccountProps) {
    const [{ user }, dispatch] = useStateValue();


    const [openDialog_Option_ChangeEmail, setOpenDialog_Option_ChangeEmail] = useState<boolean>(false);
    const [openDialog_Option_ChangePassword, setOpenDialog_Option_ChangePassword] = useState<boolean>(false);
    const [openDialog_Option_DeleteAccount, setOpenDialog_Option_DeleteAccount] = useState<boolean>(false);


    return (
        <>
            <div className="flex flex-col">

                <div className="flex flex-col w-[95%] pt-3 pb-3 !mr-3" style={{ margin: "0 auto" }}>
                    <p className={`text-sm font-sans font-bold ${`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}`}> Name </p>
                    <TextField
                        hiddenLabel
                        value={user?.firstname + " " + user?.lastname}
                        variant="filled"
                        size="small"
                        spellCheck={false}
                    />
                </div>


                <div className="flex flex-col w-[95%] pt-3 pb-3 !mr-3" style={{ margin: "0 auto" }}>
                    <p className={`text-sm font-sans font-bold ${`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}`}> Email </p>
                    <TextField
                        hiddenLabel
                        value={user?.email}
                        variant="filled"
                        size="small"
                        spellCheck={false}
                    />
                    {/* <button className="btn p-1.5 w-36 text-sm rounded-lg mt-1.5" onClick={() => setOpenDialog_Option_ChangeEmail(true)}> Change Email </button> */}
                </div>

                <div className="flex flex-col w-[95%] pt-2.5 pb-6 !mr-3" style={{ margin: "0 auto" }}>
                    <p className={`text-sm font-sans font-bold ${`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}`}> Password </p>
                    <button className="btn p-1.5 w-36 text-sm rounded-lg" onClick={() => setOpenDialog_Option_ChangePassword(true)}> Change Password </button>
                </div>

                <div className="flex flex-col w-[95%] !mr-3" style={{ margin: "0 auto" }}>
                    <p className={`text-sm font-sans font-bold ${`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}`}> Logout </p>
                    <button
                        className="btn p-1.5 w-36 text-sm rounded-lg"
                        onClick={async () => {
                            try {
                                /* using here due to ContextApi disptach accessable only in react components */
                                await Auth.signOut();
                                console.log('successfully signedout');

                                dispatch(settingURL("/"));
                                navigate("/");

                                dispatch(settingISSIGNEDIN(false));
                                dispatch(settingUSER(null));

                                setOpenDialog(false);
                            }
                            catch (error) {
                                console.log('error signedout: ', error);
                            }
                        }}
                    > Logout </button>
                </div>

                <div className="!w-[96.5%] pt-5" style={{ margin: "0 auto" }}>
                    <Divider />
                </div>

                <div className="flex flex-col w-[95%] pt-10 pb-4 !mr-3" style={{ margin: "0 auto" }}>
                    <p className={`text-sm font-sans font-bold ${`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}`}> Delete Account </p>
                    <button className="dlt_btn p-1.5 w-36 text-sm rounded-lg mt-0.5" onClick={() => setOpenDialog_Option_DeleteAccount(true)}> Delete Account </button>
                </div>

            </div>


            <Dialog_ChangeEmail
                open={openDialog_Option_ChangeEmail}
                setOpen={setOpenDialog_Option_ChangeEmail}
                setOpenDialog={setOpenDialog}
            />


            <Dialog_ChangePassword
                open={openDialog_Option_ChangePassword}
                setOpen={setOpenDialog_Option_ChangePassword}
                setOpenDialog={setOpenDialog}
            />


            <Dialog_DeleteAccount
                open={openDialog_Option_DeleteAccount}
                setOpen={setOpenDialog_Option_DeleteAccount}
                setOpenDialog={setOpenDialog}
            />
        </>
    )
}

export default Account;