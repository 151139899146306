import React, { useState } from 'react';
//COMPONENTS
import Dialog from './Dialog';
import MenuThreedots from './MenuThreedots';
//MATERIAL-UI
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
//CONTEXT-API
import { useStateValue } from '../../../StateContext';
//SCSS
import './index.scss';


function Header() {
    const [ { url, displayPomodoroTimer }, dispatch ] = useStateValue();
    const [ openDialog, setOpenDialog ] = useState<boolean>(false);


    return (
        <>
            <div className="header">

                <div className="header_top">
                    <div className="header_top_flexy"/>
                    <MenuThreedots
                    />
                </div>

                <div className="header_bottom">
                    <div className="header_bottom_flexy"/>
                    <IconButton
                        className={`
                            ${
                                (url==='/'||url==='/notes'||url==='/settings'||url==='/schedule'||url==='/timetable')
                                &&`invisible`
                            }
                            ${
                                /*only for homework page ( displayPomodoroTimer ) */
                                (displayPomodoroTimer&&url==='/homework')
                                &&`invisible`
                            }
                        `}
                        onClick={() => setOpenDialog(true)}
                    >
                        <AddIcon/>
                    </IconButton>
                </div>

            </div>


            <Dialog
                open={openDialog}
                setOpen={setOpenDialog}
            />
        </>
    )
}

export default Header;