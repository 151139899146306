import React from 'react';
//MATETRIAL-UI
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { makeStyles } from '@material-ui/core/styles';
//CONTEXT-API
import { useStateValue } from '../../../../../../StateContext';


//FOR MUI INPUT BORDER-RADUIS
const useStyles = makeStyles({
    root: {
        [`& fieldset`]: {
            borderRadius: 16.5
        },
    }
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
        }
    },
}


function getStyles(name, list, theme) {
    return {
        fontWeight:
            list.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
        /*personal style*/
        margin: "0 auto"
    };
}


interface SelectMultipleWithoutCheckboxProps {
    heading: string;
    options: string[];
    list: string[];
    setList: Function;
}

function SelectMultipleWithoutCheckbox({
    heading,
    options,
    list,
    setList
}: SelectMultipleWithoutCheckboxProps) {
    const classes = useStyles();
    const theme = useTheme();
    const [{ user }, dispatch] = useStateValue();


    const handleChange = (event) => {
        const { target: { value } } = event;
        setList(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    }


    return (
        <FormControl className={classes.root}>
            <InputLabel className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`}`}> {heading} </InputLabel>
            <Select
                multiple
                value={list}
                onChange={handleChange}
                input={<OutlinedInput label={heading} />}
                MenuProps={MenuProps}
                className={`${user?.dark ? `!text-[#bbc0c4]` : `!text-[#455a64]`} !flex`}
            >
                {options.slice(0, 10).map((item) => (
                    <MenuItem key={item} value={item} className="!inline !w-[10%]" style={getStyles(item, list, theme)}>
                        {item}
                    </MenuItem>
                ))}
                <br />
                {options.slice(10, 20).map((item) => (
                    <MenuItem key={item} value={item} className="!inline !w-[10%]" style={getStyles(item, list, theme)}>
                        {item}
                    </MenuItem>
                ))}
                <br />
                {options.slice(20).map((item) => (
                    <MenuItem key={item} value={item} className="!inline !w-[10%]" style={getStyles(item, list, theme)}>
                        {item}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectMultipleWithoutCheckbox;