import React, { useState } from 'react';
//MATERIAL-UI
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
//CONTEXT-API
import { useStateValue } from '../../../StateContext';
//APPSYNC-MUTATION
import { handleEditUser } from '../../../appsync_functions/mutations';
//SCSS
import './index.scss';


//FOR MUI DIALOG WIDTH/HEIGHT
const useStyles = makeStyles({
    paper1: {
        minWidth: '29rem',
        maxWidth: '29rem'
    },
    paper2: {
        minWidth: '17.35rem',
        maxWidth: '17.35rem'
    }
});


interface OpenedItemProps {
    selectedData: {
        isSelected : boolean;
        /*TEMPORARY STORED*/
        id         : number;
        noteTitle  : string;
        description: string;
        topics     : any[];
    },
    data: any[];
    setSelectedDataTopic: Function;
    setTopicTitle: Function;
    setDisplayWallpaper: Function;
}

function OpenedItem({
    selectedData,
    data,
    setSelectedDataTopic,
    setTopicTitle,
    setDisplayWallpaper
}: OpenedItemProps) {
    const classes = useStyles();
    const [ { user }, dispatch ] = useStateValue();


    const _data = (
        /*find object of specific id in array of object*/
        data.find(x => x.id == selectedData.id)
    ).topics;


    const [ open_dialog, setOpen_dialog ] = useState<boolean>(false);
    const [ _selectedId, _setSelectedId ] = useState<number|null>(null);


    var note;
    note = user?.note;


    const [ colorpicker, setColorpicker ] = useState<string>("#03a9f4");


    return (
        <>
            <div className="ml-2.5 mt-8">
                <Typography variant="h6" className={`!font-bold !tracking-wide ${user?.dark?`!text-[#eee]`:`!text-[#000]`}`}> {selectedData.noteTitle} </Typography>
                <div className={`text-sm ${user?.dark?`!text-[#eee]`:`!text-[#000]`}`}> {selectedData.description} </div>
            </div>


            <List>
                {
                    (
                        _data
                    ).map((item, i) => (
                        <ListItem
                            key={i}
                            style={{ margin: "0 auto", backgroundColor: item.colorpicker }}
                            className={`!mt-[0.2rem] !pt-0.5 !pb-1.5 !pl-6 !w-[100%] !rounded-sm !cursor-pointer ${user?.dark?`!text-white hover:!text-black`:`!text-black hover:!text-black`}`}
                            onClick={() => {
                                setTimeout(() => {
                                    setDisplayWallpaper(false);

                                    /*for react-quill on parent component*/
                                    setSelectedDataTopic({
                                        id          : item.id,
                                        topicTitle  : item.topicTitle,
                                        topicDescrip: item.topicDescrip,
                                        colorpicker : item.colorpicker
                                    });
        
                                    /*for input on parent component*/
                                    setTopicTitle({ html: "", text: item.topicTitle });    
                                }, 300);
                            }}
                        >
                            <>
                                <div className="flex items-center ml-[-0.855rem]">
                                    <div
                                        className={`!text-[0.9rem] !mt-1.5 !font-medium !w-[10rem] !tracking-wide !text-white`}
                                        style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}
                                    >
                                        {item.topicTitle}
                                    </div>
        
                                    <div className="mt-1 ml-[-1.15rem]">
                                        <input
                                            id="colors"
                                            type="color"
                                            value={item.colorpicker}
                                            onClick={(e: any) => {
                                                if (!e) var e: any = window.event;
                                                e.cancelBubble = true;
                                                if (e.stopPropagation) e.stopPropagation();
                                            }}
                                            onChange={(e: any) => {
                                                setColorpicker(e.target.value);
                                            }}
                                            onMouseOut={() => {
                                                /*replace object property of specific id's objects in array of object*/
                                                handleEditUser({
                                                    ...user,
                                                    note: [
                                                        /*STEP-1 remove object of specific id in array of object*/
                                                        ...note.filter(item_ => item_.id != selectedData.id),
                                                        {
                                                            /*STEP-2 find object of specific id in array of object*/
                                                            ...note.find(item_ => item_.id == selectedData.id),
                                                            topics: note.find(item_ => item_.id == selectedData.id).topics
                                                                    .map((item_) => {
                                                                        if(item_.id == item.id) {
                                                                            return { ...item_, colorpicker }
                                                                        }
                                                                        else {
                                                                            return item_;
                                                                        }
                                                                })
                                                            }
                                                        ]
                                                });
                                            }}
                                        />
                                    </div>
        
                                    <IconButton
                                        size="small"
                                        className="w-12 h-12 ml-[-0.35rem] mt-[0.15rem]"
                                        onClick={(e: any) => {
                                            if (!e) var e: any = window.event;
                                            e.cancelBubble = true;
                                            if (e.stopPropagation) e.stopPropagation();
                                            /*CODE*/
                                            setOpen_dialog(true);
                                            /*setting id ( for delete purpose )*/
                                            _setSelectedId(item.id);
                                        }}
                                    >
                                        <DeleteIcon style={{ fill: user?.dark?"#c0bbbb":"black" }}/>
                                    </IconButton>
                                </div>
                            </>
                        </ListItem>
                    ))
                }
            </List>


            <Dialog
                open={open_dialog}
                onClose={() => setOpen_dialog(false)}
                className="dialog"
                classes={{ paper: classes.paper1 }}
            >
                <div className="dialog_warning pt-6 pl-6 pr-6 pb-3.5">
                    <p className={`text-[1.145rem] ml-1.5 ${user?.dark?`!text-[#bbc0c4]`:`!text-[rgb(69,90,100)]`}`}> Are you sure you want to delete this note? </p>

                    <div className="dialog_warning_btns">
                        <div className="dialog_warning_btns_flexy"/>
                        <button className={`dialog_warning_btns_cancelBtn ${user?.dark&&`hover:!bg-gray-600`} p-1.5 w-16 rounded-lg ml-2.5`} onClick={() => {
                            setOpen_dialog(false);
                            /*resetting null*/
                            _setSelectedId(null);
                        }}> No </button>
                        <button className="dialog_warning_btns_addBtn p-1.5 w-16 rounded-lg ml-2.5" onClick={() => {
                            setOpen_dialog(false);


                            /*remove object property of specific id's objects in array of object*/
                            handleEditUser({
                                ...user,
                                note: [
                                    /*STEP-1 remove object of specific id in array of object*/
                                    ...note.filter(item => item.id != selectedData.id),
                                    {
                                        /*STEP-2 find object of specific id in array of object*/
                                        ...note.find(item => item.id == selectedData.id),
                                        topics: [
                                            /*STEP-3 remove object of specific id in array of object*/
                                            ...note.find(item => item.id == selectedData.id).topics
                                            .filter(item => item.id != _selectedId)
                                        ]
                                    }
                                ]
                            });


                            /*resetting id ( for delete purpose )*/
                            _setSelectedId(null);
                            /*for react-quill on parent component*/
                            setSelectedDataTopic(null);
                        }}> Yes </button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default OpenedItem;