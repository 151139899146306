import React from 'react';
//MATERIAL-UI
import IconButton from '@material-ui/core/IconButton';
import SettingsIcon from '@material-ui/icons/Settings';


function SettingsButton(props) {
    return (
        <IconButton {...props} variant="contained" style={{ margin: "0 auto", width: "2.95rem", backgroundColor: "gray", color: "white" }}>
            <SettingsIcon/>
        </IconButton>
    )
}

export default SettingsButton;