import React from 'react';
//MATERIAL-UI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
//CONTEXT-API
import { useStateValue } from '../../../StateContext';


//FOR MUI INPUT BORDER-RADUIS
const useStyles = makeStyles({
    root: {
        [`& fieldset`]: {
            borderRadius: 16.5
        }
    }
});


interface MUI_Autocomplete_Props {
    alloptions: any[];
    value: string;
    setValue: Function;
    label: string;
}

function MUI_Autocomplete({
    alloptions,
    value,
    setValue,
    label
}: MUI_Autocomplete_Props) {
    const classes = useStyles();
    const [ { user }, dispatch ] = useStateValue();


    return (
        <Autocomplete
            freeSolo
            disableClearable
            value={value}
            onChange={(event, newValue) => {
                if (typeof newValue === 'string') {
                    setValue(newValue);
                }
                else if (newValue && newValue.inputValue) {
                    setValue(newValue.inputValue);
                }
                else {
                    setValue(newValue);
                }
            }}
            options={alloptions.map((option) => option.subject||option.name)} /* for 'subject_' or 'teacher_' */
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        className={classes.root}
                        onChange={(e) => setValue(e.target.value)}
                        sx={{ input: { color: `${user?.dark?`#bbc0c4`:`#455a64`}` } }}
                        label={<p className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}>{label}</p>}
                    />
                )
            }}
        />
    )
}

export default MUI_Autocomplete;