import React from 'react';
//MATERIAL-UI
import Button from '@material-ui/core/Button';
import PauseIcon from '@material-ui/icons/Pause';


function PauseButton(props) {
    return (
        <Button {...props} variant="contained" style={{ width: "7.15rem", height: "3rem", margin: "0 auto", display: "flex", justifyContent: "space-around", backgroundColor: "#f50057", color: "white", borderRadius: "30px" }}>
            <PauseIcon/>
            <span style={{ fontWeight: "bold", textTransform: "none", fontSize: "1rem" }}> Stop </span>
        </Button>
    )
}

export default PauseButton;