//SWEETALERT2
import Swal, { SweetAlertIcon, SweetAlertPosition } from 'sweetalert2';








export const toastAlert = (title = 'Something happened', position?: SweetAlertPosition, alertType?: SweetAlertIcon, width = '14.475rem'): void => {
    Swal.fire({
        title,
        icon: alertType,
        timer: 1500,
        showConfirmButton: false,
        timerProgressBar: true,
        toast: true,
        position,
        width,
        //CSS classes for animations when showing a popup (fade in)
        showClass: {
            popup: 'swal2-noanimation',
            backdrop: 'swal2-noanimation'
        },
        //CSS classes for animations when hiding a popup (fade out)
        hideClass: {
            popup: '',
            backdrop: ''
        }
    })
}

export const successAlert = (text: string) => {
    Swal.fire({
        icon: 'success',
        title: `<p id="design">${text}!</p>`,
        timer: 2000,
        showConfirmButton: false,
        timerProgressBar: true
    })
}

export const errorAlert = (text: string, error: string) => {
    Swal.fire({
        icon: 'error',
        title: `<p id="design">Can't ${text}!</p>`,
        text: `${error}`,
        confirmButtonText: 'Retry'
    })
}