import React from 'react';
//MATERIAL-UI
import Button from '@material-ui/core/Button';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';


function PlayButton(props) {
    return (
        <Button {...props} variant="contained" style={{ width: "10.95rem", height: "3rem", margin: "0 auto", display: "flex", justifyContent: "space-around", backgroundColor: "#f50057", color: "white", borderRadius: "30px" }}>
            <PlayArrowIcon/>
            <span style={{ fontWeight: "bold", textTransform: "none", fontSize: "1rem" }}> Start Session </span>
        </Button>
    )
}

export default PlayButton;