import React from 'react';
//MATERIAL-UI
import Dialog from '@material-ui/core/Dialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
//CONTEXT-API
import { useStateValue } from '../../../../../StateContext';
//APPSYNC-MUTATION
import { handleEditUser } from '../../../../../appsync_functions/mutations';
//SCSS
import './index.scss';


//FOR MUI DIALOG WIDTH/HEIGHT
const useStyles = makeStyles({
    paper: {
        minWidth: "42.9vh",
        maxWidth: "42.9vh",
        minHeight: "27.9vh",
        maxHeight: "27.9vh",
        borderRadius: 10,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
});


interface Dialog_ClearAllProps {
    open   : boolean;
    setOpen: Function;
    message: string;
}

function Dialog_ClearAll({
    open,
    setOpen,
    message
}: Dialog_ClearAllProps) {
    const classes = useStyles();
    const [ { user, url, refetchLoadingAppSync }, dispatch ] = useStateValue();
    

    return (
        <>
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                className="dialog"
            >
                <div className="dialog_clearall pt-6 pl-6 pr-6 pb-3.5">
                    <p className={`${user?.dark?`!text-[#bbc0c4]`:`!text-[#455a64]`}`}> {message} </p>
        
                    <div className="dialog_clearall_btns">
                        <div className="dialog_clearall_btns_flexy"/>
                        <button className={`dialog_clearall_btns_cancelBtn ${user?.dark&&`hover:!bg-gray-600`} p-1.5 w-16 rounded-lg ml-2.5`} onClick={() => setOpen(false)}> No </button>
                        <button className="dialog_clearall_btns_addBtn p-1.5 w-16 rounded-lg ml-2.5" onClick={() => {

                            if(url==="/absences") {

                                if(user?.semester === 1) {
                                    //editing on DB by AppSync
                                    handleEditUser({ ...user, semester_1: { ...user?.semester_1, absence: [] } });
                                }
                                else if(user?.semester === 2) {
                                    //editing on DB by AppSync
                                    handleEditUser({ ...user, semester_2: { ...user?.semester_2, absence: [] } });
                                }

                            }
                            else if(url==="/teachers") {

                                //editing on DB by AppSync
                                handleEditUser({ ...user, teacher: [] });

                            }
                            else if(url==="/subjects") {

                                if(user?.semester === 1) {
                                    //editing on DB by AppSync
                                    handleEditUser({ ...user, semester_1: { ...user?.semester_1, subject: [] } });
                                }
                                else if(user?.semester === 2) {
                                    //editing on DB by AppSync
                                    handleEditUser({ ...user, semester_2: { ...user?.semester_2, subject: [] } });
                                }

                            }
                            else if(url==="/schedule") {

                                //editing on DB by AppSync
                                handleEditUser({ ...user, schedule: [] });

                            }
                            else if(url==="/timetable") {

                                //editing on DB by AppSync
                                handleEditUser({ ...user, timetable: [] });

                            }
                            else if(url==="/grades") {

                                if(user?.semester === 1) {
                                    //editing on DB by AppSync
                                    handleEditUser({ ...user, semester_1: { ...user?.semester_1, grade: [] } });
                                }
                                else if(user?.semester === 2) {
                                    //editing on DB by AppSync
                                    handleEditUser({ ...user, semester_2: { ...user?.semester_2, grade: [] } });
                                }

                            }
    
                            setOpen(false);
                        }}> Yes </button>
                    </div>
                </div>
            </Dialog>


            <Dialog
                open={refetchLoadingAppSync}
                className="dialog"
                classes={{ paper: classes.paper }}
            >
                <CircularProgress size={40}/>
            </Dialog>
        </>
    )
}

export default Dialog_ClearAll;