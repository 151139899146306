import React, { useEffect, useState } from 'react';
import SettingsContext from './SettingsContext';
import Settings from './Settings';
import Timer from './Timer';
import './App.scss';
//MATERIAL-UI
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
//CONTEXT-API
import { useStateValue } from '../../../StateContext';
import { settingDISPLAY_POMODROTIMER } from '../../../contextapi';


function PomodoroTimer() {
    const [ { user }, dispatch ] = useStateValue();


    const [ showSettings, setShowSettings ] = useState<boolean>(false);
    const [ workMinutes, setWorkMinutes ]   = useState<number>(25);
    const [ breakMinutes, setBreakMinutes ] = useState<number>(5);


    useEffect(() => {
        setWorkMinutes(
            user?.pomodorotimer.workminutes
        )
        setBreakMinutes(
            user?.pomodorotimer.breakminutes
        )
    }, []);


    return (
        <>
            <IconButton
                onClick={() => dispatch(settingDISPLAY_POMODROTIMER(false))}
                style={{ marginLeft: "7.075rem", width: "2.95rem", backgroundColor: "gray", color: "white" }}
            >
                <ArrowBackIcon/>
            </IconButton>

            <main>
                <SettingsContext.Provider value={{
                    showSettings,
                    setShowSettings,
                    workMinutes,
                    breakMinutes,
                    setWorkMinutes,
                    setBreakMinutes,
                }}>
                    {showSettings
                    ?<Settings />
                    :<Timer/>
                    }
                </SettingsContext.Provider>
            </main>
        </>
    )
}

export default PomodoroTimer;